<script>
  export let routes_db, socket_query, units_travel_db;
  import { onMount } from "svelte";
  import {
    route_list,
    geos,
    geos_class,
    geos_class_list,
    units_list,
    nicks_list,
    map_ready,
    geos_map,
    poli,
    poli_list,
  } from "./stores.js";

  let map;
  var directionsService;
  var directionsRenderer;
  let all_steps = [];
  let mount_complete = false;
  function initMap() {
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });

    directionsService = new google.maps.DirectionsService();
    directionsRenderer = new google.maps.DirectionsRenderer({
      draggable: true,
      map: map,
    });

    directionsRenderer.addListener("directions_changed", function () {
      directionsRenderer.setMap(map);
      console.log(
        directionsRenderer.getDirections().routes[0].legs[0].distance.text
      );
      console.log(
        directionsRenderer.getDirections().routes[0].legs[0].duration.text
      );
      let steps = directionsRenderer.getDirections().routes[0].legs[0].steps;
      all_steps = [];
      for (let z in steps) {
        for (let w in steps[z].lat_lngs) {
          all_steps.push([
            steps[z].lat_lngs[w].lat(),
            steps[z].lat_lngs[w].lng(),
          ]);
        }
      }
      if (all_steps.length > 0) {
        all_steps.unshift([org_pos_p.lat, org_pos_p.lng]);
        all_steps.push([dest_pos_p.lat, dest_pos_p.lng]);
      }
      //console.log(all_steps);
    });
  }
  onMount(() => {
    mount_complete = true;
  });
  $: {
    if ($map_ready == 1 && mount_complete) {
      let bounds = new google.maps.LatLngBounds();
      bounds.extend({ lat: 32.534353, lng: -117.123783 });
      bounds.extend({ lat: 21.137926, lng: -86.740844 });
      bounds.extend({ lat: 14.534659, lng: -92.231633 });
      initMap();
      map.fitBounds(bounds);
      console.log("mapa cargado");
    }
  }

  let selected = -1;
  let selected_ = -1;
  let selected_nicks = -1;
  let selected_nicks_ = -1;
  let progress = 100;
  let recorridos = [];
  function get_geos(event, org) {
    if (org == 1) selected = event;
    else selected_ = event;
  }
  function time_code(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("m[min]");
    } else if (time < 60 * 24) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("H[h] m[min]");
    } else {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("D[d] H[h] m[min]");
    }
  }
  let ecm = {};
  async function maping() {
    if (selected != -1 && selected_ != -1) {
      progress = 0;
      recorridos = [];
      events = [];
      for (let x in $units_list) {
        let rec_p = [];
        try {
          rec_p = JSON.parse(await units_travel_db.getItem($units_list[x]));
        } catch (e) {
          console.log("Error leyendo travel", e);
        }

        let viaje_on = false;
        let viaje_ = {};
        for (let y in rec_p) {
          if (typeof rec_p[y]["p"] != "undefined") {
            let geo_check_ini = geo_search_(
              rec_p[y]["ps"][0] / 10000,
              rec_p[y]["ps"][1] / 10000,
              selected
            );
            rec_p[y]["name_from"] = geo_check_ini[0];
            rec_p[y]["id_from"] = geo_check_ini[2];
            rec_p[y]["type_from"] = geo_check_ini[1];
            rec_p[y]["fecha_inicio"] = moment(
              new Date(rec_p[y]["ds"] * 1000)
            ).format("DD/MM/YY HH:mm");
            rec_p[y]["fecha_inicio_"] = rec_p[y]["ds"];

            if (geo_check_ini[1] != 1) {
              //if(!viaje_on)
              //{
              viaje_ = {};
              viaje_on = true; // Incia Viaje
              viaje_["fecha_inicio"] = moment(
                new Date(rec_p[y]["ds"] * 1000)
              ).format("DD/MM/YY HH:mm");
              viaje_["fecha_inicio_"] = rec_p[y]["ds"];
              viaje_["from"] = geo_check_ini[0];
              viaje_["pos_"] = [];
              viaje_["duracion"] = 0;
              viaje_["recorridos"] = 1;
              viaje_["table_"] = [];
              //}
            }

            let geo_check_fin;

            if (viaje_on) {
              geo_check_fin = geo_search_(
                (rec_p[y]["ps"][0] -
                  rec_p[y]["p"][rec_p[y]["p"].length - 1][0]) /
                  10000,
                (rec_p[y]["ps"][1] -
                  rec_p[y]["p"][rec_p[y]["p"].length - 1][1]) /
                  10000,
                selected_
              );

              rec_p[y]["name_to"] = geo_check_fin[0];
              rec_p[y]["id_to"] = geo_check_fin[2];
              rec_p[y]["type_to"] = geo_check_fin[1];
              rec_p[y]["fecha_fin"] = moment(
                new Date(
                  (rec_p[y]["ds"] +
                    rec_p[y]["p"][rec_p[y]["p"].length - 1][3] * 60) *
                    1000
                )
              ).format("DD/MM/YY HH:mm");
              rec_p[y]["fecha_fin_"] =
                rec_p[y]["ds"] +
                rec_p[y]["p"][rec_p[y]["p"].length - 1][3] * 60;

              if (geo_check_fin[1] != 1) {
                viaje_on = false; // Termina Viaje
                viaje_["pos_"].push(rec_p[y]);
                viaje_["to"] = geo_check_fin[0];
                viaje_["fecha_fin_"] =
                  rec_p[y]["ds"] +
                  rec_p[y]["p"][rec_p[y]["p"].length - 1][3] * 60;
                viaje_["fecha_fin"] = moment(
                  new Date(viaje_["fecha_fin_"] * 1000)
                ).format("DD/MM/YY HH:mm");
                viaje_["duracion"] =
                  viaje_["duracion"] +
                  rec_p[y]["p"][rec_p[y]["p"].length - 1][3];
                viaje_["distance"] = 0;
                if (viaje_["from"] == viaje_["to"]) {
                  viaje_["travel_type"] = "loop";
                } else {
                  viaje_["travel_type"] = "";
                }
                viaje_["table_"].push([
                  geo_check_ini[0],
                  geo_check_fin[0],
                  rec_p[y]["p"][rec_p[y]["p"].length - 1][3],
                ]);
                viaje_["unit"] = $units_list[x];
                recorridos.push(viaje_);
                viaje_ = {};
                //console.log($units_list[x]);
              }
            }

            if (viaje_on) {
              viaje_["pos_"].push(rec_p[y]);
              viaje_["duracion"] =
                viaje_["duracion"] + rec_p[y]["p"][rec_p[y]["p"].length - 1][3];
              viaje_["recorridos"] = viaje_["recorridos"] + 1;
              viaje_["table_"].push([
                geo_check_ini[0],
                geo_check_fin[0],
                rec_p[y]["p"][rec_p[y]["p"].length - 1][3],
              ]);
            }
          }
        }
        progress = Math.round((x / $units_list.length) * 100);
      }
      progress = 100;
      console.log(recorridos);
      recorridos.sort(trips_order);
      recorridos = [...recorridos];

      let e = { target: { id: "trip_0" } };
      if (recorridos.length > 0) {
        trip_map(e);
      }
      viajes_list.scrollTop = 0;
    }
  }
  function trips_order(a, b) {
    return a.duracion - b.duracion;
  }
  function geo_search(lat, lng) {
    let box_size = 0.0001797 * 10; // 200 metros por celula
    let p = Math.floor((lat - 14.5408) / box_size);
    let q = Math.floor((lng + 118.2689) / box_size);
    let key_close_list = [
      p + "_" + q,
      p + "_" + (q + 1),
      p + "_" + (q - 1),
      p + 1 + "_" + q,
      p - 1 + "_" + q,
      p + 1 + "_" + (q + 1),
      p - 1 + "_" + (q - 1),
      p + 1 + "_" + (q - 1),
      p - 1 + "_" + (q + 1),
    ];
    let geos_arround = [];
    for (let w in key_close_list) {
      if ($geos_map[key_close_list[w]]) {
        for (let s in $geos_map[key_close_list[w]]) {
          if (!geos_arround.includes($geos_map[key_close_list[w]][s])) {
            geos_arround.push($geos_map[key_close_list[w]][s]);
          }
        }
      }
    }
    let found = false;
    for (let x in $poli) {
      if (geos_arround.includes(x)) {
        if (dentro([lat, lng], $poli[x][0])) {
          return [$poli[x][1], 2, x];
        }
      }
    }
    for (let x in $geos) {
      // filtro
      if (geos_arround.includes($geos[x][0])) {
        for (let y in $geos[x][1]) {
          let lat_ = $geos[x][1][y][0];
          let lng_ = $geos[x][1][y][1];
          if (get_km(lat, lng, lat_, lng_) <= 0.13) {
            if (typeof $geos_class[$geos[x][0]] == "undefined") {
              return [
                "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                1,
                $geos[x][0],
              ];
            } else {
              return [
                $geos_class[$geos[x][0]][0],
                $geos_class[$geos[x][0]][1],
                $geos[x][0],
              ];
            }
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    return ["Geocerca_automatica_", 1, ""];
  }
  function geo_search_(lat, lng, geo_org) {
    let found = false;
    for (let x in $geos) {
      if ($geos[x][0] == geo_org) {
        for (let y in $geos[x][1]) {
          let lat_ = $geos[x][1][y][0];
          let lng_ = $geos[x][1][y][1];
          if (get_km(lat, lng, lat_, lng_) <= 0.13) {
            if (typeof $geos_class[$geos[x][0]] == "undefined") {
              return [
                "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                1,
                $geos[x][0],
              ];
            } else {
              return [
                $geos_class[$geos[x][0]][0],
                $geos_class[$geos[x][0]][1],
                $geos[x][0],
              ];
            }
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    for (let x in $poli) {
      if (x == geo_org) {
        if (dentro([lat, lng], $poli[x][0])) {
          return [$poli[x][1], 2, x];
        }
      }
    }
    return ["Geocerca_automatica_", 1, ""];
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  function dentro(point, vs) {
    let x = point[0],
      y = point[1];
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0],
        yi = vs[i][1];
      let xj = vs[j][0],
        yj = vs[j][1];
      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }
  let viajes_list;
  let selected_event = -1;
  let route_stat = false;
  let geo_status = "Desconocida";
  let t_selected = 0;
  let events = [];
  let coordenada = [];
  let c_coordenadas = [];
  let mark_pause = [];
  let marks_timeout = [];
  let recorrido = [];
  let colors_line = [
    "#2196f3",
    "#e91e63",
    "#03a9f4",
    "#9c27b0",
    "#00bcd4",
    "#673ab7",
    "#009688",
    "#3f51b5",
    "#4caf50",
    "#ff5722",
    "#8bc34a",
    "#ff9800",
    "#cddc39",
    "#ffc107",
    "#607d8b",
    "#ffeb3b",
    "#9e9e9e",
    "#212121",
    "#f44336",
  ];
  let color_sombra = [
    "#e3f2fd",
    "#fce4ec",
    "#e1f5fe",
    "#f3e5f5",
    "#e0f7fa",
    "#ede7f6",
    "#e0f2f1",
    "#e8eaf6",
    "#e8f5e9",
    "#fbe9e7",
    "#f1f8e9",
    "#fff3e0",
    "#f9fbe7",
    "#fff8e1",
    "#eceff1",
    "#fffde7",
    "#fafafa",
    "#fafafa",
    "#ffebee",
  ];
  let m_inicio = [],
    m_fin = [],
    m_pause = [];
  let lista_eventos;
  let saved_type, saved_text, route_name;
  let saved_ok = false;
  function trip_map(event) {
    selected_event = -1;
    route_stat = false;
    geo_status = "Desconocida";
    t_selected = event.target.id.split("_")[1];
    if (typeof t_selected == "undefined") {
      return 0;
    }
    events = [];
    let date_0, date_0_, time_0, distance_0, name_0, id_0, type_0;
    let date_1, date_1_, time_1, name_1, id_1, type_1;
    let color_c = 0;
    c_coordenadas = [];
    mark_pause = [];
    let total_dist_rec = 0;
    let bounds = new google.maps.LatLngBounds();
    for (let x in recorridos[t_selected]["pos_"]) {
      let last_lat = recorridos[t_selected]["pos_"][x]["ps"][0] / 10000;
      let last_lng = recorridos[t_selected]["pos_"][x]["ps"][1] / 10000;
      let total_dist = 0;
      for (let y in recorridos[t_selected]["pos_"][x]["p"]) {
        let lat =
          (recorridos[t_selected]["pos_"][x]["ps"][0] -
            recorridos[t_selected]["pos_"][x]["p"][y][0]) /
          10000;
        let lng =
          (recorridos[t_selected]["pos_"][x]["ps"][1] -
            recorridos[t_selected]["pos_"][x]["p"][y][1]) /
          10000;
        total_dist = total_dist + get_km(last_lat, last_lng, lat, lng);
        last_lat = lat;
        last_lng = lng;
      }

      date_0 = recorridos[t_selected]["pos_"][x]["fecha_inicio"];
      date_0_ = recorridos[t_selected]["pos_"][x]["fecha_inicio_"];
      time_0 =
        recorridos[t_selected]["pos_"][x]["p"][
          recorridos[t_selected]["pos_"][x]["p"].length - 1
        ][3];
      distance_0 = Math.round(total_dist);
      total_dist_rec = total_dist_rec + distance_0;
      name_0 = recorridos[t_selected]["pos_"][x]["name_from"];
      id_0 = recorridos[t_selected]["pos_"][x]["id_from"];
      type_0 = recorridos[t_selected]["pos_"][x]["type_from"];
      events.push({
        type: 0,
        date: date_0,
        date_: date_0_,
        time: time_code(time_0),
        time_: time_0,
        distance: distance_0,
        name: name_0,
        id: id_0,
        geo_type: type_0,
        color: colors_line[color_c],
        pos: [
          recorridos[t_selected]["pos_"][x]["ps"][0] / 10000,
          recorridos[t_selected]["pos_"][x]["ps"][1] / 10000,
        ],
      });

      coordenada = [];
      for (let y in recorridos[t_selected]["pos_"][x]["p"]) {
        let time_stop =
          recorridos[t_selected]["pos_"][x]["p"][y][4] -
          recorridos[t_selected]["pos_"][x]["p"][y][3];
        let date_2 =
          recorridos[t_selected]["pos_"][x]["ds"] +
          recorridos[t_selected]["pos_"][x]["p"][y][3] * 60;

        let lat_map =
          (recorridos[t_selected]["pos_"][x]["ps"][0] -
            recorridos[t_selected]["pos_"][x]["p"][y][0]) /
          10000;
        let lng_map =
          (recorridos[t_selected]["pos_"][x]["ps"][1] -
            recorridos[t_selected]["pos_"][x]["p"][y][1]) /
          10000;

        if (time_stop > 4) {
          events.push({
            type: 2,
            date: moment(new Date(date_2 * 1000)).format("DD/MM/YY HH:mm"),
            date_: date_2,
            time: time_code(time_stop),
            time_: time_stop,
            color: colors_line[color_c],
            pos: [lat_map, lng_map],
          });
          mark_pause.push({ lat: lat_map, lng: lng_map });
        }
        coordenada.push({ lat: lat_map, lng: lng_map });
        bounds.extend({ lat: lat_map, lng: lng_map });
      }
      c_coordenadas.push({
        color: colors_line[c_coordenadas.length],
        cors: coordenada,
      });

      date_1 = recorridos[t_selected]["pos_"][x]["fecha_fin"];
      date_1_ = recorridos[t_selected]["pos_"][x]["fecha_fin_"];
      time_1 = 0;
      name_1 = recorridos[t_selected]["pos_"][x]["name_to"];
      id_1 = recorridos[t_selected]["pos_"][x]["id_to"];
      type_1 = recorridos[t_selected]["pos_"][x]["type_to"];
      if (x < recorridos[t_selected]["pos_"].length - 1) {
        time_1 = Math.round(
          (recorridos[t_selected]["pos_"][parseInt(x) + 1]["fecha_inicio_"] -
            recorridos[t_selected]["pos_"][x]["fecha_fin_"]) /
            60
        );
      }
      events.push({
        type: 1,
        date: date_1,
        date_: date_1_,
        time: time_code(time_1),
        time_: time_1,
        name: name_1,
        id: id_1,
        geo_type: type_1,
        color: colors_line[color_c],
        pos: [last_lat, last_lng],
      });

      color_c++;
    }
    recorridos[t_selected]["distance"] = total_dist_rec;

    for (let w in marks_timeout) {
      clearTimeout(marks_timeout[w]);
    }
    marks_timeout = [];
    for (let w in recorrido) {
      if (typeof recorrido[w] != "undefined") {
        recorrido[w].setMap(null);
      }
    }
    recorrido = [];
    for (let w in m_inicio) {
      if (typeof m_inicio[w] != "undefined") {
        m_inicio[w].setMap(null);
      }
    }
    m_inicio = [];
    for (let w in m_fin) {
      if (typeof m_fin[w] != "undefined") {
        m_fin[w].setMap(null);
      }
    }
    m_fin = [];
    for (let w in m_pause) {
      if (typeof m_pause[w] != "undefined") {
        m_pause[w].setMap(null);
      }
    }
    m_pause = [];
    if (typeof directionsRenderer != "undefined") {
      directionsRenderer.setMap(null);
    }

    let time_mark = 500;
    marks_timeout.push(
      setTimeout(
        function () {
          m_inicio[0] = new google.maps.Marker({
            position: c_coordenadas[0]["cors"][0],
            animation: google.maps.Animation.DROP,
            map: map,
            icon: "img/dd-start.png",
            zIndex: 2,
          });
        },
        time_mark,
        c_coordenadas
      )
    );
    time_mark = time_mark + 150;

    for (let z in c_coordenadas) {
      osrm(c_coordenadas[z]["cors"], z);
      /*
      recorrido[z] = new google.maps.Polyline({
        path: c_coordenadas[z]["cors"],
        geodesic: true,
        strokeColor: c_coordenadas[z]["color"],
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeColor: c_coordenadas[z]["color"],
            },
            offset: "100px",
            repeat: "100px",
          },
        ],
      });
      */

      marks_timeout.push(
        setTimeout(
          function () {
            m_fin[z] = new google.maps.Marker({
              position:
                c_coordenadas[z]["cors"][c_coordenadas[z]["cors"].length - 1],
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/dd-end.png",
              zIndex: 1,
            });
          },
          time_mark,
          z
        )
      );
      time_mark = time_mark + 150;
    }
    for (let z in mark_pause) {
      marks_timeout.push(
        setTimeout(
          function () {
            m_pause[z] = new google.maps.Marker({
              position: mark_pause[z],
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/dd-pause.png",
              zIndex: 0,
            });
          },
          time_mark,
          z
        )
      );
      time_mark = time_mark + 150;
    }
    map.fitBounds(bounds);
    lista_eventos.scrollTop = 0;
    // ECM
    let ecm_time_ini = recorridos[t_selected]["pos_"][0]["fecha_inicio_"];
    let ecm_time_fin =
      recorridos[t_selected]["pos_"][recorridos[t_selected]["pos_"].length - 1][
        "fecha_fin_"
      ];
    let fuel_ini = 0,
      fuel_fin = 0;
    let time_base,
      data_base,
      date_now = 0,
      last_data = 0,
      last_ = 0;
    if (typeof ecm.total_fuel_1708 != "undefined") {
      if (ecm.total_fuel_1708.length > 0) {
        for (let x in ecm.total_fuel_1708) {
          if (x == 0) {
            data_base = ecm.total_fuel_1708[x][0];
            time_base = ecm.total_fuel_1708[x][1] * 60;
            date_now = time_base;
            last_data = data_base;
          } else {
            date_now = time_base + ecm.total_fuel_1708[x][1] * 60;
            last_data = data_base + ecm.total_fuel_1708[x][0];
          }
          if (date_now > ecm_time_fin && fuel_ini != 0) {
            fuel_fin = last_data;
            break;
          }
          if (date_now > ecm_time_ini && fuel_ini == 0) {
            if (date_now - ecm_time_ini < 5 * 60) {
              fuel_ini = last_data;
            } else if (x > 0) {
              fuel_ini = last_;
            }
          } else last_ = last_data;
        }
      }
    }
    recorridos[t_selected]["fuel_1708"] =
      fuel_fin - fuel_ini > 0 ? fuel_fin - fuel_ini : 0;
    //console.log("fuel_1708",fuel_ini,fuel_fin);

    (fuel_ini = 0), (fuel_fin = 0);
    last_ = 0;
    if (typeof ecm.total_fuel_1939 != "undefined") {
      if (ecm.total_fuel_1939.length > 0) {
        for (let x in ecm.total_fuel_1939) {
          if (x == 0) {
            data_base = ecm.total_fuel_1939[x][0];
            time_base = ecm.total_fuel_1939[x][1] * 60;
            date_now = time_base;
            last_data = data_base;
          } else {
            date_now = time_base + ecm.total_fuel_1939[x][1] * 60;
            last_data = data_base + ecm.total_fuel_1939[x][0];
          }
          if (date_now > ecm_time_fin && fuel_ini != 0) {
            fuel_fin = last_data;
            break;
          }
          if (date_now > ecm_time_ini && fuel_ini == 0) {
            if (date_now - ecm_time_ini < 5 * 60) {
              fuel_ini = last_data;
            } else if (x > 0) {
              fuel_ini = last_;
            }
          } else last_ = last_data;
        }
      }
    }
    recorridos[t_selected]["fuel_1939"] =
      fuel_fin - fuel_ini > 0 ? fuel_fin - fuel_ini : 0;
    //console.log("fuel_1939",fuel_ini,fuel_fin);

    get_ruta(c_coordenadas);
  }
  function route_travel(e) {
    route_stat = true;
  }
  function route_save(e) {
    let z, out;
    geo_status = route_name.value;
    for (let x in recorridos[t_selected]["pos_"]) {
      if (x == 0) {
        z = {
          pos_start: recorridos[t_selected]["pos_"][x]["pos_start"],
          pos: [],
          stops: [],
        };
        for (let w in recorridos[t_selected]["pos_"][x]["pos"]) {
          z["pos"].push([
            recorridos[t_selected]["pos_"][x]["pos"][w][0],
            recorridos[t_selected]["pos_"][x]["pos"][w][1],
          ]);
        }
      } else {
        for (let w in recorridos[t_selected]["pos_"][x]["pos"]) {
          out = [
            recorridos[t_selected]["pos_"][x]["pos"][w][0],
            recorridos[t_selected]["pos_"][x]["pos"][w][1],
          ];
          out[0] = recorridos[t_selected]["pos_"][x]["pos_start"][0] - out[0];
          out[1] = recorridos[t_selected]["pos_"][x]["pos_start"][1] - out[1];
          out[0] = z["pos_start"][0] - out[0];
          out[1] = z["pos_start"][1] - out[1];
          z["pos"].push(out);
        }
      }
    }
    for (let x in events) {
      if (events[x]["type"] == 0 && x != 0 && events[x]["geo_type"] == 3) {
        z["stops"].push(events[x]["pos"]);
      }
    }
    socket_query.emit("route_save", route_name.value, z, 0, (data) => {
      if (data == "ok") {
        route_stat = false;
        saved_type = "alert-success";
        saved_text = "Ruta Guardada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }
  function event_select(e) {
    if (e.target.id.split("_")[0] == "events") {
      selected_event = e.target.id.split("_")[1];
      map.panTo({
        lat: events[selected_event]["pos"][0],
        lng: events[selected_event]["pos"][1],
      });
      map.setZoom(16);
    }
  }

  async function get_ruta(ruta_ev) {
    let ruta_ev_ = [];
    let ruta_ev_f = [];
    for (let x in ruta_ev) {
      ruta_ev_ = ruta_ev_.concat(ruta_ev[x].cors);
    }
    for (let x in ruta_ev_) {
      ruta_ev_f.push([ruta_ev_[x].lat, ruta_ev_[x].lng]);
    }
    console.log(ruta_ev_f);

    for (let x in $route_list) {
      let geo_org = geo_search(
        $route_list[x][2][0] / 10000,
        $route_list[x][2][1] / 10000
      );
      let geo_dest = geo_search(
        $route_list[x][3][0] / 10000,
        $route_list[x][3][1] / 10000
      );
      if (
        recorridos[t_selected]["pos_"][0]["id_from"] == geo_org[2] &&
        recorridos[t_selected]["pos_"][
          recorridos[t_selected]["pos_"].length - 1
        ]["id_to"] == geo_dest[2]
      ) {
        console.log($route_list[x]);
        console.log(geo_org, geo_dest);
        let route;
        try {
          route = await routes_db.getItem($route_list[x][0]);
        } catch (e) {
          console.log("Error leyendo routa", e);
        }

        let lat_org = route["pos_start"][0];
        let lng_org = route["pos_start"][1];
        let coordenadas = [];
        for (let x in route["pos"]) {
          let lat = (lat_org - route["pos"][x][0]) / 10000;
          let lng = (lng_org - route["pos"][x][1]) / 10000;
          coordenadas.push([lat, lng]);
        }
        console.log(coordenadas);
        let result = ruta_comp2(ruta_ev_f, coordenadas);
        console.log("Ruta resultado ", result);
        if (result > 90) {
          geo_status = $route_list[x][1];
        }
      }
    }
    console.log(recorridos[t_selected]);
  }
  var error = 0.00001;
  function ruta_comp2(ruta_ev, ruta_org) {
    let org_pos = 0;
    let x1,
      y1,
      x2,
      y2,
      x,
      y,
      m,
      b,
      p_rec,
      x_rec,
      y_rec,
      dentro_x,
      dentro_y,
      puntos_ev;

    puntos_ev = ruta_ev.length;
    for (var z = 0; z < ruta_ev.length; z++) {
      x = ruta_ev[z][0];
      y = ruta_ev[z][1];
      for (var w = org_pos; w < ruta_org.length - 1; w++) {
        x1 = ruta_org[w][0];
        y1 = ruta_org[w][1];
        x2 = ruta_org[w + 1][0];
        y2 = ruta_org[w + 1][1];
        m = (y2 - y1) / (x2 - x1);
        if (isFinite(m)) {
          b = y1 - m * x1;
          p_rec = Math.abs(m * x - y + b) / Math.sqrt(Math.pow(m, 2) + 1);
          x_rec = (x + m * y - b * m) / (Math.pow(m, 2) + 1);
          y_rec = (Math.pow(m, 2) * y + m * x + b) / (Math.pow(m, 2) + 1);
        } else {
          p_rec = Math.abs(x1 - x);
          x_rec = x1;
          y_rec = y;
        }
        dentro_x =
          x1 > x2
            ? x_rec <= x1 + error && x_rec >= x2 - error
              ? true
              : false
            : x1 < x2
              ? x_rec >= x1 - error && x_rec <= x2 + error
                ? true
                : false
              : true;
        dentro_y =
          y1 > y2
            ? y_rec <= y1 + error && y_rec >= y2 - error
              ? true
              : false
            : y1 < y2
              ? y_rec >= y1 - error && y_rec <= y2 + error
                ? true
                : false
              : true;
        p_rec = ((p_rec * Math.PI) / 180) * 6371;
        if (dentro_x && dentro_y) {
          // Verifica que el punto este dentreo del rango de x1 x2 y1 y2
          if (p_rec < 0.5) {
            // KM de separacion
            puntos_ev--;
            org_pos = w > 1 ? w - 2 : 0;
            break;
          }
        }
        // Algoritmo para fuera de angulo (Prototipo)
        let d1 = Math.sqrt(Math.pow(y2 - y, 2) + Math.pow(x2 - x, 2)); // Distancia a puntos.
        d1 = ((d1 * Math.PI) / 180) * 6371;
        if (d1 < 0.5) {
          // KM de separacion
          puntos_ev--;
          org_pos = w > 1 ? w - 2 : 0;
          break;
        }
        let d2 = Math.sqrt(Math.pow(y1 - y, 2) + Math.pow(x1 - x, 2)); // Distancia a puntos.
        d2 = ((d2 * Math.PI) / 180) * 6371;
        if (d2 < 0.5) {
          // KM de separacion
          puntos_ev--;
          org_pos = w > 1 ? w - 2 : 0;
          break;
        }
      }
    }
    console.log(puntos_ev, ruta_ev.length);
    return ruta_ev.length > 2
      ? ((ruta_ev.length - puntos_ev) / ruta_ev.length) * 100
      : 0;
  }
  let new_route_mode = false;
  let org_pos_p = "";
  let dest_pos_p = "";
  function new_route() {
    new_route_mode = true;
    for (let w in recorrido) {
      if (typeof recorrido[w] != "undefined") {
        recorrido[w].setMap(null);
      }
    }
    recorrido = [];
    for (let w in m_inicio) {
      if (typeof m_inicio[w] != "undefined") {
        m_inicio[w].setMap(null);
      }
    }
    m_inicio = [];
    for (let w in m_fin) {
      if (typeof m_fin[w] != "undefined") {
        m_fin[w].setMap(null);
      }
    }
    m_fin = [];
    for (let w in m_pause) {
      if (typeof m_pause[w] != "undefined") {
        m_pause[w].setMap(null);
      }
    }
    m_pause = [];

    recorridos = [];
    events = [];
    let org_geo = selected;
    let dest_geo = selected_;
    let org_pos = "";
    let dest_pos = "";
    console.log(org_geo, dest_geo);
    for (let x in $geos) {
      if ($geos[x][0] == org_geo) {
        org_pos = { lat: $geos[x][1][0][0], lng: $geos[x][1][0][1] };
      }
      if ($geos[x][0] == dest_geo) {
        dest_pos = { lat: $geos[x][1][0][0], lng: $geos[x][1][0][1] };
      }
      if (org_pos != "" && dest_pos != "") {
        break;
      }
    }
    if ($poli[org_geo]) {
      let c_ = centro($poli[org_geo][0]);
      org_pos = { lat: c_[0], lng: c_[1] };
    }
    if ($poli[dest_geo]) {
      let c_ = centro($poli[dest_geo][0]);
      dest_pos = { lat: c_[0], lng: c_[1] };
    }
    if (org_pos != "" && dest_pos != "") {
      org_pos_p = org_pos;
      dest_pos_p = dest_pos;
      displayRoute(org_pos, dest_pos, directionsService, directionsRenderer);
    }
  }
  function save_new_route() {
    new_route_mode = false;
    let out_vector = [];
    if (all_steps.length > 0) {
      let lat_start = Math.round(all_steps[0][0] * 10000);
      let lng_start = Math.round(all_steps[0][1] * 10000);
      out_vector.push({
        pos_start: [lat_start, lng_start],
        pos: [[0, 0]],
        stops: [],
      });
      for (let x in all_steps) {
        let lat_ = Math.round(all_steps[x][0] * 10000);
        let lng_ = Math.round(all_steps[x][1] * 10000);
        if (
          lat_start != lat_ &&
          lng_start != lng_ &&
          (out_vector[0].pos[out_vector[0].pos.length - 1][0] !=
            lat_start - lat_ ||
            out_vector[0].pos[out_vector[0].pos.length - 1][1] !=
              lng_start - lng_)
        ) {
          out_vector[0].pos.push([lat_start - lat_, lng_start - lng_]);
        }
      }
      console.log(out_vector[0]);

      socket_query.emit(
        "route_save",
        route_name.value,
        out_vector[0],
        1,
        (data) => {
          if (data == "ok") {
            route_stat = false;
            saved_type = "alert-success";
            saved_text = "Ruta Guardada";
            saved_ok = true;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    }
  }
  function cancel_new_route() {
    new_route_mode = false;
    if (typeof directionsRenderer != "undefined") {
      directionsRenderer.setMap(null);
    }
  }
  function displayRoute(origin, destination, service, display) {
    console.log("displayRoute", "origin", origin, "destination", destination);
    service.route(
      {
        origin: origin,
        destination: destination,
        waypoints: [],
        travelMode: "DRIVING",
        avoidTolls: false,
      },
      function (response, status) {
        if (status === "OK") {
          //console.log("Servicio ok ",response);
          display.setDirections(response);
        } else {
          new_route_mode = false;
          saved_ok = true;
          saved_text = "Ruta no disponible";
          saved_type = "alert-danger";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      }
    );
  }
  $: {
    selected;
    selected_;
    new_route_mode = false;
    if (typeof directionsRenderer != "undefined") {
      directionsRenderer.setMap(null);
    }
  }

  let origen_filter_mode = false;
  let filter_origen;
  function origen_filter() {
    origen_filter_mode = origen_filter_mode ? false : true;
    if (origen_filter_mode) filter_origen = "";
  }
  function focus(i) {
    i.focus();
  }
  function run_origen_filter(event) {
    for (let x in $geos_class_list) {
      if ($geos_class_list[x][1] == event.target.value) {
        selected = $geos_class_list[x][0];
        selected_nicks = $geos_class_list[x][1];
        origen_filter_mode = false;
        filter_origen = "";
        let origen_l = document.getElementById("origen_l_" + selected);
        if (origen_l != null) {
          origen_l.scrollIntoView();
        }
      }
    }
    for (let x in $poli_list) {
      if ($poli_list[x][2] == event.target.value) {
        selected = $poli_list[x][0];
        selected_nicks = $poli_list[x][2];
        origen_filter_mode = false;
        filter_origen = "";
        let origen_l = document.getElementById("origen_l_" + selected);
        if (origen_l != null) {
          origen_l.scrollIntoView();
        }
      }
    }
  }

  let destino_filter_mode = false;
  let filter_destino;
  function destino_filter() {
    destino_filter_mode = destino_filter_mode ? false : true;
    if (destino_filter_mode) filter_destino = "";
  }
  function run_destino_filter(event) {
    for (let x in $geos_class_list) {
      if ($geos_class_list[x][1] == event.target.value) {
        selected_ = $geos_class_list[x][0];
        selected_nicks_ = $geos_class_list[x][1];
        //get_geos(x, 2);
        destino_filter_mode = false;
        filter_destino = "";
        let destino_l = document.getElementById("destino_l_" + selected_);
        if (destino_l != null) {
          destino_l.scrollIntoView();
        }
      }
    }
    for (let x in $poli_list) {
      if ($poli_list[x][2] == event.target.value) {
        selected_ = $poli_list[x][0];
        selected_nicks_ = $poli_list[x][2];
        destino_filter_mode = false;
        filter_destino = "";
        let destino_l = document.getElementById("destino_l_" + selected_);
        if (destino_l != null) {
          destino_l.scrollIntoView();
        }
      }
    }
  }
  async function osrm(coordenada, z) {
    // OSRM
    let coordenada_osrm = "";
    let radius_osrm = "";
    for (let t in coordenada) {
      coordenada_osrm =
        coordenada_osrm + coordenada[t].lng + "," + coordenada[t].lat + ";";
    }
    for (let t in coordenada) {
      radius_osrm = radius_osrm + "20%3B";
    }
    let coordenada_first = coordenada[0];
    let coordenada_last = coordenada[coordenada.length - 1];

    coordenada_osrm = coordenada_osrm.slice(0, -1);
    radius_osrm = radius_osrm.slice(0, -3);
    let osrm_r = await fetch(
      "https://route.omnitracs.online/match/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full&radiuses=" +
        radius_osrm
    );
    osrm_r = await osrm_r.json();
    if (osrm_r.code == "Ok") {
      if (osrm_r.matchings[0].confidence > 0.001) {
        //0.2
        coordenada = [];
        for (let t in osrm_r.matchings) {
          for (let y in osrm_r.matchings[t].geometry.coordinates) {
            coordenada.push({
              lat: osrm_r.matchings[t].geometry.coordinates[y][1],
              lng: osrm_r.matchings[t].geometry.coordinates[y][0],
            });
          }
        }
        coordenada.unshift(coordenada_first);
        coordenada.push(coordenada_last);
      }
      console.log("confidence", osrm_r.matchings[0].confidence);
    }
    //---------
    recorrido[z] = new google.maps.Polyline({
      path: coordenada,
      geodesic: true,
      strokeColor: c_coordenadas[z]["color"],
      strokeOpacity: 1.0,
      strokeWeight: 2.5,
      map: map,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            strokeColor: c_coordenadas[z]["color"],
          },
          offset: "100px",
          repeat: "100px",
        },
      ],
    });
  }
  // Centroide
  function centro(pts) {
    let first = pts[0],
      last = pts[pts.length - 1];
    if (first[0] != last[0] || first[1] != last[1]) pts.push(first);
    let twicearea = 0,
      x = 0,
      y = 0,
      nPts = pts.length,
      p1,
      p2,
      f;
    for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
      p1 = pts[i];
      p2 = pts[j];
      f = p1[0] * p2[1] - p2[0] * p1[1];
      twicearea += f;
      x += (p1[0] + p2[0]) * f;
      y += (p1[1] + p2[1]) * f;
    }
    f = twicearea * 3;
    return [x / f, y / f];
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-md-auto h-100">
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100" style="min-width:13rem">
          <div class="card h-100 bg-light card-b">
            <div class="d-flex flex-column h-100">
              <div class="card-header bg-dark text-white b-0">
                <div class="d-flex justify-content-between">
                  <div>
                    {#if !origen_filter_mode}
                      <i class="material-icons md-85">play_circle_filled</i> Origen
                    {:else}
                      <input
                        list="lista_origenes"
                        placeholder="Origen"
                        use:focus
                        bind:value={filter_origen}
                        on:change={run_origen_filter}
                      />
                      <datalist id="lista_origenes">
                        {#each $geos_class_list as item, i}
                          <option value={item[1]} />{/each}
                        {#each $poli_list as item, i}
                          <option value={item[2]} />{/each}
                      </datalist>
                    {/if}
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-link p-0"
                      on:click={origen_filter}
                    >
                      <i class="material-icons md-85" style="color:#fafafa"
                        >{origen_filter_mode ? "cancel" : "search"}</i
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body h-100 overflow-auto">
                <div class="list-group" id="geo_w_c">
                  {#each $geos_class_list as item, i}
                    <a
                      id="origen_l_{item[0]}"
                      href="/{item[0]}"
                      class:active_={selected == item[0]}
                      on:click|preventDefault={() => {
                        get_geos(item[0], 1);
                        selected_nicks = item[1];
                      }}
                      class:list-group-item-warning={selected == item[0]}
                      class="g2_list list-group-item list-group-item-action"
                      >{item[1]}</a
                    >
                  {/each}
                  {#each $poli_list as item, i}
                    <a
                      id="origen_l_{item[0]}"
                      href="/{item[0]}"
                      class:active_={selected_nicks == item[2]}
                      on:click|preventDefault={() => {
                        selected_nicks = item[2];
                        selected = item[0];
                      }}
                      class:list-group-item-warning={selected_nicks == item[2]}
                      class="g2_list list-group-item list-group-item-action"
                      >{item[2]}</a
                    >
                  {/each}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100" style="min-width:13rem">
          <div class="card h-100 bg-light card-b">
            <div class="d-flex flex-column h-100">
              <div class="card-header bg-dark text-white b-0">
                <div class="d-flex justify-content-between">
                  <div>
                    {#if !destino_filter_mode}
                      <i class="material-icons md-85">stop</i> Destino
                    {:else}
                      <input
                        list="lista_destino"
                        placeholder="Destino"
                        use:focus
                        bind:value={filter_destino}
                        on:change={run_destino_filter}
                      />
                      <datalist id="lista_destino">
                        {#each $geos_class_list as item, i}
                          <option value={item[1]} />{/each}
                        {#each $poli_list as item, i}
                          <option value={item[2]} />{/each}
                      </datalist>
                    {/if}
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-link p-0"
                      on:click={destino_filter}
                    >
                      <i class="material-icons md-85" style="color:#fafafa"
                        >{destino_filter_mode ? "cancel" : "search"}</i
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body h-100 overflow-auto">
                <div class="list-group" id="geo_w_c">
                  {#each $geos_class_list as item, i}
                    <a
                      id="destino_l_{item[0]}"
                      href="/{item[0]}"
                      class:active_={selected_ == item[0]}
                      on:click|preventDefault={() => {
                        get_geos(item[0], 2);
                        selected_nicks_ = item[1];
                      }}
                      class:list-group-item-warning={selected_ == item[0]}
                      class="g2_list list-group-item list-group-item-action"
                      >{item[1]}</a
                    >
                  {/each}
                  {#each $poli_list as item, i}
                    <a
                      id="destino_l_{item[0]}"
                      href="/{item[0]}"
                      class:active_={selected_nicks_ == item[2]}
                      on:click|preventDefault={() => {
                        selected_nicks_ = item[2];
                        selected_ = item[0];
                      }}
                      class:list-group-item-warning={selected_nicks_ == item[2]}
                      class="g2_list list-group-item list-group-item-action"
                      >{item[2]}</a
                    >
                  {/each}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md h-100">
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100">
          <div class="h-100" id="map" />
        </div>
      </div>
      <div class="row h-50 no-gutters">
        <div class="col-md-7 h-100">
          <div class="card h-100 bg-light">
            <div class="card-header b-0">
              <div class="d-flex justify-content-between">
                <div>
                  <i class="material-icons md-85">merge_type</i> Lista de trayectos
                </div>
                <div>
                  {#if progress == 100 && !new_route_mode}
                    <button
                      on:click={new_route}
                      class="btn btn-info"
                      type="button"
                      disabled={selected == -1 || selected_ == -1
                        ? "disabled"
                        : ""}
                    >
                      <i class="material-icons md-85">rounded_corner</i>
                      Generar Ruta
                    </button>
                  {:else if new_route_mode}
                    <input
                      type="text"
                      value="{selected_nicks} a {selected_nicks_}"
                      bind:this={route_name}
                      size="30"
                    />
                    <button
                      on:click={save_new_route}
                      class="btn btn-success"
                      type="button"
                    >
                      <i class="material-icons md-85">save</i>
                      Guardar ruta
                    </button>
                    <button
                      on:click={cancel_new_route}
                      class="btn btn-danger"
                      type="button"
                    >
                      <i class="material-icons md-85">clear</i>
                      Cancelar
                    </button>
                  {/if}
                  {#if !new_route_mode}
                    <button
                      on:click={maping}
                      class="btn btn-{progress != 100 ? 'primary' : 'success'}"
                      type="button"
                      disabled={progress != 100
                        ? "disabled"
                        : selected == -1 || selected_ == -1
                          ? "disabled"
                          : ""}
                    >
                      {#if progress != 100}
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      {:else}
                        <i class="material-icons md-85">search</i>
                      {/if}
                      {progress != 100 ? "Buscando..." : "Buscar trayectos"}
                    </button>
                  {/if}
                </div>
              </div>
            </div>
            <div
              class="card-body flex-grow-1 overflow-auto"
              bind:this={viajes_list}
            >
              {#if progress != 100}
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {progress}%;"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {progress}%
                  </div>
                </div>
              {/if}
              {#each recorridos as item, i}
                <div class="card m-3" style="font-size:0.8rem">
                  <p class="card-header b-0" id="trip_{i}" on:click={trip_map}>
                    <strong
                      >[{typeof $nicks_list[item.unit] != "undefined"
                        ? $nicks_list[item.unit]
                        : item.unit}]</strong
                    >
                    {item.fecha_inicio} | {item.from}<i
                      class="material-icons bblue">forward</i
                    >{item.fecha_fin} | {item.to}<i
                      class="material-icons orange"
                      tooltip="Viaje circular">{item.travel_type}</i
                    >
                  </p>
                  {#if t_selected == i}
                    <div class="card-body m-3">
                      <div class="card-text">
                        <div>
                          <span class="badge travel badge-pill badge-primary"
                            >{item.recorridos} recorrido{item.recorridos > 1
                              ? "s"
                              : ""}</span
                          >
                          <span class="badge travel badge-pill badge-success"
                            >{time_code(item.duracion)} encendida</span
                          >
                          <span class="badge travel badge-pill badge-dark"
                            >{item.distance}km</span
                          >
                        </div>
                        {#if item.fuel_1708 != 0 && typeof item.fuel_1708 != "undefined"}
                          <div>
                            <span class="badge travel badge-pill badge-info"
                              >j1708</span
                            >
                            <span class="badge travel badge-pill badge-info"
                              >Consumo {item.fuel_1708} Litros</span
                            >
                            <span class="badge travel badge-pill badge-info"
                              >Rendimiento {Math.round(
                                (item.distance / item.fuel_1708) * 100
                              ) / 100}</span
                            >
                          </div>
                        {/if}
                        {#if item.fuel_1939 != 0 && typeof item.fuel_1939 != "undefined"}
                          <div>
                            <span class="badge travel badge-pill badge-dark"
                              >j1939</span
                            >
                            <span class="badge travel badge-pill badge-dark"
                              >Consumo {item.fuel_1939} Litros</span
                            >
                            <span class="badge travel badge-pill badge-dark"
                              >Rendimiento {Math.round(
                                (item.distance / item.fuel_1939) * 100
                              ) / 100}</span
                            >
                          </div>
                        {/if}
                        <div
                          class="d-flex justify-content-end align-items-center mt-2"
                        >
                          <strong class="mx-2">Ruta:</strong>
                          <span
                            on:click={(e) => {
                              if (geo_status == "Desconocida") {
                                route_travel(e);
                              }
                            }}
                            style={geo_status == "Desconocida"
                              ? "color:#2196f3"
                              : ""}
                            class:flex-grow-1={route_stat}
                          >
                            {#if !route_stat}
                              <strong>{geo_status}</strong>
                            {:else}
                              <input
                                type="text"
                                value="{item.from} a {item.to}"
                                class="form-control mini"
                                bind:this={route_name}
                              />
                            {/if}
                          </span>
                          {#if route_stat}
                            <button
                              on:click={route_save}
                              type="button"
                              class="btn btn-success mx-2">Guardar</button
                            >
                          {/if}
                        </div>
                      </div>
                    </div>
                  {/if}
                </div>
              {/each}
            </div>
          </div>
        </div>
        <div class="col-md-5 h-100">
          <div class="card h-100 bg-light">
            <div class="d-flex flex-column h-100">
              <div class="card-header b-0">
                <i class="material-icons md-85">event_available</i> Eventos
                <span class="badge badge-pill badge-info">{events.length}</span>
              </div>
              <div
                class="card-body flex-grow-1 overflow-auto"
                bind:this={lista_eventos}
              >
                <table
                  class="table table-sm table-striped"
                  style="font-size:0.75rem"
                >
                  <tbody on:click={event_select}>
                    {#each events as item, i}
                      <tr
                        ><td
                          id="events_{i}"
                          class:table-info={selected_event == i}
                          ><i
                            class="material-icons {item.type == 0
                              ? 'green'
                              : item.type == 1
                                ? 'gray'
                                : item.type == 2
                                  ? 'yei'
                                  : 'red'}"
                            >{item.type == 0
                              ? "play_circle_filled"
                              : item.type == 1
                                ? "stop"
                                : item.type == 2
                                  ? "pause_circle_filled"
                                  : "report"}</i
                          > <span class="blue">{item.date}: </span><i
                            class="material-icons md-18"
                            style="color:{item.color};">local_offer</i
                          >
                          <strong
                            >{item.type == 0
                              ? "Salió de"
                              : item.type == 1
                                ? "Llegó a"
                                : item.type == 2
                                  ? "En espera"
                                  : "Alerta de Velocidad"}
                            {@html typeof item.name != "undefined"
                              ? "<mark>" + item.name + "</mark>,"
                              : ""}</strong
                          >
                          {item.type == 0
                            ? "viajó por " +
                              item.time +
                              ", " +
                              item.distance +
                              "km"
                            : item.type == 1
                              ? "detenido por " + item.time
                              : item.type == 2
                                ? "por " + item.time
                                : item.vel + "km/hr"}.</td
                        ></tr
                      >
                    {/each}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .badge.travel {
    font-size: 0.75rem;
    margin-bottom: 0.4em;
  }
  .btn {
    vertical-align: initial;
    padding: 1px 19px;
    font-size: 0.78rem;
  }
  .card-b {
    border: 0px;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .br-green {
    background-color: #ffecb3;
  }
  .card-header:first-child {
    border-radius: 0;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>

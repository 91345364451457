export const protocol_templates = [
  {
    description:
      "<h1>Protocolo: Estandar Seguridad</h1><p>Este protocolo gestiona eventos de seguridad mediante llamadas al operador, evaluación de respuestas con clave amago, envío de comandos de seguridad como paro de motor, habilitación de la unidad, y finalización del operativo con cierre de la alerta.</p>",
    def: {
      1: {
        mensaje:
          "Protocolo de localización / robo Iniciado, notifica cuando el operativo termine.",
        actions: ["99a", "1", ""],
        options: ["Operativo terminado", "Alerta en espera", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "warning", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      99: {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      100: {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      name: "Estandar Seguridad",
      init: "-1",
      "-1": {
        mensaje: "Llamando a operador",
        actions: ["0a", "0", "0"],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0a": {
        mensaje: "¿Respondio el operador?",
        actions: ["amago1", "0b", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago1: {
        mensaje:
          "Pregunta por la clave amago y evalúa si es una Respuesta correcta, Respuesta incorrecta",
        actions: ["99", "0b", ""],
        options: ["Respuesta correcta", "Respuesta incorrecta", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "0b": {
        mensaje: "Envío de paro de motor.",
        actions: ["1", "", ""],
        options: ["Enviar paro de motor", "", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99a": {
        mensaje: "Habilitar unidad",
        actions: ["99", "", ""],
        options: ["Habilitar unidad", "", ""],
        function: ["security_off", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
  },
  {
    description:
      "<h1>Protocolo: Notificación Estándar, Sin Acciones</h1><p>Este protocolo maneja notificaciones estándar sin acciones específicas, permitiendo cerrar la alerta con o sin notificación según la decisión del operador.</p>",
    def: {
      100: {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      name: "Notificacion estandar, sin acciones",
      init: "99c",
      "99c": {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
  },
  {
    description:
      "<h1>Protocolo: Control de Velocidad</h1><p>Este protocolo gestiona el evento de exceso de velocidad, iniciando con una llamada al operador, evaluando su respuesta, verificando si disminuye la velocidad, y finalizando con la opción de cerrar la alerta con o sin notificación.</p>",
    def: {
      0: {
        mensaje:
          "Pregunta por la clave amago y evalúa si es una Respuesta correcta, Respuesta incorrecta",
        actions: ["99a", "1", "1"],
        options: ["Respuesta correcta", "Respuesta incorrecta", "No responde"],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      1: {
        mensaje:
          "Protocolo de localización / robo Iniciado, notifica cuando el operativo termine.",
        actions: ["99", "1", ""],
        options: ["Operativo terminado", "Alerta en espera", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "warning", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      99: {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      100: {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      name: "Control de Velocidad",
      init: "-1",
      "-1": {
        mensaje: "Llamando a operador",
        actions: ["0a", "0", "0"],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0a": {
        mensaje: "¿Respondio el operador?",
        actions: ["0", "-1", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99a": {
        mensaje: "¿El operador disminuyó la velocidad?",
        actions: ["99", "-1", ""],
        options: ["Si, presenta velocidad normal.", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
  },
  {
    description:
      "<h1>Protocolo: Fuera de Ruta</h1><p>Este protocolo maneja eventos de unidades fuera de ruta mediante acciones que incluyen llamada al operador, evaluación de respuestas, envío de comandos de seguridad como paro de motor, seguimiento de la ruta, y habilitación de la unidad, finalizando con el cierre de la alerta.</p>",
    def: {
      1: {
        mensaje:
          "Protocolo de localización / robo Iniciado, notifica cuando el operativo termine.",
        actions: ["99a", "1", "1"],
        options: ["Operativo terminado", "Alerta en espera", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "warning", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      2: {
        mensaje: "Envío de paro de motor.",
        actions: ["1", "", ""],
        options: ["Enviar paro de motor", "", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      99: {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      100: {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      name: "Fuera de ruta",
      init: "-1",
      "-1": {
        mensaje: "Llamando a operador",
        actions: ["0a", "0", "0"],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0a": {
        mensaje: "¿Respondio el operador?",
        actions: ["amago1", "0b", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago1: {
        mensaje:
          "Pregunta por la clave amago y evalúa si es una Respuesta correcta, Respuesta incorrecta",
        actions: ["amago1a", "0b", "1"],
        options: ["Respuesta correcta", "Respuesta incorrecta", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago1a: {
        mensaje: "Dar seguimiento a la ruta.",
        actions: ["amago1a", "99", "0b"],
        options: [
          "Pausar alerta",
          "Ya no esta fuera de ruta.",
          "Continua fuera de ruta.",
        ],
        function: ["pause_alert", "", ""],
        btn_color: ["warning", "success", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "0b": {
        mensaje: "Envío de paro de motor.",
        actions: ["0c", "", ""],
        options: ["Enviar paro de motor", "", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "0c": {
        mensaje: "Llamando a operador",
        actions: ["0d", "", ""],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0d": {
        mensaje: "¿Respondio el operador?",
        actions: ["amago2", "1", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago2: {
        mensaje:
          "Pregunta por la clave amago y evalúa si es una Respuesta correcta, Respuesta incorrecta",
        actions: ["amago2a", "0b", "1"],
        options: ["Respuesta correcta", "Respuesta incorrecta", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago2a: {
        mensaje: "Habilitar unidad",
        actions: ["amago2b", "", ""],
        options: ["Habilitar unidad", "", ""],
        function: ["security_off", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago2b: {
        mensaje: "Dar seguimiento a la ruta.",
        actions: ["amago1a", "99", "2"],
        options: [
          "Pausar amago2b",
          "Ya no esta fuera de ruta.",
          "Continua fuera de ruta.",
        ],
        function: ["pause_alert", "", ""],
        btn_color: ["warning", "success", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99a": {
        mensaje: "Habilitar unidad",
        actions: ["99", "", ""],
        options: ["Habilitar unidad", "", ""],
        function: ["security_off", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
  },
  {
    description:
      "<h1>Protocolo: Unidad Detenida</h1><p>Este protocolo gestiona eventos de unidades detenidas mediante llamadas al operador, evaluación de respuestas, envío de comandos de seguridad como paro de motor, seguimiento del estado de la unidad, y habilitación de la unidad, finalizando con la opción de cerrar la alerta.</p>",
    def: {
      1: {
        mensaje:
          "Protocolo de localización / robo Iniciado, notifica cuando el operativo termine.",
        actions: ["99a", "1", ""],
        options: ["Operativo terminado", "Alerta en espera", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "warning", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      99: {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      100: {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      name: "Unidad detenida",
      init: "-1",
      "-1": {
        mensaje: "Llamando a operador",
        actions: ["0a", "0", "0"],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0a": {
        mensaje: "¿Respondio el operador?",
        actions: ["amago1", "0b", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago1: {
        mensaje:
          "Pregunta por la clave amago y evalúa si es una Respuesta correcta, Respuesta incorrecta",
        actions: ["amago1a", "0b", ""],
        options: ["Respuesta correcta", "Respuesta incorrecta", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago1a: {
        mensaje: "Dar seguimiento a la unidad.",
        actions: ["amago1a", "99", "amago2a"],
        options: [
          "Pausar alerta",
          "Unidad con movimiento.",
          "Unidad sin movimiento.",
        ],
        function: ["pause_alert", "", ""],
        btn_color: ["warning", "success", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "0b": {
        mensaje: "Envío de paro de motor.",
        actions: ["0c", "", ""],
        options: ["Enviar paro de motor", "", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "0c": {
        mensaje: "Llamando a operador",
        actions: ["0d", "", ""],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0d": {
        mensaje: "¿Respondio el operador? (Segundo intento)",
        actions: ["amago1", "0e", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "0e": {
        mensaje: "Llamando a operador",
        actions: ["0f", "", ""],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0f": {
        mensaje: "¿Respondio el operador? (Tercer intento)",
        actions: ["amago1", "1", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago2a: {
        mensaje: "Habilitar unidad",
        actions: ["amago2b", "", ""],
        options: ["Habilitar unidad", "", ""],
        function: ["security_off", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago2b: {
        mensaje: "Dar seguimiento a la unidad.",
        actions: ["amago2b", "99", "amago2a"],
        options: [
          "Pausar alerta",
          "Unidad con movimiento.",
          "Unidad sin movimiento.",
        ],
        function: ["pause_alert", "", ""],
        btn_color: ["warning", "success", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99a": {
        mensaje: "Habilitar unidad",
        actions: ["99", "", ""],
        options: ["Habilitar unidad", "", ""],
        function: ["security_off", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
  },
  {
    description:
      "<h1>Protocolo: Unidad Sin Posición</h1><p>Este protocolo gestiona eventos de unidades sin posición mediante llamadas al operador, evaluación de respuestas con clave amago, verificación de zonas de baja cobertura, envío de comandos como reset de unidad, y finalización del operativo con cierre de la alerta.</p>",
    def: {
      1: {
        mensaje:
          "Protocolo de localización / robo Iniciado, notifica cuando el operativo termine.",
        actions: ["99", "1", ""],
        options: ["Operativo terminado", "Alerta en espera", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "warning", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      99: {
        mensaje: "Enviar reset a unidad.",
        actions: ["99c", "100", ""],
        options: ["Enviar reset", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      100: {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      name: "Unidad sin posicion",
      init: "-1",
      "-1": {
        mensaje: "Llamando a operador",
        actions: ["0a", "0", "0"],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0a": {
        mensaje: "¿Respondio el operador?",
        actions: ["amago1", "0b", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      amago1: {
        mensaje:
          "Pregunta por la clave amago y evalúa si es una Respuesta correcta, Respuesta incorrecta",
        actions: ["99", "0b", ""],
        options: ["Respuesta correcta", "Respuesta incorrecta", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "0b": {
        mensaje: "¿Se tiene mapeada como zona de baja cobertura?",
        actions: ["1", "99", ""],
        options: ["No", "Si", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99c": {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
  },
];

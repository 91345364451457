<script>
  export let travel_db;
  export let routes_db;
  export let units_travel_db;
  export let socket_query;
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    travels_list,
    route_list_d,
    geos_class_list,
    geos_full,
    poli,
    poli_list,
  } from "./stores.js";

  let map;
  function initMap() {
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    map.fitBounds(bounds);
  }

  onDestroy(() => {
    socket_query.off("gaia_line");
    window.removeEventListener("message", handleMessage);
  });

  let selected = !localStorage.getItem("selected_report")
    ? 0
    : parseInt(localStorage.getItem("selected_report"));
  $: {
    localStorage.setItem("selected_report", selected);
  }
  let reportes = [
    "Viajes monitoreados",
    "Alertas monitoreadas",
    "Viajes históricos",
    "Actividad de Operadores",
    "Telemetría de flota",
    "Estimador de viaje",
  ];

  function maping(report_id) {
    selected = report_id;
  }
  function data_table_on() {
    const dataTable = mdc.dataTable.MDCDataTable.attachTo(
      document.querySelector(".mdc-data-table")
    );
    const chipSet = mdc.chips.MDCChipSet.attachTo(
      document.querySelector(".mdc-chip-set")
    );
    //document.querySelectorAll('.lista-a').forEach(button => {mdc.ripple.MDCRipple.attachTo(button);button.unbounded = true;})
  }
  function data_table_on2() {
    const dataTable = mdc.dataTable.MDCDataTable.attachTo(
      document.querySelector(".mdc-data-table")
    );
  }
  let travel_report = [
    {
      date: "07/07/20 02:30",
      id: "M-003",
      oper: "Pedro Sanchez",
      status: 0,
      org: "Plaza de Cobro Atlacomulco",
      dest: "Talachera del Bravo",
      alerts: [
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
      ],
    },
    {
      date: "07/07/20 02:30",
      id: "M-003",
      oper: "Pedro Sanchez",
      status: 1,
      org: "Plaza de Cobro Atlacomulco",
      dest: "Talachera del Bravo",
      alerts: [
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
      ],
    },
    {
      date: "07/07/20 02:30",
      id: "M-003",
      oper: "Pedro Sanchez",
      status: 2,
      org: "Plaza de Cobro Atlacomulco",
      dest: "Talachera del Bravo",
      alerts: [],
    },
    {
      date: "07/07/20 02:30",
      id: "M-003",
      oper: "Pedro Sanchez",
      status: 3,
      org: "Plaza de Cobro Atlacomulco",
      dest: "Talachera del Bravo",
      alerts: [
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
      ],
    },
  ];
  let expaned = -1;
  async function load_travels() {
    travel_report = [];
    let travel;
    if ($travels_list != null) {
      let travels_list_ = [...$travels_list];
      for (let x in travels_list_.reverse()) {
        try {
          travel = await travel_db.getItem(travels_list_[x]);
        } catch (e) {
          console.log("Error leyendo travel", e);
        }
        if (travel != null) {
          travel_report.push(JSON.parse(travel));
        }
      }
      travel_report = [...travel_report];
    }
  }
  $: {
    $travels_list;
    load_travels();
  }
  let f_concluido = true,
    f_en_proceso = true,
    f_borrado = false,
    f_parcial = true;
  document.body.addEventListener("MDCChip:selection", (chip) => {
    if (chip.detail.chipId == "f_concluido") {
      f_concluido = chip.detail.selected;
    }
    if (chip.detail.chipId == "f_en_proceso") {
      f_en_proceso = chip.detail.selected;
    }
    if (chip.detail.chipId == "f_borrado") {
      f_borrado = chip.detail.selected;
    }
    if (chip.detail.chipId == "f_parcial") {
      f_parcial = chip.detail.selected;
    }
  });
  let detalle_modal = false;
  let map_routes;
  let alert_list_modal = [];
  let recorrido_ruta = [];
  let route_marks = [];
  let alert_marks = [];
  let ruta_real;
  let m_inicio;
  let m_fin;
  let colors_line = [
    "#2196f3",
    "#e91e63",
    "#03a9f4",
    "#9c27b0",
    "#00bcd4",
    "#673ab7",
    "#009688",
    "#3f51b5",
    "#4caf50",
    "#ff5722",
    "#8bc34a",
    "#ff9800",
    "#cddc39",
    "#ffc107",
    "#607d8b",
    "#ffeb3b",
    "#9e9e9e",
    "#212121",
    "#f44336",
  ];
  let letras = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  async function show_detalle_map() {
    alert_list_modal = [];
    let travel;
    let coordenada_alertas = [];
    let bounds = new google.maps.LatLngBounds();
    try {
      travel = await travel_db.getItem(expaned);
    } catch (e) {
      console.log("Error leyendo travel", e);
    }
    if (travel != null) {
      travel = JSON.parse(travel);
      for (let x in travel.alerts) {
        alert_list_modal.push([
          [
            moment(
              new Date(travel.alerts[x].alerts_crtl.date_on * 1000)
            ).format("DD/MM/YY HH:mm"),
          ],
          [travel.alerts[x].alerts.name],
          {
            lat: travel.alerts[x].pos_data.lat,
            lng: travel.alerts[x].pos_data.lon,
          },
        ]);
        coordenada_alertas.push({
          lat: travel.alerts[x].pos_data.lat,
          lng: travel.alerts[x].pos_data.lon,
        });
        bounds.extend({
          lat: travel.alerts[x].pos_data.lat,
          lng: travel.alerts[x].pos_data.lon,
        });
      }
      alert_list_modal = [...alert_list_modal];
    }

    let coordenada_ruta = [];
    let route;
    if (travel.route != "") {
      try {
        route = await routes_db.getItem(travel.route);
      } catch (e) {
        console.log("Error leyendo routa", e);
      }
      if (route != null) {
        let lat_org = route["pos_start"][0];
        let lng_org = route["pos_start"][1];
        coordenada_ruta.push([]);
        for (let x in route["pos"]) {
          let lat = (lat_org - route["pos"][x][0]) / 10000;
          let lng = (lng_org - route["pos"][x][1]) / 10000;
          coordenada_ruta[coordenada_ruta.length - 1].push({
            lat: lat,
            lng: lng,
          });
          bounds.extend({ lat: lat, lng: lng });
        }
      }
    }
    if (travel.sec_routes.length > 0) {
      for (let x in travel.sec_routes) {
        try {
          route = await routes_db.getItem(travel.sec_routes[x]);
        } catch (e) {
          console.log("Error leyendo routa", e);
        }
        if (route != null) {
          let lat_org = route["pos_start"][0];
          let lng_org = route["pos_start"][1];
          coordenada_ruta.push([]);
          for (let x in route["pos"]) {
            let lat = (lat_org - route["pos"][x][0]) / 10000;
            let lng = (lng_org - route["pos"][x][1]) / 10000;
            coordenada_ruta[coordenada_ruta.length - 1].push({
              lat: lat,
              lng: lng,
            });
            bounds.extend({ lat: lat, lng: lng });
          }
        }
      }
    }

    let coordenada_real = [];
    let last_coordenada_real = 0;
    if (typeof travel.start != "undefined") {
      last_coordenada_real = travel.start;
      let rec_p = JSON.parse(await units_travel_db.getItem(travel.id));
      for (var x in rec_p) {
        if (typeof rec_p[x]["p"] != "undefined") {
          for (var w in rec_p[x]["p"]) {
            if (
              rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60 > travel.start &&
              (typeof travel.end == "undefined" ||
                rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60 <= travel.end)
            ) {
              let lat = (rec_p[x]["ps"][0] - rec_p[x]["p"][w][0]) / 10000;
              let lng = (rec_p[x]["ps"][1] - rec_p[x]["p"][w][1]) / 10000;
              coordenada_real.push({ lat: lat, lng: lng });
              last_coordenada_real = rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60;
              bounds.extend({ lat: lat, lng: lng });
            }
          }
        }
      }
    }
    for (let x in $travels[travel.id].travel) {
      if (typeof $travels[travel.id].travel[x].p != "undefined") {
        for (let y in $travels[travel.id].travel[x].p) {
          if (
            $travels[travel.id].travel[x]["ds"] +
              $travels[travel.id].travel[x]["p"][y][3] * 60 >
              last_coordenada_real &&
            (typeof travel.end == "undefined" ||
              $travels[travel.id].travel[x]["ds"] +
                $travels[travel.id].travel[x]["p"][y][3] * 60 <=
                travel.end)
          ) {
            let lat =
              ($travels[travel.id].travel[x]["ps"][0] -
                $travels[travel.id].travel[x]["p"][y][0]) /
              10000;
            let lng =
              ($travels[travel.id].travel[x]["ps"][1] -
                $travels[travel.id].travel[x]["p"][y][1]) /
              10000;
            coordenada_real.push({ lat: lat, lng: lng });
            bounds.extend({ lat: lat, lng: lng });
          }
        }
      }
    }

    if (coordenada_real.length == 0 && travel.end_pos != null) {
      coordenada_real.push({ lat: travel.end_pos[0], lng: travel.end_pos[1] });
      bounds.extend({ lat: travel.end_pos[0], lng: travel.end_pos[1] });
    }

    function initMap() {
      map_routes = new google.maps.Map(document.getElementById("map_routes"), {
        center: { lat: 24.458489, lng: -102.217231 },
        zoom: 5,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.natural",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
        ],
      });

      // check if bounds are set if not set bounds to default
      if (bounds.isEmpty()) {
        bounds.extend({ lat: 32.534353, lng: -117.123783 });
        bounds.extend({ lat: 21.137926, lng: -86.740844 });
        bounds.extend({ lat: 14.534659, lng: -92.231633 });
      }
      map_routes.fitBounds(bounds);

      let zIndex = 0;

      if (typeof ruta_real != "undefined") {
        ruta_real.setMap(null);
      }
      ruta_real = new google.maps.Polyline({
        path: coordenada_real,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map_routes,
        zIndex: zIndex,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeColor: "#FF0000",
            },
            offset: "100px",
            repeat: "100px",
          },
        ],
      });

      zIndex++;
      for (let x in recorrido_ruta) {
        if (typeof recorrido_ruta[x] != "undefined") {
          recorrido_ruta[x].setMap(null);
        }
      }
      for (let z in route_marks) {
        if (typeof route_marks[z] != "undefined") {
          route_marks[z].setMap(null);
        }
      }
      for (let x in coordenada_ruta) {
        recorrido_ruta[x] = new google.maps.Polyline({
          path: coordenada_ruta[x],
          geodesic: true,
          strokeColor: colors_line[x],
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map_routes,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: colors_line[x],
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        });

        zIndex++;
        route_marks[x] = new google.maps.Marker({
          position: coordenada_ruta[x][0],
          map: map_routes,
          animation: google.maps.Animation.DROP,
          icon: "img/marker" + (x < 26 ? letras[x] : letras[x - 26]) + ".png",
          //label: (x<26?letras[x]:letras[x-26]),
          zIndex: zIndex,
        });
        if (x == coordenada_ruta.length - 1) {
          zIndex++;
          route_marks[parseInt(x) + 1] = new google.maps.Marker({
            position: coordenada_ruta[x][coordenada_ruta[x].length - 1],
            map: map_routes,
            animation: google.maps.Animation.DROP,
            icon:
              "img/marker" +
              (parseInt(x) + 1 < 26
                ? letras[parseInt(x) + 1]
                : letras[parseInt(x) + 1 - 26]) +
              ".png",
            //label: ((parseInt(x)+1)<26?letras[parseInt(x)+1]:letras[(parseInt(x)+1)-26]),
            zIndex: zIndex,
          });
        }
      }

      zIndex++;
      for (let z in alert_marks) {
        if (typeof alert_marks[z] != "undefined") {
          alert_marks[z].setMap(null);
        }
      }
      for (let x in coordenada_alertas) {
        route_marks[x] = new google.maps.Marker({
          position: coordenada_alertas[x],
          map: map_routes,
          animation: google.maps.Animation.DROP,
          icon: "img/markerAlt.png",
          zIndex: zIndex,
        });
      }

      zIndex++;
      if (typeof m_inicio != "undefined") {
        m_inicio.setMap(null);
      }
      m_inicio = new google.maps.Marker({
        position: coordenada_real[0],
        map: map_routes,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-start.png",
        zIndex: zIndex,
      });

      zIndex++;
      if (typeof m_fin != "undefined") {
        m_fin.setMap(null);
      }
      m_fin = new google.maps.Marker({
        position: coordenada_real[coordenada_real.length - 1],
        map: map_routes,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-end.png",
        zIndex: zIndex,
      });
    }
    initMap();
  }
  let selected_alert = -1;
  function maping_alert(i) {
    selected_alert = i;
    map_routes.panTo(alert_list_modal[i][2]);
    map_routes.setZoom(16);
  }
  function reporte_csv() {
    var csv = "";
    var regex = /( |<([^>]+)>)/gi;
    regex = /<[^>]*>?/gm;
    regex = /(<([^>]+)>)/gi;

    let no_word = [
      "verified_user",
      "autorenew",
      "delete",
      "rule",
      "star_rate",
      "more_vert",
      "done_outline",
      "report_problem",
    ];

    var table = document.getElementById(
      selected == 0 ? "travels_table" : selected == 1 ? "alerts_table" : ""
    );
    var rowLength = table.rows.length;

    let j_ini = selected == 0 ? 1 : 0;

    for (let i = 0; i < rowLength; i++) {
      var oCells = table.rows.item(i).cells;
      var cellLength = oCells.length;
      for (var j = j_ini; j < cellLength; j++) {
        var cellVal = oCells.item(j).innerHTML;
        cellVal = cellVal.replace(regex, "");
        for (let z in no_word) {
          cellVal = cellVal.replace(no_word[z], "");
        }
        csv = csv + cellVal + ",";
      }
      csv = csv.slice(0, -1);
      csv = csv + "\n";
    }

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download =
      selected == 0
        ? "Reporte_viajes.csv"
        : selected == 1
          ? "Reporte_alerts.csv"
          : "";
    hiddenElement.click();
  }
  let org_selected,
    dest_selected,
    tunidad_selected,
    ejes_selected,
    ejes2_selected,
    rendimiento = 2;
  let org_selected_,
    dest_selected_,
    tunidad_selected_,
    ejes_selected_,
    ejes2_selected_,
    rendimiento_ = 2;
  let costo_caseta = 0,
    long_km = 0,
    tiempo_min = 0,
    costo_diesel = 0;
  let gaia_reportes;
  let gaia_inicio, gaia_fin;
  let gaia_runing = false;
  let gaia_free = true;
  $: {
    org_selected,
      dest_selected,
      tunidad_selected,
      ejes_selected,
      ejes2_selected;
    if (
      rendimiento != null &&
      org_selected != null &&
      dest_selected != null &&
      tunidad_selected != null &&
      ejes_selected != null &&
      ejes2_selected != null &&
      gaia_free
    ) {
      (costo_caseta = 0), (long_km = 0), (tiempo_min = 0);
      costo_diesel = 0;
      if (typeof gaia_casetas != "undefined") {
        for (let r in gaia_casetas) {
          gaia_casetas[r].setMap(null);
        }
        gaia_casetas = [];
      }
      if (typeof gaia_reportes != "undefined") {
        gaia_reportes.setMap(null);
      }
      if (typeof gaia_line != "undefined") {
        for (let x in gaia_line) {
          gaia_line[x].setMap(null);
        }
        gaia_line = [];
      }
      if (typeof gaia_inicio != "undefined") {
        gaia_inicio.setMap(null);
      }
      if (typeof gaia_fin != "undefined") {
        gaia_fin.setMap(null);
      }
      let bounds = new google.maps.LatLngBounds();
      bounds.extend({ lat: 32.534353, lng: -117.123783 });
      bounds.extend({ lat: 21.137926, lng: -86.740844 });
      bounds.extend({ lat: 14.534659, lng: -92.231633 });
      map.fitBounds(bounds);
      gaia_free = false;
      gaia();
    }
  }

  function time_code(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("m[min]");
    } else {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("H[h] m[min]");
    }
  }

  let gaia_casetas = [];
  let gaia_casetas_l = [];
  function gaia() {
    if (gaia_runing) return;
    gaia_runing = true;
    let org_ = get_org_geo(org_selected);
    let dest_ = get_org_geo(dest_selected);
    if (org_.length > 0 && dest_.length > 0) {
      let v =
        tunidad_selected == "Motocicleta"
          ? 0
          : tunidad_selected == "Automóvil"
            ? 1
            : tunidad_selected == "Autobús"
              ? parseInt(ejes_selected)
              : tunidad_selected == "Camión"
                ? parseInt(ejes_selected) + 3
                : 1;
      let e = parseInt(ejes2_selected);
      socket_query.emit(
        "gaia2",
        org_[0][0],
        org_[0][1],
        dest_[0][0],
        dest_[0][1],
        v,
        e,
        tipo_ruta,
        (data) => {
          if (data != "error") {
            gaia_runing = false;
            console.log(data);

            costo_caseta = 0;
            long_km = 0;
            tiempo_min = 0;
            gaia_casetas_l = [];
            if (typeof gaia_casetas != "undefined") {
              for (let r in gaia_casetas) {
                gaia_casetas[r].setMap(null);
              }
              gaia_casetas = [];
            }
            let geojson = [];
            let bounds = new google.maps.LatLngBounds();
            costo_diesel = data.gas[2].costo;
            for (let x in data.data) {
              costo_caseta =
                costo_caseta +
                data.data[x].costo_caseta +
                data.data[x].eje_excedente;
              long_km = long_km + data.data[x].long_m;
              tiempo_min = tiempo_min + data.data[x].tiempo_min;

              geojson.push({
                lat: data.data[x].geojson[1],
                lng: data.data[x].geojson[0],
              });
              bounds.extend({
                lat: data.data[x].geojson[1],
                lng: data.data[x].geojson[0],
              });

              if (data.data[x].costo_caseta > 0) {
                gaia_casetas.push(
                  new google.maps.Marker({
                    position: {
                      lat: data.data[x].punto_caseta[1],
                      lng: data.data[x].punto_caseta[0],
                    },
                    animation: google.maps.Animation.DROP,
                    map: map,
                    icon: "img/markerAlt.png",
                    zIndex: 0,
                  })
                );
                gaia_casetas_l.push([
                  data.data[x].direccion,
                  data.data[x].costo_caseta + data.data[x].eje_excedente,
                  data.data[x].punto_caseta[1],
                  data.data[x].punto_caseta[0],
                ]);
              }
            }
            long_km = Math.round(long_km / 1000);
            tiempo_min = time_code(tiempo_min);

            let zIndex = 0;
            gaia_reportes = new google.maps.Polyline({
              path: geojson,
              geodesic: true,
              strokeColor: "#FF0000",
              strokeOpacity: 1.0,
              strokeWeight: 2.5,
              map: map,
              zIndex: zIndex,
            });

            zIndex++;
            if (typeof gaia_inicio != "undefined") {
              gaia_inicio.setMap(null);
            }
            gaia_inicio = new google.maps.Marker({
              position: { lat: org_[0][0], lng: org_[0][1] },
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/markerA.png",
              zIndex: zIndex,
            });

            if (typeof gaia_fin != "undefined") {
              gaia_fin.setMap(null);
            }
            gaia_fin = new google.maps.Marker({
              position: { lat: dest_[0][0], lng: dest_[0][1] },
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/markerB.png",
              zIndex: zIndex,
            });

            map.fitBounds(bounds);
            //org_selected=null;dest_selected=null;tunidad_selected=null;ejes_selected=null;ejes2_selected=null;
            if (tipo_ruta == "o") osrm(geojson);
          } else {
            gaia_runing = false;
            new_travel();
            saved_ok = true;
            saved_text = "Ruta no encontrada, intente de nuevo.";
            saved_type = "alert-danger";
            setTimeout(function () {
              saved_ok = false;
            }, 8000);
          }
        }
      );
    } else {
      gaia_runing = false;
      new_travel();
    }
  }

  function get_org_geo(geo) {
    let anti_f = 10;
    let last_join = "";
    let res = [];
    if ($geos_full[geo] != null) {
      geo = $geos_full[geo];
      while (typeof geo.join != "undefined" || anti_f == 0) {
        last_join = geo.join;
        geo = $geos_full[geo.join];
        anti_f--;
      }
      for (let y in geo["pos"]) {
        let lat = geo["pos"][y][0];
        let lng = geo["pos"][y][1];
        res.push([lat, lng]);
      }
    }
    return res;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  function new_travel() {
    org_selected = null;
    dest_selected = null;
    tunidad_selected = null;
    ejes_selected = null;
    ejes2_selected = null;
    org_selected_ = "";
    dest_selected_ = "";
    tunidad_selected_ = "";
    ejes_selected_ = "";
    ejes2_selected_ = "";
    rendimiento_ = "2";
    (costo_caseta = 0), (long_km = 0), (tiempo_min = 0);
    costo_diesel = 0;
    if (typeof gaia_casetas != "undefined") {
      for (let r in gaia_casetas) {
        gaia_casetas[r].setMap(null);
      }
      gaia_casetas = [];
    }
    if (typeof gaia_reportes != "undefined") {
      gaia_reportes.setMap(null);
    }
    if (typeof gaia_line != "undefined") {
      for (let x in gaia_line) {
        gaia_line[x].setMap(null);
      }
      gaia_line = [];
    }
    if (typeof gaia_inicio != "undefined") {
      gaia_inicio.setMap(null);
    }
    if (typeof gaia_fin != "undefined") {
      gaia_fin.setMap(null);
    }
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map.fitBounds(bounds);
    gaia_casetas_l = [];
    gaia_free = true;
  }
  async function osrm(coordenada) {
    // OSRM
    let coordenada_osrm = "";
    let radius_osrm = "";
    for (let t in coordenada) {
      coordenada_osrm =
        coordenada_osrm + coordenada[t].lng + "," + coordenada[t].lat + ";";
    }
    for (let t in coordenada) {
      radius_osrm = radius_osrm + "20%3B";
    }
    let coordenada_first = coordenada[0];
    let coordenada_last = coordenada[coordenada.length - 1];

    coordenada_osrm = coordenada_osrm.slice(0, -1);
    radius_osrm = radius_osrm.slice(0, -3);
    let osrm_r = await fetch(
      "https://route.omnitracs.online/match/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full&radiuses=" +
        radius_osrm
    );
    osrm_r = await osrm_r.json();
    let okok = false;
    if (osrm_r.code == "Ok") {
      if (osrm_r.matchings[0].confidence > 0.1) {
        osrm_r.matchings.push({
          geometry: {
            coordinates: [[coordenada_last.lng, coordenada_last.lat]],
          },
        });
        okok = true;
        coordenada = [];
        for (let t in osrm_r.matchings) {
          let last_lat, last_lon;
          for (let y in osrm_r.matchings[t].geometry.coordinates) {
            coordenada.push({
              lat: osrm_r.matchings[t].geometry.coordinates[y][1],
              lng: osrm_r.matchings[t].geometry.coordinates[y][0],
            });
            last_lat = osrm_r.matchings[t].geometry.coordinates[y][1];
            last_lon = osrm_r.matchings[t].geometry.coordinates[y][0];
          }
          if (typeof osrm_r.matchings[parseInt(t) + 1] != "undefined") {
            let coordenada_osrm_c =
              last_lon +
              "," +
              last_lat +
              ";" +
              osrm_r.matchings[parseInt(t) + 1].geometry.coordinates[0][0] +
              "," +
              osrm_r.matchings[parseInt(t) + 1].geometry.coordinates[0][1];
            let osrm_reco = await fetch(
              "https://route.omnitracs.online/route/v1/driving/" +
                coordenada_osrm_c +
                "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full"
            );
            osrm_reco = await osrm_reco.json();
            if (osrm_reco.code == "Ok") {
              for (let q in osrm_reco.routes[0].geometry.coordinates) {
                coordenada.push({
                  lat: osrm_reco.routes[0].geometry.coordinates[q][1],
                  lng: osrm_reco.routes[0].geometry.coordinates[q][0],
                });
              }
            }
          } else {
            console.log(last_lat, last_lon);
          }
        }
        coordenada.unshift(coordenada_first);
        coordenada.push(coordenada_last);
      }
      console.log("confidence", osrm_r.matchings[0].confidence);
    }
    //---------
    if (okok) {
      if (typeof gaia_reportes != "undefined") {
        gaia_reportes.setMap(null);
      }
      gaia_reportes = new google.maps.Polyline({
        path: coordenada,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: 0,
      });
    }
  }
  let saved_ok = false;
  let saved_type, saved_text;
  let tipo_ruta = "o";
  let gaia_line = [];
  socket_query.on("gaia_line", async function (data) {
    if (typeof gaia_reportes != "undefined") {
      gaia_reportes.setMap(null);
    }
    if (typeof gaia_line != "undefined") {
      for (let x in gaia_line) {
        gaia_line[x].setMap(null);
      }
      gaia_line = [];
    }
    for (let x in data) {
      let coordenada = [];
      for (let y in data[x]) {
        coordenada.push({ lat: data[x][y][1], lng: data[x][y][0] });
      }
      gaia_line.push(
        new google.maps.Polyline({
          path: coordenada,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map,
          zIndex: 0,
        })
      );
    }
  });

  let formulario_modal = false;
  let reporte_selected = [];
  let formats_driver_url =
    "https://vector-trip-report-creator.omnitracs.online";
  let iframe_reports;
  async function iframe_reports_on() {
    iframe_reports = document.getElementById("iframe_reports");
  }
  function handleMessage(event) {
    console.log(
      "Mensaje recibido del iframe:",
      event.data,
      event.origin,
      event.data.type
    );
    switch (event.origin) {
      case formats_driver_url:
        switch (event.data.type) {
          case "command":
            iframe_reports = document.getElementById("iframe_reports");
            iframe_reports.contentWindow.postMessage(
              {
                type: "reports",
                body: reporte_selected.forms
                  ? {
                      ...reporte_selected.forms,
                      oper:
                        typeof $drivers[reporte_selected.oper] != "undefined"
                          ? $drivers[reporte_selected.oper].name
                          : reporte_selected.oper,
                      date_ini: moment(
                        new Date(reporte_selected.date_ini * 1000)
                      ).format("DD/MM/YY HH:mm"),
                      start: moment(
                        new Date(reporte_selected.start * 1000)
                      ).format("DD/MM/YY HH:mm"),
                    }
                  : {
                      after_trip: [],
                      after_trip_optional: [],
                      before_trip: [],
                      before_trip_optional: [],
                      id: "",
                      oper: "",
                      date_ini: "",
                      start: "",
                    },
              },
              formats_driver_url
            );
            console.log("comando enviado", {
              type: "reports",
              body: reporte_selected.forms
                ? {
                    ...reporte_selected.forms,
                    oper:
                      typeof $drivers[reporte_selected.oper] != "undefined"
                        ? $drivers[reporte_selected.oper].name
                        : reporte_selected.oper,
                    date_ini: moment(
                      new Date(reporte_selected.date_ini * 1000)
                    ).format("DD/MM/YY HH:mm"),
                    start: moment(
                      new Date(reporte_selected.start * 1000)
                    ).format("DD/MM/YY HH:mm"),
                  }
                : {
                    after_trip: [],
                    after_trip_optional: [],
                    before_trip: [],
                    before_trip_optional: [],
                    id: "",
                    oper: "",
                    date_ini: "",
                    start: "",
                  },
            });
            console.log(reporte_selected);
            break;
        }
        break;
    }
  }
  window.addEventListener("message", handleMessage);
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

{#if detalle_modal}
  <div
    class="modal fade {detalle_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {detalle_modal
      ? 'block'
      : 'none'}; z-index:1039;"
    use:show_detalle_map
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
      style="min-width: 95%"
    >
      <div class="modal-content" style="min-height: 99%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">directions</i> Detalle de viaje
            <strong />
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => {
              detalle_modal = false;
              selected_alert = -1;
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body h-100 p-0">
          <div class="row h-100 no-gutters">
            <div class="col-9 h-100">
              <div class="h-100" id="map_routes" />
            </div>
            <div class="col-3 h-100">
              <div class="card h-100 bg-light card-b">
                <div class="d-flex flex-column h-100">
                  <div class="card-header bg-dark text-white b-0">
                    <i tooltip="Reportes" class="material-icons md-90"
                      >report_problem</i
                    > Alertas
                  </div>
                  <div class="card-body flex-grow-1 overflow-auto">
                    <div
                      class="list-group"
                      style="height: {alert_list_modal.length == 0
                        ? '100%'
                        : 'auto'};"
                    >
                      {#if alert_list_modal.length == 0}
                        <div
                          style="display: flex; height: 100%; justify-content: center; align-items: center;"
                        >
                          <strong
                            ><i class="material-icons">check_circle</i> No hay alertas
                          </strong>
                        </div>
                      {:else}
                        {#each alert_list_modal as item, i}
                          <a
                            href="/"
                            class:list-group-item-warning={selected_alert == i}
                            class:active_={selected_alert == i}
                            on:click|preventDefault={() => {
                              maping_alert(i);
                            }}
                            class="list-group-item list-group-item-action"
                            ><strong>{item[0]}</strong> | {item[1]}</a
                          >
                        {/each}
                      {/if}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if formulario_modal}
  <div
    class="modal fade {formulario_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {formulario_modal
      ? 'block'
      : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
      style="min-width: 95%"
    >
      <div class="modal-content" style="min-height: 99%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">assignment </i> Formularios
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => {
              formulario_modal = false;
              selected_alert = -1;
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body h-100 p-0" style="overflow-y: unset;">
          <iframe
            title="FormatosViaje"
            src="{formats_driver_url}/?report=true&url_post={window.location
              .origin + window.location.pathname.replace(/\/$/, '')}"
            style="width: 100%; height: 100%; border: none;"
            id="iframe_reports"
            use:iframe_reports_on
          ></iframe>
          <div class="watermark">External tool</div>
        </div>
      </div>
    </div>
  </div>
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div class="card-header bg-dark text-white b-0">
            <i tooltip="Reportes" class="material-icons md-90">widgets</i> Reportes
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each reportes as item, i}
                <a
                  href="/"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={() => {
                    maping(i);
                  }}
                  class="list-group-item list-group-item-action">{item}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          {#if selected != -1}
            <div class="card-header bg-dark text-white b-0">
              <div class="d-flex justify-content-between">
                <div>
                  <i tooltip="Reportes" class="material-icons md-90">notes</i>
                  {reportes[selected]}
                </div>
                <div>
                  <button
                    on:click={reporte_csv}
                    id="csv"
                    type="button"
                    class="btn btn-success mini">Descargar CSV</button
                  >
                </div>
              </div>
            </div>
            <div class="card-body flex-grow-1 overflow-auto">
              {#if selected == 0}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <div
                    style="display:flex;border-bottom: 1px solid #e0e0e0;height: 40px;align-items: center;"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      style="margin-left:16px;margin-right:0px">filter_list</i
                    >
                    <div class="mdc-chip-set mdc-chip-set--filter" role="grid">
                      <div
                        class="mdc-chip mdc-chip--selected"
                        role="row"
                        id="f_concluido"
                      >
                        <div class="mdc-chip__ripple" />
                        <span class="mdc-chip__checkmark">
                          <svg
                            class="mdc-chip__checkmark-svg"
                            viewBox="-2 -3 30 30"
                          >
                            <path
                              class="mdc-chip__checkmark-path"
                              fill="none"
                              stroke="black"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                        </span>
                        <span role="gridcell">
                          <span
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="mdc-chip__primary-action"
                          >
                            <span class="mdc-chip__text">Cerrado</span>
                          </span>
                        </span>
                      </div>
                      <div
                        class="mdc-chip mdc-chip--selected"
                        role="row"
                        id="f_en_proceso"
                      >
                        <div class="mdc-chip__ripple" />
                        <span class="mdc-chip__checkmark">
                          <svg
                            class="mdc-chip__checkmark-svg"
                            viewBox="-2 -3 30 30"
                          >
                            <path
                              class="mdc-chip__checkmark-path"
                              fill="none"
                              stroke="black"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                        </span>
                        <span role="gridcell">
                          <span
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="mdc-chip__primary-action"
                          >
                            <span class="mdc-chip__text">En Proceso</span>
                          </span>
                        </span>
                      </div>
                      <div
                        class="mdc-chip mdc-chip--selected"
                        role="row"
                        id="f_parcial"
                      >
                        <div class="mdc-chip__ripple" />
                        <span class="mdc-chip__checkmark">
                          <svg
                            class="mdc-chip__checkmark-svg"
                            viewBox="-2 -3 30 30"
                          >
                            <path
                              class="mdc-chip__checkmark-path"
                              fill="none"
                              stroke="black"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                        </span>
                        <span role="gridcell">
                          <span
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="mdc-chip__primary-action"
                          >
                            <span class="mdc-chip__text">Parcial</span>
                          </span>
                        </span>
                      </div>
                      <div class="mdc-chip mdc-chip" role="row" id="f_borrado">
                        <div class="mdc-chip__ripple" />
                        <span class="mdc-chip__checkmark">
                          <svg
                            class="mdc-chip__checkmark-svg"
                            viewBox="-2 -3 30 30"
                          >
                            <path
                              class="mdc-chip__checkmark-path"
                              fill="none"
                              stroke="black"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                        </span>
                        <span role="gridcell">
                          <span
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="mdc-chip__primary-action"
                          >
                            <span class="mdc-chip__text">Borrado</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        />
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Id</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha programada</th
                        >
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha de Inicio</th
                        >
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha de fin</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Unidad</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Operador</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Estado</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Origen</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Destino</th
                        >
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Rutas</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Incidencias</th
                        >
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Posicion final</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        />
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each travel_report as item, i}
                        {#if (f_concluido && item.status == 0) || (f_en_proceso && item.status == 1) || (f_borrado && item.status == 2) || (f_parcial && item.status == 3)}
                          <tr class="mdc-data-table__row">
                            <td
                              class="mdc-data-table__cell"
                              style="padding-right:0px"
                            >
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() => {
                                  expaned == item.uuid
                                    ? (expaned = -1)
                                    : (expaned = item.uuid);
                                }}
                                class="mdc-icon-button material-icons lista-a"
                                >{expaned == item.uuid
                                  ? "keyboard_arrow_up"
                                  : "keyboard_arrow_down"}</button
                              >
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="padding-right:0px;display:none;"
                            >
                              {item.uuid}
                            </td>
                            <td class="mdc-data-table__cell">
                              {moment(new Date(item.date_ini * 1000)).format(
                                "DD/MM/YY HH:mm"
                              )}
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="display:none;"
                            >
                              {typeof item.start != "undefined"
                                ? moment(new Date(item.start * 1000)).format(
                                    "DD/MM/YY HH:mm"
                                  )
                                : ""}
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="display:none;"
                            >
                              {typeof item.end != "undefined"
                                ? moment(new Date(item.end * 1000)).format(
                                    "DD/MM/YY HH:mm"
                                  )
                                : ""}
                            </td>
                            <td class="mdc-data-table__cell">
                              <strong
                                >{typeof $nicks_list[item.id] != "undefined"
                                  ? $nicks_list[item.id]
                                  : item.id}</strong
                              >
                            </td>
                            <td class="mdc-data-table__cell"
                              >{typeof $drivers[item.oper] != "undefined"
                                ? $drivers[item.oper].name
                                : item.oper}</td
                            >
                            <td class="mdc-data-table__cell">
                              <i
                                class="material-icons {item.status == 0
                                  ? 'green'
                                  : item.status == 1
                                    ? 'yei'
                                    : item.status == 2
                                      ? 'red'
                                      : item.status == 3
                                        ? 'gray'
                                        : 'orange'} mdc-list-item__graphic"
                                aria-hidden="true"
                                style="margin-right:0px"
                                >{item.status == 0
                                  ? "verified_user"
                                  : item.status == 1
                                    ? "autorenew"
                                    : item.status == 2
                                      ? "delete"
                                      : item.status == 3
                                        ? "rule"
                                        : "star_rate"}</i
                              >
                              {item.status == 0
                                ? "Cerrado"
                                : item.status == 1
                                  ? "En proceso"
                                  : item.status == 2
                                    ? "Borrado"
                                    : item.status == 3
                                      ? "Parcial"
                                      : ""}
                            </td>
                            <td class="mdc-data-table__cell"
                              >{typeof $geos_class[item.org] != "undefined"
                                ? $geos_class[item.org][0]
                                : typeof $poli[item.org] != "undefined"
                                  ? $poli[item.org][1]
                                  : item.org}</td
                            >
                            <td class="mdc-data-table__cell"
                              >{item.sec_dests.length > 0
                                ? typeof $geos_class[
                                    item.sec_dests[item.sec_dests.length - 1]
                                  ] != "undefined"
                                  ? $geos_class[
                                      item.sec_dests[item.sec_dests.length - 1]
                                    ][0]
                                  : typeof $poli[
                                        item.sec_dests[
                                          item.sec_dests.length - 1
                                        ]
                                      ] != "undefined"
                                    ? $poli[
                                        item.sec_dests[
                                          item.sec_dests.length - 1
                                        ]
                                      ][1]
                                    : item.sec_dests[item.sec_dests.length - 1]
                                : typeof $geos_class[item.dest] != "undefined"
                                  ? $geos_class[item.dest][0]
                                  : typeof $poli[item.dest] != "undefined"
                                    ? $poli[item.dest][1]
                                    : item.dest}</td
                            >
                            <td
                              class="mdc-data-table__cell"
                              style="display:none;"
                            >
                              {typeof $route_list_d[item.route] != "undefined"
                                ? $route_list_d[item.route]
                                : "No asignada"}
                              {#each item.sec_routes as item, i}
                                | {typeof $route_list_d[item] != "undefined"
                                  ? $route_list_d[item]
                                  : "No asignada"}
                              {/each}
                            </td>
                            <td class="mdc-data-table__cell">
                              {#if item.alerts.length > 0}
                                <i
                                  tooltip="{item.alerts.length} incidencias"
                                  class="material-icons red mdc-list-item__graphic"
                                  aria-hidden="true"
                                  style="margin-right:0px">report_problem</i
                                >
                                <strong>{item.alerts.length}</strong>
                              {:else}
                                <i
                                  tooltip="Sin incidencias"
                                  class="material-icons green mdc-list-item__graphic"
                                  aria-hidden="true"
                                  style="margin-right:0px">done_outline</i
                                >
                              {/if}
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="display:none;"
                            >
                              {typeof item.end_pos != "undefined"
                                ? item.end_pos[0] + " " + item.end_pos[1]
                                : ""}
                            </td>
                            <td class="mdc-data-table__cell">
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() => {}}
                                class="mdc-icon-button material-icons"
                                >more_vert</button
                              >
                            </td>
                          </tr>
                          {#if expaned == item.uuid}
                            <tr
                              class="mdc-data-table__row"
                              style="vertical-align: top;height: unset;background-color:rgba(0, 0, 0, 0.04)"
                            >
                              <td
                                class="mdc-data-table__cell"
                                colspan="9"
                                style="padding:16px"
                              >
                                {#if typeof item.reason != "undefined"}
                                  <div
                                    class="d-flex justify-content-between align-items-center"
                                  >
                                    {#if typeof item.start != "undefined"}
                                      <div>
                                        <strong>Inicio real: </strong>{moment(
                                          new Date(item.start * 1000)
                                        ).format("DD/MM/YY HH:mm")}
                                      </div>
                                    {/if}
                                    {#if typeof item.end != "undefined"}
                                      <div>
                                        <strong>Final real: </strong>{moment(
                                          new Date(item.end * 1000)
                                        ).format("DD/MM/YY HH:mm")}
                                      </div>
                                    {/if}
                                    <div>
                                      <strong
                                        >Tipo de cierre:
                                      </strong>{item.reason == 0
                                        ? "Unidad terminó viaje"
                                        : item.reason == 1
                                          ? "Unidad canceló viaje"
                                          : item.reason == 2
                                            ? "Unidad no inició viaje"
                                            : "?"}
                                    </div>
                                    <div>
                                      <button
                                        class="mdc-button mdc-button--outlined"
                                        on:click={() => {
                                          detalle_modal = true;
                                        }}
                                      >
                                        <div class="mdc-button__ripple" />
                                        <i
                                          class="material-icons mdc-button__icon"
                                          aria-hidden="true">map</i
                                        >
                                        <span class="mdc-button__label"
                                          >Detalle</span
                                        >
                                      </button>
                                      {#if item.forms}
                                        <button
                                          class="mdc-button mdc-button--outlined"
                                          on:click={() => {
                                            reporte_selected = item;
                                            formulario_modal = true;
                                          }}
                                        >
                                          <div class="mdc-button__ripple" />
                                          <i
                                            class="material-icons mdc-button__icon"
                                            aria-hidden="true"
                                          >
                                            assignment
                                          </i>
                                          <span class="mdc-button__label"
                                            >Formularios</span
                                          >
                                        </button>
                                      {/if}
                                    </div>
                                  </div>
                                {/if}
                                {#if item.alerts.length > 0}
                                  <div
                                    class="mdc-data-table"
                                    style="display:flex;margin-top:10px"
                                  >
                                    <table class="mdc-data-table__table">
                                      <thead>
                                        <tr class="mdc-data-table__header-row">
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Fecha</th
                                          >
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Nombre</th
                                          >
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Fecha de cierre</th
                                          >
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Posición</th
                                          >
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Comentario</th
                                          >
                                        </tr>
                                      </thead>
                                      <tbody class="mdc-data-table__content">
                                        {#each item.alerts as item, i}
                                          <tr class="mdc-data-table__row">
                                            <td class="mdc-data-table__cell">
                                              {moment(
                                                new Date(
                                                  item.alerts_crtl.date_on *
                                                    1000
                                                )
                                              ).format("DD/MM/YY HH:mm")}
                                            </td>
                                            <td class="mdc-data-table__cell">
                                              {item.alerts.name}
                                            </td>
                                            <td class="mdc-data-table__cell">
                                              {typeof item.date_close !=
                                              "undefined"
                                                ? moment(
                                                    new Date(
                                                      item.date_close * 1000
                                                    )
                                                  ).format("DD/MM/YY HH:mm")
                                                : ""}
                                            </td>
                                            <td class="mdc-data-table__cell">
                                              {item.pos_data.lat},{item.pos_data
                                                .lon}
                                            </td>
                                            <td class="mdc-data-table__cell">
                                              {typeof item.comment !=
                                              "undefined"
                                                ? item.comment
                                                : ""}
                                            </td>
                                          </tr>
                                        {/each}
                                      </tbody>
                                    </table>
                                  </div>
                                {/if}
                                {#if typeof item.comment != "undefined"}
                                  <div style="display:flex;margin-top:10px">
                                    <strong
                                      >Comentario de cierre:
                                    </strong>{item.comment}
                                  </div>
                                {/if}
                              </td>
                            </tr>
                          {/if}
                        {/if}
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 1}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <div
                    style="display:flex;border-bottom: 1px solid #e0e0e0;height: 40px;align-items: center;"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      style="margin-left:16px;margin-right:0px">filter_list</i
                    >
                    <div
                      class="mdc-chip-set mdc-chip-set--filter"
                      role="grid"
                    />
                  </div>
                  <table class="mdc-data-table__table" id="alerts_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Id</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Unidad</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Operador</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Nombre</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha de cierre</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Posición</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Comentario</th
                        >
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each travel_report as item_a, i}
                        {#if item_a.alerts.length > 0}
                          {#each item_a.alerts as item, i}
                            <tr class="mdc-data-table__row">
                              <td
                                class="mdc-data-table__cell"
                                style="padding-right:0px;display:none;"
                              >
                                {item_a.uuid}
                              </td>
                              <td class="mdc-data-table__cell">
                                {moment(
                                  new Date(item.alerts_crtl.date_on * 1000)
                                ).format("DD/MM/YY HH:mm")}
                              </td>
                              <td class="mdc-data-table__cell">
                                <strong
                                  >{typeof $nicks_list[item_a.id] != "undefined"
                                    ? $nicks_list[item_a.id]
                                    : item_a.id}</strong
                                >
                              </td>
                              <td class="mdc-data-table__cell">
                                {typeof $drivers[item_a.oper] != "undefined"
                                  ? $drivers[item_a.oper].name
                                  : item_a.oper}
                              </td>
                              <td class="mdc-data-table__cell">
                                {item.alerts.name}
                              </td>
                              <td class="mdc-data-table__cell">
                                {typeof item.date_close != "undefined"
                                  ? moment(
                                      new Date(item.date_close * 1000)
                                    ).format("DD/MM/YY HH:mm")
                                  : ""}
                              </td>
                              <td class="mdc-data-table__cell">
                                {item.pos_data.lat}
                                {item.pos_data.lon}
                              </td>
                              <td class="mdc-data-table__cell">
                                {typeof item.comment != "undefined"
                                  ? item.comment
                                  : ""}
                              </td>
                            </tr>
                          {/each}
                        {/if}
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 5}
                <div class="col-md h-100 w-100" use:initMap style="padding:0">
                  <div class="row h-60 no-gutters w-100">
                    <div class="col-md-12 h-100 w-100">
                      <div class="h-100 w-100" id="map" />
                    </div>
                  </div>
                  {#if !gaia_runing}
                    <div class="row h-40 no-gutters w-100">
                      <div
                        class="col-md-4 h-100 border"
                        style="border-color:#9e9d99"
                      >
                        <table class="table table-striped table-hover table-sm">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">
                                <i
                                  tooltip="Viaje"
                                  class="material-icons"
                                  style="font-size:13px">calendar_today</i
                                >
                                Estimar viajes
                                {#if gaia_free}
                                  <select
                                    name="tipo_ruta"
                                    id="tipo_ruta"
                                    bind:value={tipo_ruta}
                                  >
                                    <option value="o" selected>Cuota</option>
                                    <option value="l">Preferente Libre</option>
                                  </select>
                                {/if}
                              </th>
                              {#if !gaia_free}
                                <th scope="col" style="padding:0.1rem">
                                  <button
                                    on:click={new_travel}
                                    id="edit_prog"
                                    type="button"
                                    class="btn btn-success mini"
                                    >Nuevo viaje</button
                                  >
                                </th>
                              {/if}
                            </tr>
                          </thead>
                          <div class="d-flex flex-column flex-grow-1 p-2">
                            <div>
                              <i
                                tooltip="Origen"
                                class="material-icons md-90"
                                style="color:#26a69a">play_circle_outline</i
                              >
                              <input
                                list="org_list"
                                placeholder="Origen"
                                size="30"
                                on:change={(event) => {
                                  org_selected = "";
                                  for (let x in $geos_class_list) {
                                    if (
                                      $geos_class_list[x][1] ==
                                      event.target.value
                                    ) {
                                      org_selected = $geos_class_list[x][0];
                                    }
                                  }
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={org_selected_}
                              />
                              <datalist id="org_list">
                                {#each $geos_class_list as item, i}
                                  <option value={item[1]} />{/each}
                              </datalist>
                            </div>
                            <div>
                              <i
                                tooltip="Destino"
                                class="material-icons md-90"
                                style="color:#26a69a">stop</i
                              >
                              <input
                                list="dest_list"
                                placeholder="Destino"
                                size="30"
                                on:change={(event) => {
                                  dest_selected = "";
                                  for (let x in $geos_class_list) {
                                    if (
                                      $geos_class_list[x][1] ==
                                      event.target.value
                                    ) {
                                      dest_selected = $geos_class_list[x][0];
                                    }
                                  }
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={dest_selected_}
                              />
                              <datalist id="dest_list">
                                {#each $geos_class_list as item, i}
                                  <option value={item[1]} />{/each}
                              </datalist>
                            </div>
                            <div>
                              <i
                                tooltip="Tipo"
                                class="material-icons md-90"
                                style="color:#26a69a">commute</i
                              >
                              <input
                                list="type_list"
                                placeholder="Tipo de unidad"
                                size="30"
                                on:change={(event) => {
                                  tunidad_selected = event.target.value;
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={tunidad_selected_}
                              />
                              <datalist id="type_list">
                                <option value="Motocicleta" /><option
                                  value="Automóvil"
                                /><option value="Autobús" /><option
                                  value="Camión"
                                /></datalist
                              >
                            </div>
                            <div>
                              <i
                                tooltip="Ejes"
                                class="material-icons md-90"
                                style="color:#26a69a">timeline</i
                              >
                              <input
                                list="ejes_list"
                                placeholder="Numero de ejes"
                                size="30"
                                on:change={(event) => {
                                  ejes_selected = event.target.value;
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={ejes_selected_}
                              />
                              <datalist id="ejes_list">
                                <option value="2" /><option value="3" /><option
                                  value="4"
                                /><option value="5" /><option
                                  value="6"
                                /><option value="7" /><option
                                  value="8"
                                /><option value="9" /></datalist
                              >
                            </div>
                            <div>
                              <i
                                tooltip="Ejes excedentes"
                                class="material-icons md-90"
                                style="color:#26a69a">timeline</i
                              >
                              <input
                                list="ejes2_list"
                                placeholder="Numero de ejes excedentes"
                                size="30"
                                on:change={(event) => {
                                  ejes2_selected = event.target.value;
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={ejes2_selected_}
                              />
                              <datalist id="ejes2_list">
                                <option value="0" /><option value="1" /><option
                                  value="2"
                                /><option value="3" /><option
                                  value="4"
                                /></datalist
                              >
                            </div>
                            <div>
                              <i
                                tooltip="Redimiento"
                                class="material-icons md-90"
                                style="color:#26a69a">eco</i
                              >
                              <input
                                type="text"
                                placeholder="Redimiento por litro"
                                size="30"
                                on:change={(event) => {
                                  rendimiento = event.target.value;
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={rendimiento_}
                              />
                            </div>
                          </div>
                        </table>
                      </div>
                      <div
                        class="col-md-4 h-100 border"
                        style="border-color:#9e9d99"
                      >
                        <div
                          class="mdc-data-table"
                          style="display:flex"
                          use:data_table_on2
                        >
                          <table
                            class="mdc-data-table__table"
                            id="casetas_table"
                          >
                            <thead>
                              <tr
                                class="mdc-data-table__header-row"
                                style="height:1px"
                              >
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col">Datos de viaje</th
                                >
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col">Valor</th
                                >
                              </tr>
                            </thead>
                            <tbody class="mdc-data-table__content">
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Costo de casetas
                                </td>
                                <td class="mdc-data-table__cell">
                                  <strong
                                    >{formatter.format(costo_caseta)}</strong
                                  >
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Costo de combustible
                                </td>
                                <td class="mdc-data-table__cell">
                                  <strong
                                    >{formatter.format(
                                      (long_km / parseInt(rendimiento)) *
                                        costo_diesel
                                    )}</strong
                                  >
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Costo de total
                                </td>
                                <td
                                  class="mdc-data-table__cell"
                                  style="color:red"
                                >
                                  <strong
                                    >{formatter.format(
                                      costo_caseta +
                                        (long_km / parseInt(rendimiento)) *
                                          costo_diesel
                                    )}</strong
                                  >
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Distancia
                                </td>
                                <td class="mdc-data-table__cell">
                                  <strong>{long_km} KM</strong>
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell"> Tiempo </td>
                                <td class="mdc-data-table__cell">
                                  <strong>{tiempo_min}</strong>
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Costo diesel
                                </td>
                                <td class="mdc-data-table__cell">
                                  <!--<strong>{formatter.format(costo_diesel)}</strong>-->
                                  <input
                                    type="text"
                                    bind:value={costo_diesel}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="col-md-4 h-100 overflow-auto border"
                        style="border-color:#9e9d99"
                      >
                        <div
                          class="mdc-data-table"
                          style="display:flex"
                          use:data_table_on2
                        >
                          <table
                            class="mdc-data-table__table"
                            id="casetas_table"
                          >
                            <thead>
                              <tr
                                class="mdc-data-table__header-row"
                                style="height:1px"
                              >
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col">Nombre de caseta</th
                                >
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col">Costo</th
                                >
                              </tr>
                            </thead>
                            <tbody class="mdc-data-table__content">
                              {#each gaia_casetas_l as item, i}
                                <tr
                                  class="mdc-data-table__row"
                                  style="height:1px"
                                >
                                  <td class="mdc-data-table__cell">
                                    <a
                                      href="/"
                                      on:click|preventDefault={() => {
                                        map.setZoom(13);
                                        map.panTo({
                                          lat: item[2],
                                          lng: item[3],
                                        });
                                      }}
                                    >
                                      {item[0]}
                                    </a>
                                  </td>
                                  <td class="mdc-data-table__cell">
                                    <strong>{formatter.format(item[1])}</strong>
                                  </td>
                                </tr>
                              {/each}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  {:else}
                    <div
                      class="h-40 d-flex flex-column justify-content-center flex-grow-1 align-items-center border"
                      style="border-color:#9e9d99"
                    >
                      <div
                        class="spinner-border text-danger"
                        style="width: 6rem; height: 6rem;"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  {/if}
                </div>
              {/if}
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .h-30 {
    height: 30%;
  }
  .h-70 {
    height: 70%;
  }
  .h-40 {
    height: 40%;
  }
  .h-60 {
    height: 60%;
  }
  .h-49 {
    height: 49%;
  }
  .h-2 {
    height: 2%;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  .mdc-data-table__row {
    height: 45px;
  }
  .mdc-data-table__header-row {
    height: 45px;
  }
  .mdc-chip__text:focus {
    outline: unset;
  }
  .mdc-chip__primary-action:focus {
    outline: unset;
  }
  .mdc-icon-button {
    width: 24px;
    height: 24px;
    padding: 0px;
  }
  .mdc-data-table__table {
    white-space: normal;
  }

  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }

  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  .watermark {
    position: fixed;
    bottom: 40px;
    right: 80px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3); /* Color negro con 30% de opacidad */
    z-index: 1000;
    pointer-events: none; /* Para que no interfiera con otros elementos */
  }
</style>

<script>
  export let units_travel_db;
  export let login_db;
  export let socket_query;
  export let routes_db;
  export let chats_db;
  export let user_level;
  export let user_alerts;
  export let vins_db;
  export let ecm_db;
  export let dtc_db;
  export let travel_db;
  export let monitor_worker;
  export let unit_worker;
  export let geos_worker;
  export let travel_worker;
  export let trips_worker;
  export let routes_worker;
  export let protocol_db;
  import Inicio from "./Inicio.svelte";
  import Recorridos from "./Recorridos.svelte";
  import Geocercas from "./Geocercas.svelte";
  import Viajes from "./Viajes.svelte";
  import Rutas from "./Rutas.svelte";
  import Monitor from "./Monitor.svelte";
  import Alertas from "./Alertas.svelte";
  import Panel from "./Panel.svelte";
  import Mensajes from "./Mensajes.svelte";
  import Choferes from "./Choferes.svelte";
  import Estado from "./Estado.svelte";
  import Telemetria from "./Telemetria.svelte";
  import Dtc from "./Dtc.svelte";
  import Trayectos from "./Trayectos.svelte";
  import Reportes from "./Reportes.svelte";
  import Configuraciones from "./Configuraciones.svelte";
  import Torre from "./Torre.svelte";
  import {
    menu,
    nicks_list,
    recognition,
    portal_version,
    cc_monitor,
    speaking_list,
    drivers,
    last_message_date,
  } from "./stores.js";
  import { onMount, afterUpdate } from "svelte";

  //window.innerWidth<1920||window.innerHeight<900
  var menu_b; //menu_b.click();
  Notification.requestPermission();
  let alerta_off = false;
  socket_query.on("disconnect", (reason) => {
    alerta_off = true;
    console.log("alerta_off", reason);
    //new Notification("Alerta, portal desconectado.");
    //var text_to = new SpeechSynthesisUtterance("Alerta, portal desconectado.");
    //window.speechSynthesis.speak(text_to);
  });
  socket_query.on("hello", (reason) => {
    alerta_off = false;
  }); //navigator.connection.downlink

  var menu_contex;
  onMount(() => {
    const topAppBarElement = document.querySelector(".mdc-top-app-bar");
    const topAppBar = new mdc.topAppBar.MDCTopAppBar(topAppBarElement);
    const drawer = mdc.drawer.MDCDrawer.attachTo(
      document.querySelector(".mdc-drawer")
    );
    const listEl = document.querySelector(".mdc-drawer .mdc-list");
    const logo1 = document.querySelector(".mdc-top-app-bar__navigation-icon");
    topAppBar.listen("MDCTopAppBar:nav", () => {
      drawer.open = true;
    });
    listEl.addEventListener("click", (event) => {
      drawer.open = false;
    });
    document.body.addEventListener("MDCDrawer:closed", () => {
      logo1.blur();
    });
    menu_contex = mdc.menu.MDCMenu.attachTo(
      document.querySelector(".mdc-menu")
    );
    menu_contex.setAnchorElement(document.querySelector("#menu-button"));
    menu_contex.setIsHoisted(true);
    document.querySelector("#menu-button").addEventListener("click", () => {
      menu_contex.open = !menu_contex.open;
    });
  });

  let recognition_status = false;
  let spkeaking = false;
  let vectordetected = false;
  let speech_text = "",
    speech_alert = false;
  var recognition_ = new webkitSpeechRecognition();
  recognition_.continuous = true;
  recognition_.lang = "es-ES";
  recognition_.interimResults = true;
  recognition_.maxAlternatives = 1;
  recognition_.onstart = function () {
    console.log("Escuchando...");
  };
  recognition_.onend = function (event) {
    if (recognition_status && !spkeaking) {
      recognition_.start();
    } else {
      console.log("Esucha desactivada");
    }
    if (!recognition_status) {
      setBolita("disappear");
    }
  };
  recognition_.onresult = async function (event) {
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        console.log(
          "event:",
          event.results[i][0].transcript,
          event.results[i][0].confidence
        );
        let command = event.results[i][0].transcript.toLowerCase();
        if (command.includes("vector")) {
          setBolita("appear");
          vectordetected = true;
          speakresponse("Hola, ¿en qué puedo ayudarte?");
        } else if (vectordetected) {
          console.log("Sending transcript to server...", command);
          sendtranscript(command);
          vectordetected = false;
        }
      }
    }
    /*
    console.log(event.results[0][0].transcript);
    speech_text = event.results[0][0].transcript;
    speech_alert = true;
    setTimeout(function () {
      speech_alert = false;
    }, 2000);
    if (event.results[0][0].transcript == "inicio") {
      menu.set(1);
      login_db.setItem("menu_base", 1);
    } else if (event.results[0][0].transcript == "recorridos") {
      menu.set(2);
      login_db.setItem("menu_base", 2);
    } else if (event.results[0][0].transcript == "geocerca") {
      menu.set(3);
      login_db.setItem("menu_base", 3);
    } else if (event.results[0][0].transcript == "viajes") {
      menu.set(4);
      login_db.setItem("menu_base", 4);
    } else if (event.results[0][0].transcript == "rutas") {
      menu.set(5);
      login_db.setItem("menu_base", 5);
    } else if (event.results[0][0].transcript == "trayectos") {
      menu.set(13);
      login_db.setItem("menu_base", 13);
    } else if (event.results[0][0].transcript == "reportes") {
      menu.set(14);
      login_db.setItem("menu_base", 14);
    } else if (event.results[0][0].transcript == "monitor") {
      menu.set(6);
      login_db.setItem("menu_base", 6);
    } else if (event.results[0][0].transcript == "torre") {
      menu.set(16);
      login_db.setItem("menu_base", 16);
    } else if (event.results[0][0].transcript == "choferes") {
      menu.set(7);
      login_db.setItem("menu_base", 7);
    } else if (event.results[0][0].transcript == "mensajes") {
      menu.set(8);
      login_db.setItem("menu_base", 8);
    } else if (event.results[0][0].transcript == "indicadores") {
      menu.set(9);
      login_db.setItem("menu_base", 9);
    } else if (event.results[0][0].transcript == "telemetría") {
      menu.set(11);
      login_db.setItem("menu_base", 11);
    } else if (event.results[0][0].transcript == "dtc") {
      menu.set(12);
      login_db.setItem("menu_base", 12);
    } else {
      recognition.set(event.results[0][0].transcript);
    }
    */
    setTimeout(function () {
      recognition.set("");
    }, 1000);
  };
  async function log_out_real() {
    await login_db.setItem("menu_base", 0);
    await login_db.setItem("login_", "");
    unit_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    geos_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    travel_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    monitor_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    trips_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    routes_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    socket_query.emit("hello", "" + ":" + sha1(""));
    location.reload();
  }

  let alerta_mensaje = false;
  let portal_notification = "";
  socket_query.on("portal_notification", (message) => {
    if (message != null && message != "") {
      portal_notification = message;
      alerta_mensaje = true;
    } else {
      alerta_mensaje = false;
    }
  });

  // voices
  var voices = window.speechSynthesis.getVoices();
  function populatevoicelist() {
    voices = window.speechSynthesis.getVoices();
  }
  speechSynthesis.onvoiceschanged = populatevoicelist;
  function sendtranscript(transcript) {
    transcript = transcript.replace("vector", "").trim().toLowerCase();
    if (transcript === "") {
      return;
    }
    transcript +=
      ", solo responde una frase, con acentos pero sin caracteres especiales";
    setBolita("thinking");
    fetch("https://gpt-gateway.omnitracs.online/vector", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: transcript }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.type == "text") {
          if (data.message) {
            speakresponse(data.message);
          }
        }
        if (data.type == "tools") {
          for (let i in data.message) {
            switch (data.message[i].name) {
              case "select_unit":
                if (menu != 6) {
                  menu.set(6);
                }
                speakresponse(
                  "Este es el estado de la unidad " +
                    data.message[i].arguments.name.replace("unidad", "").trim()
                );
                cc_monitor.set([
                  {
                    type: "select_unit",
                    name: data.message[i].arguments.name
                      .replace("unidad", "")
                      .trim(),
                  },
                ]);
                break;
            }
          }
        }
      })
      .catch((error) => console.error("Error:", error));
  }
  let speaking_queue = [];
  function speakresponse(text) {
    speaking_queue.push(text);
    if (!spkeaking) {
      speak_(speaking_queue[0]);
    }
  }
  function speak_(text) {
    spkeaking = true;
    setBolita("speaking");
    recognition_.stop();
    const msg = new SpeechSynthesisUtterance();
    voices = window.speechSynthesis.getVoices();
    msg.text = text;
    msg.voice =
      voices.find((voice) => voice.name === "Google español") || voices[0];
    speechSynthesis.speak(msg);
    msg.onend = () => {
      recognition_.start();
      setBolita("appear");
      if (!vectordetected) {
        setBolita("disappear");
      }
      spkeaking = false;
      speaking_queue.shift();
      if (speaking_queue.length > 0) {
        speak_(speaking_queue[0]);
      }
    };
  }
  $: {
    $speaking_list;
    if ($speaking_list.length > 0) {
      console.log("speaking_list", $speaking_list);
      speaking_queue.push($speaking_list[0]);
      speaking_list.set([]);
      if (!spkeaking) {
        speak_(speaking_queue[0]);
      }
    }
  }
  let bolita = "";
  function setBolita(state) {
    bolita = state;
  }
  // Chats
  socket_query.on("chats_", chat_in);
  let notificaciones = [];
  let last_not_id = 0;
  let messages_not_read = false;
  $: {
    if (Object.keys($last_message_date).length > 0) {
      let last_message_read = localStorage.getItem("last_read")
        ? JSON.parse(localStorage.getItem("last_read"))
        : {};
      let change = false;
      for (let i in $last_message_date) {
        if ($last_message_date[i] > last_message_read[i]) {
          messages_not_read = true;
          change = true;
          break;
        }
      }
      if (!change) messages_not_read = false;
    }
  }
  function chat_in(data, data2) {
    data2 = JSON.parse(data2);
    if (data2.type == 0) {
      notificaciones.push({
        driver: $drivers ? $drivers[data].name : "",
        message:
          data2.message.search("data:image") != -1
            ? "Envio una imagen"
            : data2.message.search("data:audio") != -1
              ? "Clip de Audio"
              : data2.message,
        id: last_not_id,
      });
      notificaciones = [...notificaciones];
      last_not_id++;
    }
  }
  function notification_control(e) {
    var toast = new bootstrap.Toast(e);
    toast.show();
    var id_n = e.getAttribute("id_n");
    setTimeout(function () {
      notificaciones = notificaciones.filter(
        (item) => item.id !== parseInt(id_n)
      );
    }, 5000);
  }
</script>

<div
  class="bolita {bolita === 'appear' ? 'appear' : ''} {bolita === 'disappear'
    ? 'disappear'
    : ''} {bolita === 'thinking' ? 'appear thinking' : ''} {bolita ===
  'speaking'
    ? 'appear speaking'
    : ''}"
  id="bolita"
></div>
<header class="mdc-top-app-bar mdc-top-app-bar--fixed mdc-top-app-bar--dense">
  <div class="mdc-top-app-bar__row">
    <section
      class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
    >
      <button
        class="material-icons mdc-top-app-bar__navigation-icon mdc-icon-button"
        aria-label="Open navigation menu">menu</button
      >
      <span class="mdc-top-app-bar__title">
        <img
          src="img/logo_.png"
          alt="logo"
          height="24"
          on:click={() => {
            if (!recognition_status) {
              try {
                var text_to = new SpeechSynthesisUtterance();
                voices = window.speechSynthesis.getVoices();
                text_to.text =
                  "Comandos de voz activados, diga Véctor para activar el asistente";
                text_to.voice =
                  voices.find((voice) => voice.name === "Google español") ||
                  voices[0];
                speechSynthesis.speak(text_to);
                setBolita("speaking");
                text_to.onend = function () {
                  recognition_status = true;
                  recognition_.start();
                  setBolita("appear");
                  setTimeout(function () {
                    setBolita("disappear");
                  }, 500);
                };
              } catch (e) {}
            } else {
              recognition_status = false;
              recognition_.stop();
              var text_to = new SpeechSynthesisUtterance();
              voices = window.speechSynthesis.getVoices();
              text_to.text = "Comandos de voz desactivados";
              text_to.voice =
                voices.find((voice) => voice.name === "Google español") ||
                voices[0];
              speechSynthesis.speak(text_to);
            }
          }}
        />
        {#if spkeaking}
          <i class="material-icons" style="font-size: 1.5rem; color:red;"
            >mic_off</i
          >
        {:else if recognition_status && !vectordetected}
          <i class="material-icons" style="font-size: 1.5rem; color:white;"
            >mic</i
          >
        {:else if recognition_status && vectordetected}
          <i class="material-icons" style="font-size: 1.5rem; color:lawngreen;"
            >mic</i
          >
        {/if}
        <!--Icon for messeges not read-->
        {#if messages_not_read}
          <i
            on:click={() => {
              menu.set(8);
              login_db.setItem("menu_base", 8);
            }}
            class="material-icons"
            style="font-size: 1.5rem; color:red; cursor:pointer;"
            >notifications</i
          >
        {/if}
      </span>
    </section>
    <section
      class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
      role="toolbar"
    >
      <button
        id="menu-button"
        class="material-icons mdc-top-app-bar__action-item mdc-icon-button"
        aria-label="Options">more_vert</button
      >
    </section>
  </div>
</header>

<aside class="mdc-drawer mdc-drawer--modal">
  <div class="mdc-drawer__header" style="min-height:0px">
    <h6 class="mdc-drawer__title" style="font-size: 12px">
      <img
        src="img/logo2.png"
        alt="logo"
        height="35"
        style="padding-top:10px;box-sizing:content-box;vertical-align:baseline"
      />
      v{$portal_version}
    </h6>
  </div>
  <div class="mdc-drawer__content">
    <nav class="mdc-list">
      <hr class="mdc-list-divider" />
      <a
        class="mdc-list-item {$menu == 1 ? 'mdc-list-item--activated' : ''}"
        href="/"
        aria-current="page"
        tabindex="0"
        on:click|preventDefault={() => {
          menu.set(1);
          login_db.setItem("menu_base", 1);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >home</i
        >
        <span class="mdc-list-item__text">Inicio</span>
      </a>
      <a
        class="mdc-list-item {$menu == 16 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(16);
          login_db.setItem("menu_base", 16);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >group_work</i
        >
        <span class="mdc-list-item__text">Torre de control</span>
      </a>
      <a
        class="mdc-list-item {$menu == 2 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(2);
          login_db.setItem("menu_base", 2);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >directions</i
        >
        <span class="mdc-list-item__text">Recorridos</span>
      </a>
      <a
        class="mdc-list-item {$menu == 3 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(3);
          login_db.setItem("menu_base", 3);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >layers</i
        >
        <span class="mdc-list-item__text">Geocercas</span>
      </a>
      <a
        class="mdc-list-item {$menu == 4 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(4);
          login_db.setItem("menu_base", 4);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >map</i
        >
        <span class="mdc-list-item__text">Viajes</span>
      </a>
      <a
        class="mdc-list-item {$menu == 5 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(5);
          login_db.setItem("menu_base", 5);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >near_me</i
        >
        <span class="mdc-list-item__text">Rutas</span>
      </a>
      <a
        class="mdc-list-item {$menu == 13 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(13);
          login_db.setItem("menu_base", 13);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >merge_type</i
        >
        <span class="mdc-list-item__text">Trayectos</span>
      </a>
      <a
        class="mdc-list-item {$menu == 14 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(14);
          login_db.setItem("menu_base", 14);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >widgets</i
        >
        <span class="mdc-list-item__text">Reportes</span>
      </a>
      <hr class="mdc-list-divider" />
      <a
        class="mdc-list-item {$menu == 6 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(6);
          login_db.setItem("menu_base", 6);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >assessment</i
        >
        <span class="mdc-list-item__text">Monitor</span>
      </a>
      <a
        class="mdc-list-item {$menu == 7 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(7);
          login_db.setItem("menu_base", 7);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >account_circle</i
        >
        <span class="mdc-list-item__text">Choferes</span>
      </a>
      <a
        class="mdc-list-item {$menu == 8 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(8);
          login_db.setItem("menu_base", 8);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >chat</i
        >
        <span class="mdc-list-item__text">Mensajes</span>
        {#if messages_not_read}
          <i
            class="material-icons"
            style="font-size: 1.5rem; color:red; cursor:pointer; margin-left: 10px;"
            >notifications</i
          >
        {/if}
      </a>
      <a
        class="mdc-list-item {$menu == 9 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(9);
          login_db.setItem("menu_base", 9);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >cloud</i
        >
        <span class="mdc-list-item__text">Indicadores</span>
      </a>
      <hr class="mdc-list-divider" />
      <a
        class="mdc-list-item {$menu == 11 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(11);
          login_db.setItem("menu_base", 11);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >leak_add</i
        >
        <span class="mdc-list-item__text">Telemetria</span>
      </a>
      <a
        class="mdc-list-item {$menu == 12 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(12);
          login_db.setItem("menu_base", 12);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >error_outline</i
        >
        <span class="mdc-list-item__text">DTC</span>
      </a>
      <hr class="mdc-list-divider" />
      <a
        class="mdc-list-item {$menu == 15 ? 'mdc-list-item--activated' : ''}"
        href="/"
        on:click|preventDefault={() => {
          menu.set(15);
          login_db.setItem("menu_base", 15);
        }}
      >
        <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
          >settings</i
        >
        <span class="mdc-list-item__text">Configuraciones</span>
      </a>
    </nav>
  </div>
</aside>
<div class="mdc-drawer-scrim" />

<div class="mdc-menu mdc-menu-surface">
  <ul
    class="mdc-list"
    role="menu"
    aria-hidden="true"
    aria-orientation="vertical"
    tabindex="-1"
  >
    <li
      class="mdc-list-item"
      role="menuitem"
      on:click|preventDefault={() => {
        window.document.body.classList.remove("central");
        menu.set(0);
        log_out_real();
      }}
    >
      <i
        class="material-icons mdc-list-item__graphic"
        aria-hidden="true"
        style="margin-right:10px">lock_open</i
      >
      <span class="mdc-list-item__text">Cerrar sesion</span>
    </li>
  </ul>
</div>

{#if alerta_off}
  <div class="alert-top alert alert-danger mb-0 text-center" role="alert">
    <strong>¡ALERTA Portal desconectado!</strong>
  </div>
{/if}

{#if speech_alert}
  <div class="alert-top2 alert alert-light mb-0 text-center" role="alert">
    <strong>{speech_text}</strong>
  </div>
{/if}

{#if alerta_mensaje}
  <div class="alert-top3 alert alert-dark mb-0 text-center" role="alert">
    <strong>{portal_notification}</strong>
  </div>
{/if}

<Alertas
  {routes_db}
  {socket_query}
  {login_db}
  {chats_db}
  {monitor_worker}
  {protocol_db}
  {user_alerts}
/>

<!-- Notification -->
<div
  class="toast-container"
  style="position: fixed;top: 55px;right: 55px;z-index: 1050;"
>
  {#each notificaciones as item}
    <div
      class="toast"
      id="myToast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      data-delay="5000"
      use:notification_control
      id_n={item.id}
    >
      <div class="toast-header bg-dark text-white">
        <i class="material-icons md-2">notifications</i>
        <strong class="mr-auto" style="padding-left: 10px;"
          >Mensaje{item.driver != "" ? " de " + item.driver : ""}</strong
        >
        <button
          type="button"
          class="ml-2 mb-1 close text-white"
          data-dismiss="toast"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="toast-body bg-secondary text-white">
        {item.message}
      </div>
    </div>
  {/each}
</div>

{#if $menu == 1}
  <Inicio />
{:else if $menu == 2}
  <Recorridos {socket_query} {units_travel_db} />
{:else if $menu == 3}
  <Geocercas {socket_query} />
{:else if $menu == 4}
  <Viajes {ecm_db} {socket_query} {units_travel_db} {routes_db} />
{:else if $menu == 5}
  <Rutas {socket_query} {routes_db} />
{:else if $menu == 6}
  <Monitor {socket_query} {routes_db} {units_travel_db} />
{:else if $menu == 7}
  <Choferes {socket_query} {chats_db} {travel_db} {units_travel_db} />
{:else if $menu == 8}
  <Mensajes {socket_query} {chats_db} />
{:else if $menu == 9}
  <Panel {socket_query} />
{:else if $menu == 10}
  <Estado {socket_query} />
{:else if $menu == 11}
  <Telemetria {ecm_db} {vins_db} {socket_query} {units_travel_db} />
{:else if $menu == 12}
  <Dtc {dtc_db} {ecm_db} {vins_db} {socket_query} {units_travel_db} />
{:else if $menu == 13}
  <Trayectos {socket_query} {routes_db} {units_travel_db} />
{:else if $menu == 14}
  <Reportes {socket_query} {travel_db} {routes_db} {units_travel_db} />
{:else if $menu == 15}
  <Configuraciones {socket_query} {user_level} />
{:else if $menu == 16}
  <Torre />
{/if}
<footer />

<style>
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  .alert-top2 {
    position: fixed;
    top: 0.2rem;
    left: 45%;
    width: 10%;
    z-index: 1050;
  }
  .alert-top3 {
    position: fixed;
    top: 0.1rem;
    left: 30%;
    width: 40%;
    z-index: 4;
  }
  .navbar-brand {
    font-size: 1rem;
  }
  .btn {
    font-size: 0.75rem;
  }
  .btn.big {
    font-size: 1rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  button:focus {
    outline: 0px;
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.5rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.4rem;
  }
  .material-icons.md-2 {
    font-size: 2rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  .timeline-Tweet-text {
    color: red;
  }

  .bolita {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(135deg, #f5f304e0, #ff0000);
    position: fixed;
    top: 20px;
    right: 10px;
    opacity: 0;
    transition: all 0.5s ease;
    z-index: 1000;
    pointer-events: none;
  }
  .appear {
    opacity: 0.8;
    transform: scale(1);
  }
  .disappear {
    opacity: 0;
    transform: scale(0.5);
  }
  .thinking {
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.5);
    animation: borderPulse 1.5s infinite;
  }
  @keyframes -global-borderPulse {
    0% {
      box-shadow: 0 0 20px rgba(255, 0, 0, 0.5);
    }
    50% {
      box-shadow: 0 0 40px rgba(255, 0, 0, 1);
    }
    100% {
      box-shadow: 0 0 20px rgba(255, 0, 0, 0.5);
    }
  }
  .speaking {
    animation: speakingAnimation 1s infinite;
  }
  @keyframes -global-speakingAnimation {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
</style>

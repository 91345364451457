<script>
  //https://programadorwebvalencia.com/javascript-recortar-y-previsualizar-imagen/
  export let chats_db;
  export let socket_query;
  export let travel_db;
  export let units_travel_db;
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    travels_list,
    poli,
    map_ready,
    group,
  } from "./stores.js";

  let selected = !localStorage.getItem("selected_driver")
    ? -1
    : parseInt(localStorage.getItem("selected_driver"));
  $: {
    localStorage.setItem("selected_driver", selected);
  }
  let selected_new = -1;
  let driver_name = "-";
  let tel = "-";
  let chatId = "-";
  let chatType = "-";
  let usuario = "-";
  let inter;
  let inter2;
  let aceleracion = 0,
    frenada = 0,
    exceso = 0,
    vuelta = 0;
  let travels_list_op = [];
  let driver_id = "";
  let photo_file = "img/profile.png";
  let travels_day_km_g = [];
  let q_score = 0;
  let s_score = 0;
  let alerts_security = [
    "4330d447-f21c-468b-b98d-020c23e13b2b",
    "d77ff8f7-37f7-4173-9f15-7d0c41d798d5",
    "d6247ba3-a6f6-4781-a220-e3cc24ec26e8",
    "e5bf3b75-934b-41b4-9a2a-864e8d54c2dd",
  ];
  async function maping(i, manual = false) {
    console.log(
      "maping",
      mapa_on_,
      add_mode,
      drivers_add,
      selected_new,
      tel_edit_mode,
      selected
    );
    if (manual) {
      mapa_on_ = false;
      add_mode = false;
      drivers_add = [];
      selected_new = -1;
      tel_edit_mode = false;
      selected = i;
      score = 0;
      score_b = 0;
    }
    driver_id = $drivers_list[i][0];
    driver_name =
      typeof $drivers[$drivers_list[i][0]].name != "undefined"
        ? $drivers[$drivers_list[i][0]].name
        : "";
    tel =
      typeof $drivers[$drivers_list[i][0]].tel != "undefined"
        ? $drivers[$drivers_list[i][0]].tel
        : "";
    chatId =
      typeof $drivers[$drivers_list[i][0]].chatId != "undefined"
        ? $drivers[$drivers_list[i][0]].chatId
        : "";
    usuario =
      typeof $drivers[$drivers_list[i][0]].user != "undefined"
        ? $drivers[$drivers_list[i][0]].user
        : "";
    travels_list_op =
      typeof $drivers[$drivers_list[i][0]].travels_list != "undefined"
        ? $drivers[$drivers_list[i][0]].travels_list
        : [];
    chatType =
      chatId.search("\\+52") >= 0
        ? "Vector Messenger"
        : typeof $drivers[$drivers_list[i][0]].ot_account != "undefined"
          ? "OT Drive"
          : chatId.split("-").length == 5
            ? "Web App"
            : chatId != ""
              ? "Telegram APP"
              : "Ninguno";
    photo_file =
      typeof $drivers[$drivers_list[i][0]].photo != "undefined"
        ? $drivers[$drivers_list[i][0]].photo
        : "img/profile.png";
    try {
      clearInterval(inter);
    } catch (e) {
      console.log(e);
    }
    try {
      clearInterval(inter2);
    } catch (e) {
      console.log(e);
    }
    /*
    inter = setInterval(() => {
      score < $drivers[$drivers_list[i][0]].score
        ? (score = score + 1)
        : clearInterval(inter);
    }, 10);
    */
    //chart_1.data.datasets[0].data = $drivers[$drivers_list[i][0]].travels;
    chart_1.update();
    aceleracion = $drivers[$drivers_list[i][0]].indicators[0];
    frenada = $drivers[$drivers_list[i][0]].indicators[1];
    exceso = $drivers[$drivers_list[i][0]].indicators[2];
    //bar1.animate([{width: "0%"},{width: "100%"}],{ duration: 1000});
    // Carga de viajes basados en historial
    console.log("driver_id", driver_id);
    travels_list_op = [];
    let travels_list_op_ = [];
    let travel;
    let travels_day = {};
    let travels_day_km = {};
    let travels_day_g = [];
    travels_day_km_g = [];
    let travels_day_km_g_tot = 0;
    let travels_day_lab = [];
    let total_alt = 0;
    let total_alt_safe = 0;
    let travel_buff = {};
    aceleracion = 0;
    frenada = 0;
    exceso = 0;
    vuelta = 0;
    if ($travels_list != null) {
      let travels_list_ = [...$travels_list];
      for (let x in travels_list_.reverse()) {
        if (typeof travel_buff[travels_list_[x]] == "undefined") {
          try {
            travel = await travel_db.getItem(travels_list_[x]);
            travel_buff[travels_list_[x]] = travel;
          } catch (e) {
            console.log("Error leyendo travel", e);
          }
        } else {
          travel = travel_buff[travels_list_[x]];
        }
        if (travel != null) {
          travel = JSON.parse(travel);
          if (
            travel.oper == driver_id &&
            travel.status == 0 &&
            new Date() / 1000 - travel.end < 60 * 60 * 24 * 30
          ) {
            travels_list_op.push([
              moment(new Date(travel.start * 1000)).format("DD/MM/YY HH:mm"),
              typeof $nicks_list[travel.id] != "undefined"
                ? $nicks_list[travel.id]
                : travel.id,
              typeof $geos_class[travel.org] != "undefined"
                ? $geos_class[travel.org][0]
                : typeof $poli[travel.org] != "undefined"
                  ? $poli[travel.org][1]
                  : travel.org,
              travel.sec_dests.length > 0
                ? typeof $geos_class[
                    travel.sec_dests[travel.sec_dests.length - 1]
                  ] != "undefined"
                  ? $geos_class[
                      travel.sec_dests[travel.sec_dests.length - 1]
                    ][0]
                  : typeof $poli[
                        travel.sec_dests[travel.sec_dests.length - 1]
                      ] != "undefined"
                    ? $poli[travel.sec_dests[travel.sec_dests.length - 1]][1]
                    : travel.sec_dests[travel.sec_dests.length - 1]
                : typeof $geos_class[travel.dest] != "undefined"
                  ? $geos_class[travel.dest][0]
                  : typeof $poli[travel.dest] != "undefined"
                    ? $poli[travel.dest][1]
                    : travel.dest,
              time_code_s(travel.end - travel.start),
              travel.alerts.length,
              travel.id,
              travel.start,
              travel.end,
              travel.uuid,
              travel.alerts,
            ]);
            for (let w in travel.alerts) {
              if (alerts_security.includes(travel.alerts[w].alerts.alertid)) {
                total_alt = total_alt + 1;
                if (travel.alerts[w].alerts.alertid == alerts_security[0]) {
                  vuelta = vuelta + 1;
                }
                if (travel.alerts[w].alerts.alertid == alerts_security[1]) {
                  aceleracion = aceleracion + 1;
                }
                if (travel.alerts[w].alerts.alertid == alerts_security[2]) {
                  frenada = frenada + 1;
                }
                if (travel.alerts[w].alerts.alertid == alerts_security[3]) {
                  exceso = exceso + 1;
                }
              } else {
                total_alt_safe = total_alt_safe + 1;
              }
            }
            //total_alt = total_alt + travel.alerts.length;
            travels_list_op_.push(travel);
            let c_travel = moment(travel.start * 1000)
              .startOf("day")
              .format("DD/MM/YY");
            if (typeof travels_day[c_travel] == "undefined") {
              travels_day[c_travel] = 1;
            } else {
              travels_day[c_travel] = travels_day[c_travel] + 1;
            }
            if (typeof travel.distance == "undefined") {
              travel.distance = 0;
            }
            travels_day_km_g_tot = travels_day_km_g_tot + travel.distance;
            if (typeof travels_day_km[c_travel] == "undefined") {
              travels_day_km[c_travel] = travel.distance;
            } else {
              travels_day_km[c_travel] =
                travels_day_km[c_travel] + travel.distance;
            }
          }
        }
      }
      travels_day_g = [];
      for (let x in travels_day) {
        travels_day_lab.push(x);
        travels_day_g.push(travels_day[x]);
      }
      travels_day_km_g = [];
      for (let x in travels_day_km) {
        travels_day_km_g.push(Math.round(travels_day_km[x]));
      }
      chart_1.data.labels = [];
      chart_1.data.datasets = [];
      /*
      chart_1.data.datasets.push({
        label: "Kilomentros ("+comma(travels_day_km_g_tot)+" KM)",
        data: travels_day_km_g.reverse(),
        borderColor: "rgb(254, 153, 103)",
        backgroundColor: "rgb(254, 153, 103)",
        fill: false,
        pointRadius: 3,
        borderWidth: 3,
        yAxisID: "y-axis-2",
        type: "line",
      });
      */
      chart_1.data.datasets.push({
        label: "Viajes Totales (" + comma(travels_day_km_g_tot) + " KM)",
        data: travels_day_g.reverse(),
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgb(54, 162, 235)",
        fill: false,
        pointRadius: 0,
        yAxisID: "y-axis-1",
      });
      chart_1.data.labels = travels_day_lab.reverse();
      chart_1.update();
      travels_list_op = [...travels_list_op];
    }
    q_score =
      (total_alt / travels_list_op.length) * 0.15 > 1
        ? 1
        : (total_alt / travels_list_op.length) * 0.15;
    q_score = parseInt((1 - q_score) * 100);
    q_score = q_score == 0 ? 1 : q_score;
    inter = setInterval(() => {
      score < q_score ? (score = score + 1) : clearInterval(inter);
    }, 10);
    // Safety
    s_score =
      (total_alt_safe / travels_list_op.length) * 0.15 > 1
        ? 1
        : (total_alt_safe / travels_list_op.length) * 0.15;
    s_score = parseInt((1 - s_score) * 100);
    s_score = s_score == 0 ? 1 : s_score;
    inter2 = setInterval(() => {
      score_b < s_score ? (score_b = score_b + 1) : clearInterval(inter2);
    }, 10);
  }

  let mapa_on_ = false;
  let map_routes;
  function initMap() {
    map_routes = new google.maps.Map(document.getElementById("map_routes"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
    });
  }
  let bounds;
  let ruta_real;
  let m_inicio;
  let m_fin;
  let fecha_now = "";
  let unidad_now = "";
  let origen_now = "";
  let destino_now = "";
  let duracion_now = "";
  let alertas_now = "";
  let time_travel = 0;
  let time_idle = 0;
  let time_off = 0;
  let m_pause = [];
  let paradas_now = 0;
  let total_dist = 0;
  async function get_travel(id, start, end, full) {
    total_dist = 0;
    fecha_now = full[0];
    unidad_now = full[1];
    origen_now = full[2];
    destino_now = full[3];
    duracion_now = full[4];
    alertas_now = full[5];
    mapa_on_ = true;
    map_routes.setCenter({ lat: 24.458489, lng: -102.217231 });
    let travel = { id: id, start: start, end: end };
    bounds = new google.maps.LatLngBounds();
    let coordenada_real = [];
    let last_coordenada_real = 0;
    let last_x = -1;
    let last_state = 2;
    let last_state_ = -1;
    let grap_hos = [];
    let grap_hos_c = -1;
    let last_grap_hos_c = -1;
    time_travel = 0;
    time_idle = 0;
    time_off = 0;
    let last_time_travel = 0;
    let last_time_idle = 0;
    let last_time_off = 0;
    let pause_list = [];
    if (typeof travel.start != "undefined") {
      last_coordenada_real = travel.start;
      let rec_p = JSON.parse(await units_travel_db.getItem(travel.id));
      console.log(rec_p);
      for (var x in rec_p) {
        if (typeof rec_p[x]["p"] != "undefined") {
          for (var w in rec_p[x]["p"]) {
            if (
              rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60 > travel.start &&
              (typeof travel.end == "undefined" ||
                rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60 <= travel.end)
            ) {
              let lat = (rec_p[x]["ps"][0] - rec_p[x]["p"][w][0]) / 10000;
              let lng = (rec_p[x]["ps"][1] - rec_p[x]["p"][w][1]) / 10000;
              if (coordenada_real.length > 0) {
                total_dist =
                  total_dist +
                  get_km(
                    coordenada_real[coordenada_real.length - 1].lat,
                    coordenada_real[coordenada_real.length - 1].lng,
                    lat,
                    lng
                  );
              }
              coordenada_real.push({ lat: lat, lng: lng });
              last_coordenada_real = rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60;
              bounds.extend({ lat: lat, lng: lng });
              if (last_x != x) {
                grap_hos_c++;
                grap_hos.push([]);
                last_x = x;
              }
              if (rec_p[x]["p"].length - 1 == parseInt(w)) {
                last_state = 2;
              } else if (typeof rec_p[x]["p"][w][4] != "undefined") {
                if (rec_p[x]["p"][w][4] > 10) {
                  pause_list.push({ lat: lat, lng: lng });
                  last_state = 1;
                } else {
                  last_state = 0;
                }
              } else {
                last_state = 0;
              }
              if (last_state_ != last_state) {
                if (last_state_ == 2) {
                  last_time_off =
                    last_time_off == 0
                      ? rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60
                      : last_time_off;
                  time_off =
                    time_off +
                    (rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60 - last_time_off);
                }
                if (last_state_ == 1) {
                  last_time_idle =
                    last_time_idle == 0
                      ? rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60
                      : last_time_idle;
                  time_idle =
                    time_idle +
                    (rec_p[x]["ds"] +
                      rec_p[x]["p"][w][3] * 60 -
                      last_time_idle);
                }
                if (last_state_ == 0) {
                  last_time_travel =
                    last_time_travel == 0
                      ? rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60
                      : last_time_travel;
                  time_travel =
                    time_travel +
                    (rec_p[x]["ds"] +
                      rec_p[x]["p"][w][3] * 60 -
                      last_time_travel);
                }
                last_time_off = rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60;
                last_time_idle = rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60;
                last_time_travel = rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60;
                last_state_ = last_state;
                grap_hos[grap_hos_c].push({
                  y: last_state,
                  t: (rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60) * 1000,
                });
                if (grap_hos_c > 0 && grap_hos_c != last_grap_hos_c) {
                  last_grap_hos_c = grap_hos_c;
                  grap_hos[grap_hos_c - 1].push({
                    y: last_state,
                    t: (rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60) * 1000,
                  });
                }
              }
            }
          }
        }
      }
    }
    chart_3.data.datasets[0].data[0] = time_travel;
    chart_3.data.datasets[0].data[1] = time_off;
    chart_3.data.datasets[0].data[2] = time_idle;
    chart_3.update();

    console.log("grap_hos", grap_hos);
    if (coordenada_real.length > 0) {
      map_routes.setZoom(5);
      map_routes.fitBounds(bounds);
    } else {
      map_routes.setZoom(5);
      map_routes.setCenter({ lat: 24.458489, lng: -102.217231 });
    }
    let zIndex = 0;
    if (typeof ruta_real != "undefined") {
      ruta_real.setMap(null);
    }
    ruta_real = new google.maps.Polyline({
      path: coordenada_real,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2.5,
      map: map_routes,
      zIndex: zIndex,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            strokeColor: "#FF0000",
          },
          offset: "100px",
          repeat: "100px",
        },
      ],
    });
    zIndex++;
    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    m_inicio = new google.maps.Marker({
      position: coordenada_real[0],
      map: map_routes,
      animation: google.maps.Animation.DROP,
      icon: "img/dd-start.png",
      zIndex: zIndex,
    });

    zIndex++;
    if (typeof m_fin != "undefined") {
      m_fin.setMap(null);
    }
    m_fin = new google.maps.Marker({
      position: coordenada_real[coordenada_real.length - 1],
      map: map_routes,
      animation: google.maps.Animation.DROP,
      icon: "img/dd-end.png",
      zIndex: zIndex,
    });

    zIndex++;
    for (let w in m_pause) {
      if (typeof m_pause[w] != "undefined") {
        m_pause[w].setMap(null);
      }
    }
    m_pause = [];
    for (let z in pause_list) {
      m_pause[z] = new google.maps.Marker({
        position: pause_list[z],
        animation: google.maps.Animation.DROP,
        map: map_routes,
        icon: "img/dd-pause.png",
        zIndex: 0,
      });
    }
    paradas_now = pause_list.length;

    chart_2.data.datasets = [];
    for (let x in grap_hos) {
      chart_2.data.datasets.push({
        label: "Recorrido " + x,
        fill: true,
        borderColor: colors_line[x],
        backgroundColor: color_sombra[x],
        steppedLine: true,
        data: grap_hos[x],
      });
    }
    chart_2.update();
  }

  let coordenada_alertas = [];
  let bounds_alerts;
  function get_alerts(id, alerts) {
    coordenada_alertas = [];
    alert_list_modal = [];
    bounds_alerts = new google.maps.LatLngBounds();
    for (let x in alerts) {
      alert_list_modal.push([
        [
          moment(new Date(alerts[x].alerts_crtl.date_on * 1000)).format(
            "DD/MM/YY HH:mm"
          ),
        ],
        [alerts[x].alerts.name],
        {
          lat: alerts[x].pos_data.lat,
          lng: alerts[x].pos_data.lon,
        },
      ]);
      coordenada_alertas.push({
        lat: alerts[x].pos_data.lat,
        lng: alerts[x].pos_data.lon,
      });
      bounds_alerts.extend({
        lat: alerts[x].pos_data.lat,
        lng: alerts[x].pos_data.lon,
      });
    }
    alert_list_modal = [...alert_list_modal];
    detalle_modal = true;
  }

  function maping_new(i) {
    selected_new = i;
  }
  let score = 0;
  let score_b = 0;

  var chart1 = {
    type: "bar",
    data: {
      labels: [],
      datasets: [],
    },
    plugins: [ChartDataLabels],
    options: {
      plugins: {
        datalabels: {
          color: "white",
          font: { size: "12" },
          formatter: function (value, context) {
            return (
              comma(
                travels_day_km_g[
                  travels_day_km_g.length - 1 - context.dataIndex
                ]
              ) + " km"
            );
          },
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              offsetGridLines: true,
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Numero de Viajes",
            },
            offset: true,
          },
          /*
          {
            type: "linear",
            display: true,
            position: "right",
            id: "y-axis-2",
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Distancia (Km)",
            },
            offset: true,
          }
          */
        ],
      },
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Viajes completados",
      },
      maintainAspectRatio: false,
    },
  };

  let chart2 = {
    type: "line",
    data: {
      datasets: [
        {
          label: "Recorrido 1",
          data: [
            { y: 2, t: 1579908586980 },
            { y: 1, t: 1579909586980 },
            { y: 0, t: 1579910586980 },
            { y: 0, t: 1579912586980 },
            { y: 0, t: 1579913586980 },
            { y: 1, t: 1579914586980 },
            { y: 2, t: 1579915586980 },
            { y: 2, t: 1579916586980 },
          ],
          fill: true,
          borderColor: "#2196f3",
          backgroundColor: "#e3f2fd",
          steppedLine: true,
        },
        {
          label: "Recorrido 2",
          data: [
            { y: 2, t: 1579916586980 },
            { y: 2, t: 1579917586980 },
            { y: 1, t: 1579918586980 },
            { y: 0, t: 1579919586980 },
            { y: 0, t: 1579920586980 },
            { y: 0, t: 1579921586980 },
            { y: 1, t: 1579922586980 },
            { y: 2, t: 1579923586980 },
            { y: 2, t: 1579924586980 },
          ],
          fill: true,
          borderColor: "#e91e63",
          backgroundColor: "#ffebee",
          steppedLine: true,
        },
      ],
    },
    options: {
      onClick: function (d) {
        let e = chart_2.getElementAtEvent(d);
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            let c =
              tooltipItem.yLabel == 0
                ? "Drive"
                : tooltipItem.yLabel == 1
                  ? "Ilde"
                  : "Off";
            return c;
          },
        },
      },
      title: {
        display: false,
        fontSize: 12,
        padding: 0,
        text: "HOS",
      },
      legend: {
        display: false,
      },
      layout: {
        padding: {
          top: 30,
          right: 20,
        },
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              callback: function (value, index, values) {
                return value == 0 ? "Drive" : value == 1 ? "Idle" : "Off";
              },
            },
          },
        ],
        xAxes: [
          {
            type: "time",
            time: {
              displayFormats: {
                minute: "HH:mm",
              },
            },
          },
        ],
      },
    },
  };

  var chart3 = {
    type: "pie",
    data: {
      labels: ["On", "Off", "Idle"],
      datasets: [
        {
          data: [10, 10, 20],
          backgroundColor: ["#90caf9", "#ff6090", "#9a67ea"],
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.labels[tooltipItem.index];
            var val =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return label + ": " + time_code_s(val);
          },
        },
      },
    },
  };

  let chart_1, chart_2, chart_3, bar1;
  let mount_complete = false;
  onMount(() => {
    chart_1 = new Chart(
      document.getElementById("chart1").getContext("2d"),
      chart1
    );
    chart_2 = new Chart(
      document.getElementById("chart2").getContext("2d"),
      chart2
    );
    chart_3 = new Chart(
      document.getElementById("chart3").getContext("2d"),
      chart3
    );
    mount_complete = true;
  });
  $: {
    if ($map_ready == 1 && mount_complete) {
      initMap();
      console.log("mapa cargado");
    }
  }

  $: {
    $drivers_list;
    $travels_list;
    mount_complete;
    refresh_change();
  }
  function refresh_change() {
    if (selected != -1 && mount_complete && $drivers_list.length > 0) {
      maping(selected);
    } else if ($drivers_list.length > 0 && mount_complete) {
      maping(0);
    }
  }
  let drivers_add = [];
  let add_mode = false;
  function add_driver() {
    selected = -1;
    if (drivers_add.length == 0) {
      drivers_add.push([]);
      drivers_add = [...drivers_add];
      add_mode = true;

      driver_name = "";
      password_new = "";
      driver_id = "";
      tel = "";
      tel_new = "";
      chatId = "";
      chatId_new = "";
      chatType = "";
      usuario = "";
      usuario_new = "";
      photo_file = "img/profile.png";
      tel_edit_mode = true;
      chart_1.data.labels = [];
      chart_1.data.datasets = [];
      chart_1.update();
      aceleracion = 0;
      frenada = 0;
      exceso = 0;
      vuelta = 0;
      travels_list_op = [];

      selected_new = 0;
    }
  }
  let tel_edit_mode = false;
  let tel_new, chatId_new, usuario_new;
  let password_new = "•••••••••";
  let saved_ok = false,
    saved_type,
    saved_text;
  function edit_tel() {
    if (selected != -1) {
      tel_new = tel;
      chatId_new = chatId;
      usuario_new = usuario;
      tel_edit_mode = true;
      password_new = "•••••••••";
    }
  }
  function save_driver() {
    if (selected != -1) {
      if (password_new == "•••••••••") {
        password_new = "";
      }
      socket_query.emit(
        "save_driver",
        driver_id,
        tel_new,
        chatId_new,
        usuario_new,
        password_new,
        driver_name,
        (data) => {
          if (data == "ok") {
            saved_type = "alert-success";
            saved_text = "Chofer guardado";
            saved_ok = true;
            tel_edit_mode = false;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    }
    if (selected_new != -1 && selected == -1) {
      if (driver_name == null) {
        return 0;
      }
      socket_query.emit(
        "new_driver",
        driver_name,
        tel_new,
        chatId_new,
        usuario_new,
        password_new,
        (data) => {
          if (data == "ok") {
            saved_type = "alert-success";
            saved_text = "Chofer guardado";
            saved_ok = true;
            tel_edit_mode = false;
            drivers_add = [];
            add_mode = false;
            selected_new = -1;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    }
  }
  $: {
    // change usuario_new to miusculas
    if (usuario_new) {
      usuario_new = usuario_new.toLowerCase();
    }
  }
  $: {
    driver_name;
    if (true) {
      if (selected_new != -1 || selected != -1) {
        usuario_new = driver_name.toLowerCase().replace(/ /g, "");
      }
    }
  }
  function time_code_s(time) {
    //if(time<60){return moment.utc().subtract(1, 'years').startOf('years').add({ seconds: time }).format('s[s]')} // 's[s]'
    if (time < 60) {
      return "0m";
    } else if (time < 3600) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("m[min]");
    } else if (time < 86400) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("H[h] m[min]");
    } else if (time < 7776000) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("D[d] H[h] m[min]");
    } else {
      return "+3 meses";
    }
  }
  let colors_line = [
    "#2196f3",
    "#e91e63",
    "#03a9f4",
    "#9c27b0",
    "#00bcd4",
    "#673ab7",
    "#009688",
    "#3f51b5",
    "#4caf50",
    "#ff5722",
    "#8bc34a",
    "#ff9800",
    "#cddc39",
    "#ffc107",
    "#607d8b",
    "#ffeb3b",
    "#9e9e9e",
    "#212121",
    "#f44336",
  ];
  let color_sombra = [
    "#e3f2fd",
    "#fce4ec",
    "#e1f5fe",
    "#f3e5f5",
    "#e0f7fa",
    "#ede7f6",
    "#e0f2f1",
    "#e8eaf6",
    "#e8f5e9",
    "#fbe9e7",
    "#f1f8e9",
    "#fff3e0",
    "#f9fbe7",
    "#fff8e1",
    "#eceff1",
    "#fffde7",
    "#fafafa",
    "#fafafa",
    "#ffebee",
  ];

  let detalle_modal = false;
  let alert_list_modal = [];
  let selected_alert = -1;
  let map_alerts;
  let alert_marks = [];
  function show_detalle_map() {
    selected_alert = -1;
    map_alerts = new google.maps.Map(document.getElementById("map_alerts"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      maxZoom: 16,
    });
    for (let z in alert_marks) {
      if (typeof alert_marks[z] != "undefined") {
        alert_marks[z].setMap(null);
      }
    }
    alert_marks = [];
    for (let x in coordenada_alertas) {
      alert_marks[x] = new google.maps.Marker({
        position: coordenada_alertas[x],
        map: map_alerts,
        animation: google.maps.Animation.DROP,
        icon: "img/markerAlt.png",
        zIndex: 1,
      });
    }
    map_alerts.fitBounds(bounds_alerts);
  }
  function maping_alert(i) {
    selected_alert = i;
    map_alerts.panTo(alert_list_modal[i][2]);
    alert_marks[i].setMap(null);
    alert_marks[i].setAnimation(google.maps.Animation.DROP);
    alert_marks[i].setMap(map_alerts);
    map_alerts.setZoom(16);
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  function comma(x) {
    try {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return x;
    }
  }
  let f_score = 0;
  function change_score() {
    f_score = f_score == 0 ? 1 : 0;
    score = 0;
    score_b = 0;
    inter = setInterval(() => {
      score < q_score ? (score = score + 1) : clearInterval(inter);
    }, 10);
    inter2 = setInterval(() => {
      score_b < s_score ? (score_b = score_b + 1) : clearInterval(inter2);
    }, 10);
  }
</script>

{#if detalle_modal}
  <div
    class="modal fade {detalle_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {detalle_modal
      ? 'block'
      : 'none'}; z-index:1039;"
    use:show_detalle_map
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
      style="min-width: 95%"
    >
      <div class="modal-content" style="min-height: 99%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">warning</i> Detalle de alertas
            <strong />
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => {
              detalle_modal = false;
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body h-100 p-0">
          <div class="row h-100 no-gutters">
            <div class="col-9 h-100">
              <div class="h-100" id="map_alerts" />
            </div>
            <div class="col-3 h-100">
              <div class="card h-100 bg-light card-b">
                <div class="d-flex flex-column h-100">
                  <div class="card-header bg-dark text-white b-0">
                    <i tooltip="Reportes" class="material-icons md-90"
                      >report_problem</i
                    > Alertas
                  </div>
                  <div class="card-body flex-grow-1 overflow-auto">
                    <div class="list-group">
                      {#each alert_list_modal as item, i}
                        <a
                          href="/"
                          class:list-group-item-warning={selected_alert == i}
                          class:active_={selected_alert == i}
                          on:click|preventDefault={() => {
                            maping_alert(i);
                          }}
                          class="list-group-item list-group-item-action"
                          ><strong>{item[0]}</strong> | {item[1]}</a
                        >
                      {/each}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div
            class="card-header bg-dark text-white b-0"
            style="padding-right: 0.2rem;"
          >
            <div class="d-flex">
              <div class="flex-grow-1">
                <i tooltip="Operador" class="material-icons md-90">person</i> Choferes
              </div>
              <div class="d-flex justify-content-end">
                {#if !add_mode && !mapa_on_}
                  <button
                    id="add_driver"
                    on:click={add_driver}
                    type="button"
                    class="btn btn-info mini"
                  >
                    <i class="material-icons md-90">add_circle_outline</i
                    >Agregar
                  </button>
                {/if}
              </div>
            </div>
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each drivers_add as item, i}
                <a
                  href="/{item[0]}"
                  class:list-group-item-warning={selected_new == i}
                  class:active_={selected_new == i}
                  on:click|preventDefault={() => {
                    maping_new(i);
                  }}
                  class="list-group-item list-group-item-action">Nuevo chofer</a
                >
              {/each}
              {#each $drivers_list as item, i}
                <a
                  href="/{item[0]}"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={() => {
                    maping(i, true);
                  }}
                  class="list-group-item list-group-item-action"
                  >{typeof $drivers[item[0]].name != "undefined"
                    ? $drivers[item[0]].name
                    : item}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="d-flex flex-column h-100">
        <div class="card-body flex-grow-1 overflow-auto">
          <div class="row h-50 no-gutters">
            <div class="col-2 h-100">
              <div
                class="card h-100 bg-light"
                style="font-size:0.8rem; border-left:0; border-top:0;"
              >
                <div class="d-flex flex-column h-100 overflow-auto">
                  {#if selected != -1 || selected_new != -1}
                    <div class="card-header b-0 bg-dark text-white text-center">
                      <h5>{driver_name}</h5>
                    </div>
                    <div class="d-flex flex-grow-1 align-items-center">
                      <img class="img-fluid" src={photo_file} alt="profile" />
                    </div>
                  {/if}
                </div>
              </div>
            </div>
            <div class="col-10 h-100">
              <div
                class="card h-100"
                style="font-size:0.8rem; border-top:0; border-right:0;"
              >
                <div class="d-flex flex-column h-100">
                  <div class="card-header b-0 bg-dark text-white">
                    <span
                      >{!mapa_on_
                        ? "Historial de viajes"
                        : "Mapa detalle"}</span
                    >
                  </div>
                  <div class="d-flex flex-grow-1 overflow-auto">
                    <canvas
                      id="chart1"
                      style="display: {mapa_on_ ? 'none' : ''};"
                    />
                    <div
                      class="h-100 w-100"
                      id="map_routes"
                      style="display: {!mapa_on_ ? 'none' : ''};"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row h-50 no-gutters">
            <div class="col-2 h-100">
              <div class="card h-100">
                <div
                  class="d-flex flex-column h-100"
                  style="overflow-y:hidden;overflow-x: hidden;"
                >
                  <div
                    class="card-header b-0 bg-dark text-white"
                    style="min-height: 26px;"
                  >
                    <span style="vertical-align: sub;"
                      >{f_score == 1 ? "Security" : "Safety"} Score</span
                    >
                  </div>
                  <div
                    class="btn-group mx-2"
                    role="group"
                    style="margin-top:5px;"
                  >
                    <button
                      on:click|preventDefault={change_score}
                      type="button"
                      class="btn {f_score == 0
                        ? 'btn-info'
                        : 'btn-outline-info'}">Safety</button
                    >
                    <button
                      on:click|preventDefault={change_score}
                      type="button"
                      class="btn {f_score == 1
                        ? 'btn-info'
                        : 'btn-outline-info'}">Security</button
                    >
                  </div>
                  {#if f_score == 0}
                    <div
                      class=" d-flex flex-grow-1 align-items-center align-self-center"
                    >
                      <div
                        class="c100 p{score} big {score > 85
                          ? 'green'
                          : score > 50
                            ? ''
                            : 'orange'}"
                      >
                        <span>{score}</span>
                        <div class="slice">
                          <div class="bar" />
                          <div class="fill" />
                        </div>
                      </div>
                    </div>
                    <div class=" d-flex flex-grow-1">
                      <div class="table-responsive" style="overflow: hidden;">
                        <table
                          class="table table-sm"
                          style="margin-bottom: 0rem"
                        >
                          <thead>
                            <tr style="font-size: 0.55rem;">
                              <th
                                class="text-center"
                                scope="col"
                                style="vertical-align: middle;"
                                >Vuelta brusca</th
                              >
                              <th
                                class="text-center"
                                scope="col"
                                style="vertical-align: middle;"
                                >Aceleración brusca</th
                              >
                              <th
                                class="text-center"
                                scope="col"
                                style="vertical-align: middle;"
                                >Frenada brusca</th
                              >
                              <th
                                class="text-center"
                                scope="col"
                                style="vertical-align: middle;"
                                >Exceso de velocidad</th
                              >
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-center"
                                ><span
                                  class="badge badge-{vuelta > 2
                                    ? 'danger'
                                    : vuelta > 0
                                      ? 'warning'
                                      : 'secondary'}">{vuelta}</span
                                ></td
                              >
                              <td class="text-center"
                                ><span
                                  class="badge badge-{aceleracion > 2
                                    ? 'danger'
                                    : aceleracion > 0
                                      ? 'warning'
                                      : 'secondary'}">{aceleracion}</span
                                ></td
                              >
                              <td class="text-center"
                                ><span
                                  class="badge badge-{frenada > 2
                                    ? 'danger'
                                    : frenada > 0
                                      ? 'warning'
                                      : 'secondary'}">{frenada}</span
                                ></td
                              >
                              <td class="text-center"
                                ><span
                                  class="badge badge-{exceso > 2
                                    ? 'danger'
                                    : exceso > 0
                                      ? 'warning'
                                      : 'secondary'}">{exceso}</span
                                ></td
                              >
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  {/if}
                  {#if f_score == 1}
                    <div
                      class=" d-flex flex-grow-1 align-items-center align-self-center"
                    >
                      <div
                        class="c100 p{score_b} big {score_b > 85
                          ? 'green'
                          : score_b > 50
                            ? ''
                            : 'orange'}"
                      >
                        <span>{score_b}</span>
                        <div class="slice">
                          <div class="bar" />
                          <div class="fill" />
                        </div>
                      </div>
                    </div>
                  {/if}
                </div>
              </div>
            </div>
            <div class="col-4 h-100">
              <div class="card h-100">
                <div class="d-flex flex-column h-100 overflow-auto">
                  <div
                    class="card-header b-0 bg-dark text-white"
                    style="display: flex; justify-content:space-between; align-items:center; min-height:26px;"
                  >
                    <span>Datos Generales</span>
                    {#if !mapa_on_}
                      <button
                        id="edit_tel"
                        on:click={edit_tel}
                        type="button"
                        class="btn btn-info mini"
                        style="margin-left: 5px; display: {tel_edit_mode
                          ? 'none'
                          : ''};"
                      >
                        <i class="material-icons" style="font-size: 12px;"
                          >edit</i
                        >
                        Editar
                      </button>
                    {:else}
                      <!-- Button to return to driver data-->
                      <button
                        id="edit_tel"
                        on:click={() => {
                          mapa_on_ = false;
                        }}
                        type="button"
                        class="btn btn-info mini"
                        style="margin-left: 5px;"
                      >
                        <i class="material-icons" style="font-size: 12px;"
                          >arrow_back</i
                        >
                        Regresar
                      </button>
                    {/if}
                  </div>
                  <div class=" d-flex flex-grow-1 pt-0">
                    {#if !mapa_on_}
                      <div class="table-responsive">
                        <table
                          class="table table-sm table-striped"
                          style="margin-bottom: 0rem;"
                        >
                          <tbody>
                            <tr>
                              <!--Driver id-->
                              <th scope="row" class="dato-general">ID</th>
                              <th class="dato-general">{driver_id.slice(-6)}</th
                              >
                            </tr>
                            <tr>
                              <th scope="row" class="dato-general">Nombre</th>
                              {#if tel_edit_mode}
                                <td class="dato-general" style="color:#2196f3">
                                  <input
                                    type="text"
                                    size="20"
                                    maxlength="50"
                                    bind:value={driver_name}
                                  />
                                </td>
                              {:else}
                                <td class="dato-general">{driver_name}</td>
                              {/if}
                            </tr>
                            <tr>
                              <th scope="row" class="dato-general">Teléfono</th>
                              {#if !tel_edit_mode}
                                <td class="dato-general" style="color:#2196f3"
                                  >{tel}</td
                                >
                              {:else}
                                <td class="dato-general" style="color:#2196f3">
                                  <input
                                    type="text"
                                    size="10"
                                    maxlength="10"
                                    bind:value={tel_new}
                                  />
                                </td>
                              {/if}
                            </tr>
                            <tr>
                              <th scope="row" class="dato-general">Usuario</th>
                              {#if !tel_edit_mode}
                                <td
                                  class="dato-general"
                                  style="color:rgb(0 64 253)"
                                  >{usuario}{usuario != ""
                                    ? "_ @" + $group
                                    : ""}</td
                                >
                              {:else}
                                <td
                                  class="dato-general"
                                  style="color:rgb(0 64 253)"
                                >
                                  <input
                                    type="text"
                                    size="20"
                                    maxlength="50"
                                    bind:value={usuario_new}
                                  />_ @{$group}
                                </td>
                              {/if}
                            </tr>
                            <tr>
                              <th scope="row" class="dato-general"
                                >Contraseña</th
                              >
                              {#if !tel_edit_mode}
                                <td class="dato-general"
                                  >{usuario != "-" ? "•••••••••" : "-"}
                                </td>
                              {:else}
                                <td class="dato-general" style="color:#2196f3">
                                  <input
                                    type="password"
                                    size="10"
                                    maxlength="20"
                                    bind:value={password_new}
                                  />
                                </td>
                              {/if}
                            </tr>
                            <tr>
                              <th scope="row" class="dato-general"
                                >Tipo de chat</th
                              >
                              {#if !tel_edit_mode}
                                <td class="dato-general">{chatType} </td>
                              {:else}
                                <td class="dato-general" style="color:#2196f3">
                                  <select
                                    name="chat_type"
                                    bind:value={chatType}
                                  >
                                    <option value="Niguno">Niguno</option>
                                    <option value="Telegram APP"
                                      >Telegram APP</option
                                    >
                                    <option value="OT Drive">OT Drive</option>
                                    <option value="Vector Messenger"
                                      >Vector Messenger</option
                                    >
                                    <option value="Web App">Web App</option>
                                  </select>
                                </td>
                              {/if}
                            </tr>
                            <tr>
                              <th scope="row" class="dato-general">ChatId</th>
                              {#if !tel_edit_mode}
                                <td class="dato-general" style="color:#2196f3"
                                  >{chatId}</td
                                >
                              {:else}
                                <td class="dato-general" style="color:#2196f3">
                                  <input
                                    type="text"
                                    size="10"
                                    maxlength="10"
                                    bind:value={chatId_new}
                                  />
                                </td>
                              {/if}
                            </tr>
                            {#if tel_edit_mode}
                              <tr>
                                <td style="text-align: center;"> </td>
                                <td style="text-align: right;">
                                  <button
                                    id="save_Ruta"
                                    on:click={save_driver}
                                    type="button"
                                    class="btn btn-success">Guardar</button
                                  >
                                  <button
                                    id="cancel_save_destino"
                                    on:click={() => {
                                      add_mode = false;
                                      drivers_add = [];
                                      if (
                                        selected_new != -1 &&
                                        $drivers_list.length > 0
                                      ) {
                                        maping(0, true);
                                      }
                                      selected_new = -1;
                                      tel_edit_mode = false;
                                    }}
                                    type="button"
                                    style="margin-left: 5px;"
                                    class="btn btn-danger">Cancelar</button
                                  >
                                </td>
                              </tr>
                            {/if}
                          </tbody>
                        </table>
                      </div>
                    {:else}
                      <div class="table-responsive">
                        <table
                          class="table table-sm table-striped"
                          style="margin-bottom: 0rem"
                        >
                          <tbody>
                            <tr>
                              <th scope="row" class="text-center"
                                >Inicio real</th
                              >
                              <td class="text-center">{fecha_now}</td>
                            </tr>
                            <tr>
                              <th scope="row" class="text-center">Unidad</th>
                              <td class="text-center">{unidad_now}</td>
                            </tr>
                            <tr>
                              <th scope="row" class="text-center">Origen</th>
                              <td class="text-center">{origen_now}</td>
                            </tr>
                            <tr>
                              <th scope="row" class="text-center">Destino</th>
                              <td class="text-center">{destino_now}</td>
                            </tr>
                            <tr>
                              <th scope="row" class="text-center">Duración</th>
                              <td class="text-center">{duracion_now}</td>
                            </tr>
                            <tr>
                              <th scope="row" class="text-center">Distancia</th>
                              <td class="text-center"
                                >{Math.round(total_dist)}km</td
                              >
                            </tr>
                            <tr>
                              <th scope="row" class="text-center">Alertas</th>
                              <td class="text-center">{alertas_now}</td>
                            </tr>
                            <tr>
                              <th scope="row" class="text-center"
                                >Tiempo de manejo</th
                              >
                              <td class="text-center"
                                >{time_code_s(time_travel)}</td
                              >
                            </tr>
                            <tr>
                              <th scope="row" class="text-center"
                                >Tiempo en idle</th
                              >
                              <td class="text-center"
                                >{time_code_s(time_idle)}</td
                              >
                            </tr>
                            <tr>
                              <th scope="row" class="text-center"
                                >Tiempo en apagada</th
                              >
                              <td class="text-center"
                                >{time_code_s(time_off)}</td
                              >
                            </tr>
                            <tr>
                              <th scope="row" class="text-center"
                                >Numero de paradas</th
                              >
                              <td class="text-center">{paradas_now}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 h-100">
              {#if !mapa_on_}
                <div class="card h-100" style="font-size:0.8rem;">
                  <div class="d-flex flex-column h-100 overflow-auto">
                    <div
                      class="card-header b-0 bg-dark text-white"
                      style="min-height: 26px;"
                    >
                      <span style="vertical-align: sub; font-size:0.85rem;"
                        >Lista de viajes</span
                      >
                    </div>
                    <div class=" d-flex flex-grow-1 pt-0">
                      <table
                        class="table table-sm table-striped"
                        style="margin-bottom: 0rem"
                      >
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Unidad</th>
                            <th scope="col">Origen</th>
                            <th scope="col">Destino</th>
                            <th scope="col">Duración</th>
                            <th scope="col">Alertas</th>
                          </tr>
                        </thead><tbody>
                          {#each travels_list_op as item, i}
                            <tr>
                              <th scope="row">{i + 1}</th>
                              <td>
                                <a
                                  href="/{item[9]}"
                                  on:click|preventDefault={() => {
                                    get_travel(item[6], item[7], item[8], item);
                                  }}>{item[0]}</a
                                >
                              </td>
                              <td>{item[1]}</td>
                              <td>{item[2]}</td>
                              <td>{item[3]}</td>
                              <td>{item[4]}</td>
                              <td>
                                {#if item[5] > 0}
                                  <a
                                    href="/"
                                    on:click|preventDefault={() => {
                                      get_alerts(item[6], item[10]);
                                    }}
                                  >
                                    {item[5]}
                                  </a>
                                {:else}
                                  {item[5]}
                                {/if}
                              </td>
                            </tr>
                          {/each}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              {/if}
              <div
                class="card h-100"
                style="font-size:0.8rem;display: {!mapa_on_ ? 'none' : ''};"
              >
                <div class="d-flex flex-column h-50 overflow-auto">
                  <div class="card-header b-0 bg-dark text-white">
                    <strong>HOS</strong>
                  </div>
                  <div class=" d-flex flex-grow-1 pt-0">
                    <canvas
                      id="chart2"
                      style="display: {!mapa_on_ ? 'none' : ''};"
                    />
                  </div>
                </div>
                <div class="d-flex flex-column h-50 overflow-auto">
                  <div class="card-header b-0 bg-dark text-white">
                    <strong>Tiempo por estado</strong>
                  </div>
                  <div class=" d-flex flex-grow-1 pt-0">
                    <canvas
                      id="chart3"
                      style="display: {!mapa_on_ ? 'none' : ''};"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .btn {
    font-size: 0.75rem;
    padding: 0.1rem, 0.1rem;
    line-height: 0.7;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .c100.big {
    font-size: 11vw;
  }
  .c100 {
    margin: 0;
  }
  .c100 > span {
    width: 2.5em;
    line-height: 2.5em;
    font-size: 0.4em;
  }
  .badge {
    font-size: 1.5rem;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  th.dato-general,
  td.dato-general {
    text-align: start;
    vertical-align: middle;
    font-size: 0.9rem;
    padding-left: 0.75rem;
  }
</style>

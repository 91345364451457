export const alert_templates = [
  {
    description:
      '<h1>Alerta: Sin señal ON</h1><p>La <strong>alerta Sin señal ON</strong> es una funcionalidad diseñada para identificar situaciones en las que un dispositivo no reporta su posición durante un periodo de tiempo mientras la ignición del vehículo está encendida. Esta alerta permite a los usuarios monitorear el estado de los dispositivos y tomar medidas inmediatas en caso de interrupciones en la comunicación.</p><p>Este sistema es ideal para garantizar un seguimiento constante de los vehículos, especialmente en entornos donde la visibilidad y la conectividad son críticas. Al activarse, envía un mensaje automático a los contactos designados para solicitar información sobre la ubicación actual de la unidad afectada.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Ignición encendida y sin señal por más de <strong>10 minutos</strong>.</li><li>Mensaje enviado: "<em>Sin señal, {unidad}, ON</em>".</li><li>Mensaje de chat: "<em>Compañero favor de informar sobre su ubicación actual</em>".</li><li>Frecuencia máxima de alertas: <strong>10 minutos</strong>.</li><li>Icono representativo: <strong>mobile_off</strong>.</li></ul><p>Esta alerta es configurable y cuenta con protocolos específicos para filtrar escenarios según el tiempo, la geolocalización y otros factores relevantes. Su implementación asegura un monitoreo eficiente y oportuno en sistemas de flota.</p>',
    def: {
      name: "Sin señal On",
      alertid: "6df0c413-1e9c-4ac1-a42a-723d5a778c19",
      severity: 0,
      description:
        "Alerta cuando el dispositivo no reporte posición en un periodo de tiempo con Ignición ON",
      message: "Sin señal, {unidad}, ON",
      chat_message: "Compañero favor de informar sobre su ubicación actual",
      report_tels: [],
      disabled: false,
      frequency_max: 10,
      freq_counter_min: -1,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "mobile_off",
      cron_alert: {
        count: 1,
        counter: [
          {
            last_pos: {
              type: "major",
              val: {
                default: 10,
                FS: 10,
                TMCI: 20,
                IVG: 20,
              },
              scale: "minutos",
            },
            ingnition: {
              type: "equal",
              val: {
                default: 1,
                FS: 1,
                TMCI: 1,
                IVG: 1,
              },
            },
            geo_type: {
              type: "noequal",
              val: {
                default: "secure",
                FS: "secure",
                TMCI: "secure",
                IVG: "secure",
              },
            },
            in_route_no_sig: {
              type: "major",
              val: {
                default: 0.3,
                FS: 0.3,
                TMCI: 0.3,
                IVG: 0.3,
              },
              scale: "minutos",
            },
          },
        ],
        discounter: [
          {
            last_pos: {
              type: "minor",
              val: {
                default: 5,
                FS: 5,
                TMCI: 5,
                IVG: 5,
              },
              scale: "minutos",
            },
          },
        ],
      },
      event_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Sin señal OFF</h1><p>La <strong>alerta Sin señal OFF</strong> es una funcionalidad diseñada para identificar situaciones en las que un dispositivo no reporta su posición durante un periodo de tiempo mientras la ignición del vehículo está apagada. Esta alerta permite a los usuarios monitorear el estado de los dispositivos en momentos de inactividad del vehículo y tomar medidas en caso de interrupciones en la comunicación.</p><p>Este sistema es ideal para garantizar un seguimiento constante de los vehículos incluso cuando están estacionados o fuera de operación, asegurando que se mantenga un control adecuado. Al activarse, se genera un mensaje para advertir sobre la falta de señal.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Ignición apagada y sin señal por más de <strong>22 minutos</strong>.</li><li>Mensaje enviado: "<em>Sin señal, {unidad}, OFF</em>".</li><li>Frecuencia máxima de alertas: <strong>20 minutos</strong>.</li><li>Icono representativo: <strong>mobile_off</strong>.</li></ul><p>Esta alerta es configurable y cuenta con parámetros específicos para filtrar escenarios según el tiempo, la geolocalización y otros factores relevantes. Su implementación asegura un monitoreo eficiente y oportuno incluso en periodos de inactividad del vehículo.</p>',
    def: {
      name: "Sin señal Off",
      alertid: "3807632e-1e6e-49dd-94a7-0bc88b4fcf84",
      severity: 0,
      description:
        "Alerta cuando el dispositivo no reporte posición en un periodo de tiempo con Ignición Off",
      message: "Sin señal, {unidad}, Off",
      chat_message: "",
      disabled: false,
      frequency_max: 20,
      freq_counter_min: -1,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "mobile_off",
      cron_alert: {
        count: 1,
        counter: [
          {
            last_pos: {
              type: "major",
              val: {
                default: 22,
                FS: 22,
                TMCI: 1500,
                IVG: 1500,
                GNX: 60,
              },
              scale: "minutos",
            },
            ingnition: {
              type: "equal",
              val: {
                default: 0,
                FS: 0,
                TMCI: 0,
                IVG: 0,
                GNX: 0,
              },
            },
            geo_type: {
              type: "noequal",
              val: {
                default: "secure",
                FS: "secure",
                TMCI: "secure",
                IVG: "secure",
                GNX: "secure",
              },
            },
          },
        ],
        discounter: [
          {
            last_pos: {
              type: "minor",
              val: {
                default: 5,
                FS: 5,
                TMCI: 5,
                IVG: 5,
                GNX: 5,
              },
              scale: "minutos",
            },
          },
        ],
      },
      event_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Unidad Detenida</h1><p>La <strong>alerta Unidad Detenida</strong> está diseñada para identificar situaciones en las que una unidad permanece detenida durante un periodo prolongado en zonas no seguras o fuera de su ruta habitual. Esta alerta permite a los usuarios monitorear el comportamiento de sus unidades y tomar medidas preventivas en caso de detenciones inesperadas.</p><p>Este sistema es ideal para garantizar la seguridad y la continuidad operativa de las flotas, al generar una notificación que detalla la situación y solicita información del motivo de la detención.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Unidad detenida por más de <strong>10 minutos</strong> en áreas no seguras.</li><li>Mensaje enviado: "<em>Unidad detenida, {unidad}</em>".</li><li>Mensaje de chat: "<em>Detectamos que su unidad {unidad}, se encuentra detenida, favor de informar el motivo.</em>".</li><li>Frecuencia máxima de alertas: <strong>20 minutos</strong>.</li><li>Icono representativo: <strong>pan_tool</strong>.</li></ul><p>Esta alerta es configurable y permite el ajuste de parámetros específicos como el tiempo de detención, la ubicación y las condiciones de ignición. Su implementación asegura una gestión proactiva y oportuna de las incidencias relacionadas con la operación de la flota.</p>',
    def: {
      name: "Unidad detenida",
      alertid: "00f9fca8-6e08-47d9-a462-8a394546c365",
      severity: 0,
      description: "Unidad detenida",
      message: "Unidad detenida, {unidad}",
      chat_message:
        "Detectamos que su unidad {unidad}, se encuentra detenida, favor de informar el motivo.",
      report_tels: [],
      emails: [],
      disabled: false,
      frequency_max: 20,
      freq_counter_min: 20,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "pan_tool",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            stay_time: {
              type: "major",
              val: 10,
              scale: "minutos",
            },
            geo_type: {
              type: "noequal",
              val: "secure",
            },
          },
          {
            ingnition: {
              type: "equal",
              val: 0,
            },
            geo_type: {
              type: "noequal",
              val: "secure",
            },
          },
        ],
        discounter: [
          {
            stay_time: {
              type: "minor",
              val: 5,
              scale: "minutos",
            },
            stay: {
              type: "major",
              val: 0.3,
              scale: "minutos",
            },
          },
          {
            geo_type: {
              type: "equal",
              val: "secure",
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Unidad Llegó a Destino</h1><p>La <strong>alerta Unidad Llegó a Destino</strong> está diseñada para notificar cuando una unidad alcanza su ubicación de destino programada. Esta funcionalidad asegura un monitoreo preciso de los trayectos y confirma la llegada de las unidades de forma eficiente.</p><p>Este sistema es ideal para operaciones logísticas y de transporte, donde es fundamental conocer la puntualidad y cumplimiento de los destinos asignados.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: La unidad llega a su destino definido en el sistema.</li><li>Mensaje enviado: "<em>Alerta, la unidad {unidad} llegó a destino.</em>".</li><li>Mensaje de chat: "<em>Alerta, unidad llegó a destino.</em>".</li><li>Frecuencia máxima de alertas: <strong>5 minutos</strong>.</li><li>Icono representativo: <strong>save_alt</strong>.</li></ul><p>Esta alerta es configurable, permitiendo ajustar los destinos y parámetros asociados a las notificaciones, lo que facilita una gestión eficiente de las entregas y la monitorización del desempeño de la flota.</p>',
    def: {
      name: "Unidad llego a destino",
      alertid: "5f6a45dd-d6ef-4d20-8a98-c2015790e480",
      severity: 0,
      description: "Unidad llego a destino",
      message: "Alerta, la unidad {unidad} llegó a destino.",
      chat_message: "Alerta, unidad llego a destino.",
      disabled: false,
      frequency_max: 5,
      freq_counter_min: 5,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "save_alt",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            geo_dest: {
              type: "equal",
              val: 1,
            },
          },
        ],
        discounter: [
          {
            geo_dest: {
              type: "equal",
              val: 0,
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Unidad Fuera de Ruta</h1><p>La <strong>alerta Unidad Fuera de Ruta</strong> está diseñada para identificar cuando una unidad sale del trayecto predefinido o asignado. Esta funcionalidad es crucial para garantizar la seguridad de las operaciones y evitar desviaciones no autorizadas o accidentes fuera de las rutas establecidas.</p><p>Este sistema es ideal para operaciones logísticas, transporte y seguridad, donde es esencial monitorear que las unidades sigan las rutas asignadas y reportar cualquier desviación de manera oportuna.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: La unidad se desvía de la ruta por más de la distancia permitida.</li><li>Mensaje enviado: "<em>Alerta, la unidad {unidad} está fuera de ruta.</em>".</li><li>Mensaje de chat: "<em>Unidad {unidad}, favor de confirmar el destino.</em>".</li><li>Frecuencia máxima de alertas: <strong>5 minutos</strong>.</li><li>Icono representativo: <strong>call_missed</strong>.</li></ul><p>Esta alerta es configurable, permitiendo establecer parámetros como la distancia de desviación aceptable y la ubicación segura. Su implementación garantiza una supervisión efectiva y la capacidad de reaccionar rápidamente a situaciones irregulares.</p>',
    def: {
      name: "Unidad fuera de ruta",
      alertid: "f0418cbc-3a02-4bca-b488-4d1159adfdcd",
      severity: 0,
      description: "Unidad fuera de ruta",
      message: "Alerta, la unidad {unidad} está fuera de ruta.",
      chat_message: "Unidad {unidad}, favor de confirmar el destino.",
      report_tels: [],
      emails: [],
      disabled: false,
      frequency_max: 5,
      freq_counter_min: 5,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "call_missed",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            route_distance: {
              type: "major",
              val: 0.3,
              scale: "metros",
            },
            geo_type: {
              type: "noequal",
              val: "secure",
            },
          },
        ],
        discounter: [
          {
            route_distance: {
              type: "minor",
              val: 0.3,
              scale: "metros",
            },
            geo_type: {
              type: "noequal",
              val: "secure",
            },
          },
          {
            geo_type: {
              type: "equal",
              val: "secure",
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: GPS Jamming</h1><p>La <strong>alerta GPS Jamming</strong> está diseñada para detectar intentos de interferencia o bloqueo intencional de la señal GPS de una unidad. Esta funcionalidad permite alertar sobre posibles situaciones de riesgo, como sabotajes o intentos de robo, que buscan deshabilitar el sistema de localización.</p><p>Este sistema es ideal para operaciones de alto valor y seguridad, donde es crítico identificar cualquier actividad sospechosa que pueda comprometer el monitoreo y la trazabilidad de las unidades.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Detección de interferencia en la señal GPS en áreas no seguras.</li><li>Mensaje enviado: "<em>GPS jamming, {unidad}</em>".</li><li>Mensaje de chat: "<em>Alerta, GPS jamming detectado.</em>".</li><li>Frecuencia máxima de alertas: <strong>10 minutos</strong>.</li><li>Icono representativo: <strong>gps_off</strong>.</li></ul><p>Esta alerta es configurable, permitiendo ajustar parámetros como los códigos de evento y la validación de ubicación segura. Su implementación asegura una rápida identificación y respuesta ante posibles amenazas de seguridad.</p>',
    def: {
      name: "GPS JAM",
      alertid: "42695208-af52-4f7d-9ab5-7856566e48a2",
      severity: 0,
      description: "GPS jamming",
      message: "GPS llaming, {unidad}",
      chat_message: "Alerta, GPS jamming detectado.",
      disabled: false,
      frequency_max: 10,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "gps_off",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            event_code: {
              type: "equal",
              val: 12,
            },
            geo_type: {
              type: "noequal",
              val: "secure",
            },
          },
        ],
        discounter: [
          {
            ingnition: {
              type: "equal",
              val: 1,
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Botón de Pánico</h1><p>La <strong>alerta Botón de Pánico</strong> está diseñada para detectar y notificar cuando se activa el botón de pánico en una unidad. Esta funcionalidad es esencial para situaciones de emergencia, permitiendo una respuesta rápida y oportuna por parte de los responsables del monitoreo.</p><p>Este sistema es ideal para garantizar la seguridad de los operadores y vehículos, alertando inmediatamente al detectar activaciones en zonas no seguras.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Presión del botón de pánico en áreas no seguras.</li><li>Mensaje enviado: "<em>Botón de pánico, {unidad}</em>".</li><li>Mensaje de chat: "<em>Alerta, botón de pánico detectado.</em>".</li><li>Frecuencia máxima de alertas: <strong>5 minutos</strong>.</li><li>Icono representativo: <strong>record_voice_over</strong>.</li></ul><p>Esta alerta es configurable, permitiendo ajustar parámetros como los códigos de evento y las validaciones de ubicación segura. Su implementación asegura una supervisión eficiente y una reacción inmediata ante emergencias críticas.</p>',
    def: {
      name: "Boton de panico",
      alertid: "672b45f2-9f3f-45e2-a070-14e41e94caca",
      severity: 0,
      description: "Boton de panico",
      message: "Boton de panico, {unidad}",
      chat_message: "Alerta, Boton de panico detectado.",
      disabled: false,
      frequency_max: 5,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "record_voice_over",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            event_code: {
              type: "equal",
              val: 40,
            },
            geo_type: {
              type: "noequal",
              val: "secure",
            },
          },
        ],
        discounter: [
          {
            ingnition: {
              type: "equal",
              val: 1,
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Manual</h1><p>La <strong>alerta Manual</strong> está diseñada para permitir la activación manual de una alerta destinada a detener una unidad en situaciones específicas. Esta funcionalidad proporciona flexibilidad y control adicional, permitiendo tomar medidas directas cuando sea necesario.</p><p>Este sistema es ideal para operaciones que requieren intervención manual para garantizar la seguridad y el cumplimiento de procedimientos en tiempo real.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Alerta activada manualmente por el usuario.</li><li>Mensaje enviado: "<em>Atención, Alerta manual, unidad {unidad}</em>".</li><li>Frecuencia máxima de alertas: <strong>5 minutos</strong>.</li><li>Icono representativo: <strong>eco</strong>.</li></ul><p>Esta alerta es configurable y puede adaptarse a diferentes necesidades operativas. Su implementación asegura un control efectivo y la capacidad de reaccionar rápidamente ante situaciones que requieren atención manual directa.</p>',
    def: {
      name: "Manual",
      alertid: "672b45f2-9f3f-45e2-a070-74e41e94c3ca",
      severity: 0,
      description: "Alerta manual, para detener la unidad.",
      message: "Atención, Alerta manual, unidad {unidad}",
      emails: [],
      chat_message: "",
      disabled: false,
      frequency_max: 1,
      freq_counter_min: -1,
      freq_discounter_min: -1,
      protocol: 0,
      icon: "eco",
      cron_alert: {
        count: 1,
        counter: [
          {
            manual: {
              type: "equal",
              val: 1,
            },
          },
        ],
        discounter: [
          {
            ingnition: {
              type: "equal",
              val: 1,
            },
          },
          {
            ingnition: {
              type: "equal",
              val: 0,
            },
          },
        ],
      },
      event_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
    },
  },
  {
    description:
      "<h1>Alerta: Vuelta Brusca</h1><p>La <strong>alerta Vuelta Brusca</strong> está diseñada para detectar maniobras repentinas o bruscas realizadas por una unidad, como giros inesperados. Esta funcionalidad es clave para monitorear la conducción y garantizar un manejo seguro, ayudando a identificar comportamientos que puedan poner en riesgo la seguridad de la operación.</p><p>Este sistema es ideal para flotas que buscan mejorar la seguridad en carretera y reducir riesgos asociados a maniobras peligrosas.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Detección de maniobras bruscas asociadas con códigos de evento específicos (<strong>70</strong> y <strong>71</strong>).</li><li>Frecuencia máxima de alertas: <strong>10 minutos</strong>.</li><li>Icono representativo: <strong>priority_high</strong>.</li></ul><p>Esta alerta está actualmente <strong>deshabilitada</strong> pero puede configurarse para activarse según las necesidades de seguridad de la flota. Su implementación permite un análisis detallado del comportamiento de los conductores y fomenta una conducción más segura.</p>",
    def: {
      name: "Vuelta brusca",
      alertid: "4330d447-f21c-468b-b98d-020c23e13b2b",
      severity: 0,
      description: "Vuelta brusca",
      message: "",
      chat_message: "",
      disabled: false,
      frequency_max: 10,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "priority_high",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            event_code: {
              type: "equal",
              val: 70,
            },
          },
          {
            event_code: {
              type: "equal",
              val: 71,
            },
          },
        ],
        discounter: [
          {
            ingnition: {
              type: "equal",
              val: 1,
            },
          },
        ],
      },
    },
  },
  {
    description:
      "<h1>Alerta: Aceleración Brusca</h1><p>La <strong>alerta Aceleración Brusca</strong> está diseñada para identificar eventos donde una unidad experimenta un incremento repentino y significativo en la velocidad. Esta funcionalidad es esencial para monitorear el comportamiento de los conductores y promover prácticas de manejo seguro.</p><p>Este sistema es ideal para operaciones que priorizan la seguridad vial y buscan minimizar riesgos asociados a maniobras agresivas o descuidos durante la conducción.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Detección de eventos relacionados con aceleraciones bruscas (<strong>código de evento: 6e</strong>).</li><li>Frecuencia máxima de alertas: <strong>10 minutos</strong>.</li><li>Icono representativo: <strong>speed</strong>.</li></ul><p>Esta alerta está actualmente <strong>deshabilitada</strong>, pero puede activarse según los requerimientos de monitoreo de seguridad de la flota. Su implementación permite identificar y corregir comportamientos que puedan comprometer la seguridad en carretera.</p>",
    def: {
      name: "Aceleración brusca",
      alertid: "d77ff8f7-37f7-4173-9f15-7d0c41d798d5",
      severity: 0,
      description: "Aceleración brusca",
      message: "",
      chat_message: "",
      disabled: false,
      frequency_max: 10,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "speed",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            event_code: {
              type: "equal",
              val: "6e",
            },
          },
        ],
        discounter: [
          {
            ingnition: {
              type: "equal",
              val: 1,
            },
          },
        ],
      },
    },
  },
  {
    description:
      "<h1>Alerta: Frenada Brusca</h1><p>La <strong>alerta Frenada Brusca</strong> está diseñada para detectar eventos donde una unidad realiza una desaceleración repentina e intensa. Esta funcionalidad es fundamental para monitorear el comportamiento de los conductores, identificar posibles riesgos y fomentar prácticas de manejo más seguras.</p><p>Este sistema es ideal para operaciones que buscan reducir riesgos asociados a maniobras agresivas y mejorar la seguridad en carretera.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Detección de eventos relacionados con frenadas bruscas (<strong>código de evento: 6f</strong>).</li><li>Frecuencia máxima de alertas: <strong>10 minutos</strong>.</li><li>Icono representativo: <strong>block</strong>.</li></ul><p>Esta alerta está actualmente <strong>deshabilitada</strong>, pero puede ser configurada para activarse según las necesidades de monitoreo de seguridad de la flota. Su implementación ayuda a identificar y prevenir comportamientos de conducción que puedan comprometer la seguridad operativa.</p>",
    def: {
      name: "Frenada brusca",
      alertid: "d6247ba3-a6f6-4781-a220-e3cc24ec26e8",
      severity: 0,
      description: "Frenada brusca",
      message: "",
      chat_message: "",
      disabled: false,
      frequency_max: 10,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "block",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            event_code: {
              type: "equal",
              val: "6f",
            },
          },
        ],
        discounter: [
          {
            ingnition: {
              type: "equal",
              val: 1,
            },
          },
        ],
      },
    },
  },
  {
    description:
      "<h1>Alerta: Exceso de Velocidad Fijo</h1><p>La <strong>alerta Exceso de Velocidad</strong> está diseñada para identificar cuando una unidad supera los límites de velocidad establecidos. Esta funcionalidad es crucial para monitorear el comportamiento de los conductores y fomentar una conducción segura y responsable.</p><p>Este sistema es ideal para operaciones que buscan reducir accidentes asociados a la velocidad y mejorar la seguridad vial dentro de sus flotas.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Detección de eventos relacionados con exceso de velocidad (<strong>código de evento: 6f</strong>).</li><li>Frecuencia máxima de alertas: <strong>10 minutos</strong>.</li><li>Icono representativo: <strong>trending_up</strong>.</li></ul><p>Esta alerta está actualmente <strong>deshabilitada</strong>, pero puede ser habilitada y configurada para adaptarse a las necesidades de monitoreo de seguridad vial de la flota. Su implementación ayuda a identificar y corregir comportamientos peligrosos que puedan comprometer la seguridad operativa.</p>",
    def: {
      name: "Exceso de velocidad Fijo",
      alertid: "e5bf3b75-934b-41b4-9a2a-864e8d54c2dd",
      severity: 0,
      description: "Exceso de velocidad",
      message: "",
      chat_message: "",
      disabled: false,
      frequency_max: 10,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "trending_up",
      cron_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      event_alert: {
        count: 1,
        counter: [
          {
            event_code: {
              type: "equal",
              val: "6f",
            },
          },
        ],
        discounter: [
          {
            ingnition: {
              type: "equal",
              val: 1,
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Solicitud de Fin de Ruta</h1><p>La <strong>alerta Solicitud de Fin de Ruta</strong> está diseñada para notificar cuando se realiza una solicitud manual para finalizar una ruta asignada. Esta funcionalidad asegura un monitoreo eficiente y permite coordinar el cierre de trayectos de manera controlada.</p><p>Este sistema es ideal para operaciones logísticas y de transporte que requieren un control preciso sobre el inicio y fin de las rutas asignadas.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Solicitud manual de finalización de ruta (<strong>travel_finish_query</strong>).</li><li>Mensaje enviado: "<em>Solicitud de fin de ruta, {unidad}</em>".</li><li>Mensaje de chat: "<em>Compañero favor de informar sobre su ubicación actual.</em>".</li><li>Frecuencia máxima de alertas: <strong>1 minuto</strong>.</li><li>Icono representativo: <strong>trip_origin</strong>.</li></ul><p>Esta alerta es configurable y puede adaptarse a las necesidades de control operativo, facilitando una gestión eficiente de las rutas y garantizando una respuesta adecuada a las solicitudes de finalización.</p>',
    def: {
      name: "Solicitud de fin de ruta",
      alertid: "d6247ba3-a6f6-4781-a220-e3cc24ec26e9",
      severity: 0,
      description: "Solicitud de fin de ruta",
      emails: [],
      message: "Solicitud de fin de ruta, {unidad}",
      chat_message: "Compañero favor de informar sobre su ubicación actual",
      disabled: false,
      frequency_max: 1,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "trip_origin",
      event_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      cron_alert: {
        count: 1,
        counter: [
          {
            travel_manual: {
              type: "equal",
              val: "travel_finish_query",
            },
          },
        ],
        discounter: [
          {
            travel_manual: {
              type: "noequal",
              val: "travel_finish_query",
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Solicitud de Parada</h1><p>La <strong>alerta Solicitud de Parada</strong> está diseñada para notificar cuando se realiza una solicitud manual para detener una unidad en tránsito. Esta funcionalidad permite gestionar de manera eficiente las solicitudes de paradas y coordinar acciones necesarias durante el trayecto.</p><p>Este sistema es ideal para operaciones que requieren control preciso sobre las paradas de las unidades, garantizando una comunicación clara y oportuna.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Solicitud manual de parada (<strong>travel_stop_query</strong>).</li><li>Mensaje enviado: "<em>Solicitud de parada, {unidad}</em>".</li><li>Mensaje de chat: "<em>Compañero favor de informar sobre su ubicación actual.</em>".</li><li>Frecuencia máxima de alertas: <strong>1 minuto</strong>.</li><li>Icono representativo: <strong>highlight_off</strong>.</li></ul><p>Esta alerta es configurable y se adapta a las necesidades operativas, facilitando la gestión de solicitudes de paradas y mejorando la coordinación en las operaciones de flota.</p>',
    def: {
      name: "Solicitud de parada",
      alertid: "a6247ba3-a6f6-4781-a220-e3cc24ec26e9",
      severity: 0,
      description: "Solicitud de parada",
      emails: [],
      message: " Solicitud de parada, {unidad}",
      chat_message: " Compañero favor de informar sobre su ubicación actual",
      disabled: false,
      frequency_max: 1,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "highlight_off",
      event_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      cron_alert: {
        count: 1,
        counter: [
          {
            travel_manual: {
              type: "equal",
              val: "travel_stop_query",
            },
          },
        ],
        discounter: [
          {
            travel_manual: {
              type: "noequal",
              val: "travel_stop_query",
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: SOS Operador</h1><p>La <strong>alerta SOS Operador</strong> está diseñada para notificar situaciones de emergencia reportadas manualmente por el operador de una unidad. Esta funcionalidad permite una respuesta inmediata y coordinada ante posibles riesgos o incidentes críticos.</p><p>Este sistema es ideal para operaciones que priorizan la seguridad del personal y requieren una herramienta confiable para manejar emergencias en tiempo real.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Solicitud manual de emergencia por parte del operador (<strong>help</strong>).</li><li>Mensaje enviado: "<em>SOS Operador, {unidad}</em>".</li><li>Mensaje de chat: "<em>Compañero favor de informar sobre su ubicación actual.</em>".</li><li>Frecuencia máxima de alertas: <strong>1 minuto</strong>.</li><li>Icono representativo: <strong>emergency</strong>.</li></ul><p>Esta alerta es configurable para ajustarse a las necesidades de seguridad operativa y garantizar una gestión efectiva y rápida de las emergencias reportadas.</p>',
    def: {
      name: "SOS Operador",
      alertid: "a1247ba3-a6f6-4781-a220-e3cc24ec26e9",
      severity: 0,
      description: "SOS Operador",
      emails: [],
      message: " SOS Operador, {unidad}",
      chat_message: " Compañero favor de informar sobre su ubicación actual",
      disabled: false,
      frequency_max: 1,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "help",
      event_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      cron_alert: {
        count: 1,
        counter: [
          {
            travel_manual: {
              type: "equal",
              val: "travel_sos",
            },
          },
        ],
        discounter: [
          {
            travel_manual: {
              type: "noequal",
              val: "travel_sos",
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Inicio Tardío</h1><p>La <strong>alerta Inicio Tardío</strong> está diseñada para notificar cuando una unidad no inicia su operación dentro del tiempo programado. Esta funcionalidad es clave para garantizar la puntualidad y el cumplimiento de los horarios establecidos en las operaciones logísticas.</p><p>Este sistema es ideal para flotas que buscan optimizar la eficiencia operativa y reducir los impactos de retrasos en sus itinerarios.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Inicio de operación retrasado por más de <strong>20 minutos</strong>.</li><li>Mensaje enviado: "<em>Inicio tardío, {unidad}</em>".</li><li>Mensaje de chat: "<em>Compañero favor de informar sobre su ubicación actual.</em>".</li><li>Frecuencia máxima de alertas: <strong>1 minuto</strong>.</li><li>Icono representativo: <strong>schedule</strong>.</li></ul><p>Esta alerta es configurable para adaptarse a las necesidades específicas de cada operación, asegurando una gestión proactiva y eficiente de los retrasos en los inicios de las actividades.</p>',
    def: {
      name: "Inicio tardio",
      alertid: "a1247ba3-a6f6-4081-b220-e3cc24ec26e9",
      severity: 0,
      description: "Inicio tardio",
      emails: [],
      message: " Inicio tardio, {unidad}",
      chat_message: " Compañero favor de informar sobre su ubicación actual",
      disabled: false,
      frequency_max: 1,
      freq_counter_min: 0,
      freq_discounter_min: 0,
      protocol: 0,
      icon: "schedule",
      event_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
      cron_alert: {
        count: 1,
        counter: [
          {
            late_start: {
              type: "major",
              val: 20,
            },
          },
        ],
        discounter: [
          {
            follow: {
              type: "equal",
              val: 1,
            },
          },
        ],
      },
    },
  },
  {
    description:
      '<h1>Alerta: Sin Señal ON (Ruta Sin Señal)</h1><p>La <strong>alerta Sin Señal ON (Ruta Sin Señal)</strong> está diseñada para notificar cuando un dispositivo no reporta posición en un periodo de tiempo específico mientras la ignición del vehículo está encendida. Esta funcionalidad es crucial para identificar posibles problemas de conectividad durante el trayecto.</p><p>Este sistema es ideal para operaciones que requieren un monitoreo constante de las unidades en ruta, asegurando que se detecten interrupciones en la comunicación de manera oportuna.</p><ul><li>Severidad: Alta (<strong>1</strong>).</li><li>Activación: Ignición encendida y sin señal por más de <strong>10 minutos</strong>.</li><li>Mensaje enviado: "<em>Sin señal, {unidad}, ON</em>".</li><li>Mensaje de chat: "<em>Compañero favor de informar sobre su ubicación actual.</em>".</li><li>Frecuencia máxima de alertas: <strong>10 minutos</strong>.</li><li>Icono representativo: <strong>signal_cellular_off</strong>.</li></ul><p>Esta alerta es configurable, permitiendo ajustar parámetros como tiempo sin señal, estado de ignición y condiciones de ruta. Su implementación garantiza una supervisión eficiente y una respuesta rápida ante interrupciones en la comunicación.</p>',
    def: {
      name: "Sin señal On (ruta sin señal)",
      alertid: "de35d4d8-3e5d-42db-9e56-2ca709ecadab",
      severity: 0,
      description:
        "Alerta cuando el dispositivo no reporte posición en un periodo de tiempo con Ignición ON",
      chat_message: "Compañero favor de informar sobre su ubicación actual",
      frequency_max: 10,
      freq_counter_min: -1,
      freq_discounter_min: 0,
      protocol: 0,
      message: "Sin señal, {unidad}, ON",
      report_tels: [],
      emails: [],
      disabled: false,
      icon: "signal_cellular_off",
      cron_alert: {
        count: 1,
        counter: [
          {
            last_pos: {
              type: "major",
              val: {
                default: 10,
                FS: 10,
                TMCI: 20,
                IVG: 20,
              },
              scale: "minutos",
            },
            ingnition: {
              type: "equal",
              val: {
                default: 1,
                FS: 1,
                TMCI: 1,
                IVG: 1,
              },
            },
            in_route_no_sig: {
              type: "minor",
              val: {
                default: 0.3,
                FS: 0.3,
                TMCI: 0.3,
                IVG: 0.3,
              },
              scale: "minutos",
            },
            route_no_sig: {
              type: "equal",
              val: {
                default: 0.3,
                FS: 0.3,
                TMCI: 0.3,
                IVG: 0.3,
              },
              scale: "metros",
            },
          },
        ],
        discounter: [
          {
            last_pos: {
              type: "minor",
              val: {
                default: 5,
                FS: 5,
                TMCI: 5,
                IVG: 5,
              },
              scale: "minutos",
            },
          },
        ],
      },
      event_alert: {
        count: 1,
        counter: [],
        discounter: [],
      },
    },
  },
];
